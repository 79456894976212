import _ from 'lodash';
import { TextField, Typography, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import axiosInstance from '../../services/axiosConfig';
import { ReplicateModelNameToCredits } from '../../consts/model-prices.consts';
import { color } from '../../colors';
import ThreeDeeViewer from "./ThreeDeeViewer";
import JSZip from 'jszip';

export const ExtraSmallFontTextField = styled(TextField)`
  & .MuiInputBase-root, & {
    font-size: 0.6rem;
  }
  
  & .MuiSelect-select {
    padding: 4px 6px;
  }
  
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }
`;

export const SmallFontTextField = styled(TextField)`
  & .MuiInputBase-root, & {
    font-size: 0.8rem;
    background-color: ${color.Yambo_BG};
  }
  
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }
`;

export const getImageDimensions = async (image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
  
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };
  
    img.onerror = (error) => {
      reject(error);
    };
  
    img.src = image;
  });
};

export const getFileDimensions = (file, type) => {
  return new Promise((resolve, reject) => {
    // For images
    if (type === 'image') {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = file;
    }
    // For videos
    else if (type === 'video') {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = reject;
      video.src = file;
    } else {
      reject(new Error('Unsupported file type'));
    }
  });
};
  
  
export const extractInputSchemaDetails = (jsonData) => {

  const schemas = jsonData.openapi_schema?.components?.schemas || {};
  const inputSchema = schemas.Input?.properties || {};
  const requiredKeys = schemas.Input?.required || [];
  const extractedDetails = {};

  Object.entries(inputSchema).forEach(([propName, propInfo]) => {
    let propDetails = { type: propInfo.type };

    // support array of diffrent types
    if(propInfo.type === 'array'){
      propDetails.array_type = propInfo.items.type;
    }

    // Include default values
    if (propInfo.default !== undefined) {
      propDetails.default = propInfo.default;
    }
  
    // Handle enums directly within the property
    if (propInfo.enum) {
      propDetails.options = propInfo.enum;
    }
  
    // Handle min and max for numbers and integers
    if (propInfo.type === 'number' || propInfo.type === 'integer') {
      if (propInfo.minimum !== undefined) {
        propDetails.min = propInfo.minimum;
      }
      if (propInfo.maximum !== undefined) {
        propDetails.max = propInfo.maximum;
      }
      if(propInfo.minimum === undefined && propInfo.maximum === undefined){ // I assume it's not a slider but an input box
        if (propInfo.type === 'number'){
          propDetails.type = 'input-number';
        } else propDetails.type = 'input-integer';
        
      }
    }
  
    if(propName === 'Seed' || propName === 'seed'){
      propDetails.type = 'seed';
    }
    // Resolve references to other schemas and extract details
    // if (propInfo['allOf']) {
    //   const referencedSchema = resolveRef(propInfo['allOf'][0]['$ref']);
    //   // If the referenced schema is an enum, extract its options
    //   if (referencedSchema.enum) {
    //     propDetails.ref = propName;
    //     propDetails.options = referencedSchema.enum;
    //     propDetails.type = "enum";
    //   }
    //   // Extend to include other details from the referenced schema as needed
    // }
    if (propDetails.options) {
      propDetails.type = "enum";
    }

    /// fix name to be presentable
    function capitalizeWords(str) {
      return str.replace(/_/g, ' ') // Replace underscores with spaces
        .split(' ') // Split the string into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Rejoin the words into a single string
    }
    /// title
    propDetails.title = capitalizeWords(propName);
    /// description
    propDetails.description = propInfo['description'];
    if(propName === 'seed' || propName === 'Seed'){
      propDetails.description = 'Seed value for random number generator. Uncheck for reproducible results.';
    }
    /// order 
    propDetails.order = propInfo['x-order'];
    propDetails.required = requiredKeys.includes(propName) ? true : false;
    propDetails.format = propInfo['format'];

    // normalize seed names
    if(propName === 'seed' || propName === 'Seed'){
      propName = 'seed';
    }
    extractedDetails[propName] = propDetails;
  });
  
  const sortedDetailsArray = Object.entries(extractedDetails).sort((a, b) => {
    const orderA = a[1].order || 0; // Provide a default order if undefined
    const orderB = b[1].order || 0; // Provide a default order if undefined
    
    return orderA - orderB;
  });
  
  // Convert the sorted array back into an object
  const sortedExtractedDetails = sortedDetailsArray.reduce((acc, [key, value]) => {
    acc[key] = value;
    
    return acc;
  }, {});
  
  return sortedExtractedDetails;
};

export function sanitizeNodes(nodes) {
  const sanitizedNodes = nodes.map((node) => {
    if(node.data.output){
      for (const [name, conf] of Object.entries(node.data.output)) {
        if (conf?.type === "image" && conf?.url.includes("data:image/png;base64")) {
          node.data.output[name].url = "";
        }
      }
    }

    if(node.data.input){
      for (const [name, conf] of Object.entries(node.data.input)) {
        if (conf?.type === "image" && conf?.url.includes("data:image/png;base64")) {
          node.data.input[name].url = "";
        }
      }
    }

    return node;
  });

  return sanitizedNodes;
}

function performMatteChoke(imageData, amount) {
  const width = imageData.width;
  const height = imageData.height;
  const data = new Uint8ClampedArray(imageData.data);
  const result = new Uint8ClampedArray(data.length);

  const threshold = 128;
  const iterations = Math.abs(amount);
  const expand = amount > 0;

  for (let i = 0; i < iterations; i++) {
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const idx = (y * width + x) * 4;
        let sum = 0;
        let count = 0;

        // Check neighboring pixels
        for (let dy = -1; dy <= 1; dy++) {
          for (let dx = -1; dx <= 1; dx++) {
            const nx = x + dx;
            const ny = y + dy;
            if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
              const nidx = (ny * width + nx) * 4;
              sum += data[nidx] > threshold ? 1 : 0;
              count++;
            }
          }
        }

        // Determine if the pixel should be white or black
        const shouldBeWhite = expand ? (sum > 0) : (sum === count);
        const value = shouldBeWhite ? 255 : 0;

        // Set the result pixel
        result[idx] = result[idx + 1] = result[idx + 2] = value;
        result[idx + 3] = 255; // Alpha channel
      }
    }

    // Copy result back to data for next iteration
    data.set(result);
  }

  return new ImageData(result, width, height);
}

/// another options
export function matteChoker2(base64Image, amount) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const chokedImageData = performMatteChoke(imageData, amount);

      ctx.putImageData(chokedImageData, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
    img.src = base64Image;
  });
}

// bria register image
export const registerImageUtil = async (url) => {
  const res = await axiosInstance.post("/v1/models/br/run/masks/register", { url });
  
  return res.data.visualId;
};

export const getOS = () => {
  const platform = window.navigator.platform.toLowerCase();
  if (platform.includes('mac')) {
    return 'Mac';
  } else if (platform.includes('win')) {
    return 'Windows';
  }
  
  return 'Other';
};

export const flattenMenuItems = (items, searchTerm) => {
  let flatItems = [];
  const uniqueIds = new Set();

  const searchAndFlatten = (item) => {
    Object.keys(item).forEach((key) => {
      const currentItem = item[key];
      
      // Check if we have a valid item without children
      if (!currentItem.children && currentItem.id && !uniqueIds.has(currentItem.id)) {
        const searchTermLower = searchTerm.toLowerCase();
        
        // Check displayName
        const matchesDisplayName = currentItem.displayName.toLowerCase().includes(searchTermLower);
        
        // Check aliases
        const matchesAlias = currentItem.alias?.some((alias) =>
          alias.toLowerCase().includes(searchTermLower),
        );

        if (matchesDisplayName || matchesAlias) {
          flatItems.push(currentItem);
          uniqueIds.add(currentItem.id);
        }
      }
      
      // Continue searching in children
      if (currentItem.children) {
        searchAndFlatten(currentItem.children);
      }
    });
  };

  searchAndFlatten(items);
  
  return flatItems;
};

export const cleanParamsForSaving = (params, latestSeed) => {
  const cleanParams = _.cloneDeep(params);
  if(cleanParams && cleanParams.seed){
    cleanParams.seed = latestSeed;
  }
  
  return cleanParams;
};

/// IMAGE processing functions

export function rgbaToRgb(imageUrl, format = "jpeg") {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Enable cross-origin if needed
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the canvas size to match the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0);

      // Convert the canvas back to a data URL in the desired format
      const rgbImageUrl = canvas.toDataURL(`image/${format}`);

      // Cleanup: remove the canvas
      canvas.remove();

      resolve(rgbImageUrl);
    };

    img.onerror = () => {
      reject(new Error("Failed to load image."));
    };
  });
}

/**
 * Negates the colors of an image from a URL or base64 string.
 * @param {string} imageSource - URL or base64 string of the image
 * @returns {Promise<string>} Promise that resolves to a base64 string of the negated image
 */
export async function negateImageColors(imageSource) {
  // Create an off-screen canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  // Create and load the image
  const image = new Image();
  image.crossOrigin = 'Anonymous'; // Enable CORS if needed
  
  // Wait for the image to load
  await new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = reject;
    image.src = imageSource;
  });
  
  // Set canvas size to match image
  canvas.width = image.width;
  canvas.height = image.height;
  
  // Draw image to canvas
  ctx.drawImage(image, 0, 0);
  
  // Get image data
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  
  // Invert colors
  for (let i = 0; i < imageData.data.length; i += 4) {
    imageData.data[i] = 255 - imageData.data[i];         // Red
    imageData.data[i + 1] = 255 - imageData.data[i + 1]; // Green
    imageData.data[i + 2] = 255 - imageData.data[i + 2]; // Blue
    // Alpha channel (i + 3) remains unchanged
  }
  
  // Put the negated image data back
  ctx.putImageData(imageData, 0, 0);
  
  // Return as base64 string
  return canvas.toDataURL('image/png');
}

export const getHandleId = (nodeId, handleType, handleName) => {
  return `${nodeId}-${handleType}-${handleName}`;
};

/// programatic node creation utils
export const getNodeTemplates = (type, param, schema) => {

  switch(type){
    case 'number':
    case 'integer':
    case 'input-number':
    case 'input-integer':
      const mode = schema.type === 'integer' || schema.type === 'input-integer' ? 'integer' : 'number';

      return {
        handleName: "number",
        template:{
          id: "JbQzwNX9qVhHP59o5yS1",
          type: "number",
          initialData: {
            name: schema.title,
            description: schema.description,
            result: param,
            min: schema.min,
            max: schema.max,
            step: schema.step,
            mode: mode,
          },
        },
      };
    case "boolean":
      return {
        handleName: "option",
        template:{
          id: "UpdngxSEck7aHx92LkSg",
          initialData: {
            name: schema.title,
            description: schema.description,
            result: param,
          },
        },
      };
    case "enum":
      return {
        handleName: "option",
        template: {
          id: "fSfFe05blBrafifRsbzM",
          initialData: {
            name: schema.title,
            description: schema.description,
            result: param,
            options: schema.options,
          },
        },
      };
    case "text":
      return {
        handleName: "string",
        template: {
          id: "iB0UfxX3z6c1LCTaLH65",
          initialData: {
            name: schema.title,
            description: schema.description,
            result: { string: param },
          },
        },
      };
    case "array":
      return {
        handleName: "array",
        template: {
          id: "eUJLF9oGyVVprrX1OURL",
          initialData: {
            name: schema.title,
            description: schema.description,
            result: param || [``],
            placeholder: `${schema.title} item`,
          },
        },
      };
    case "seed":
      return {
        handleName: "seed",
        template: {
          id: "HMTea9nBdmeBeoCAdxvz",
          initialData: {
            name: schema.title,
            description: schema.description,
            result: param,
            isRandom: param.isRandom,
            seed: param.seed,
          },
        },
      };
  }
};

export const getModelPrice = (model, version = null, duration) => {
  if(model === "kling"){
    switch(version){
      case "Standard":
        if(duration === 5) {
          return 15;
        }
        if (duration === 10) {
          return 30;
        }
        break;
      case "Pro 1.5":
      case "Pro 1.6":
      case "Pro":
        if (duration === 5) {
          return 65;
        }
        if (duration === 10) {
          return 130;
        }
        break;
    }
  }
  else if(model === "rw_video"){
    if(duration === 5) {
      return 25;
    }
    if (duration === 10) {
      return 50;
    }
  }

  return ReplicateModelNameToCredits.get(model);
};

export const hasEditingPermissions = (role, data) => {
  if(role !== "editor"){
    return false;
  }
  if(data.isLocked){
    return false;
  }

  return true;
};

export const uploadFile = async (acceptedFiles, setUploadProgress, uploadSuccess, setHasError) => {
  if (acceptedFiles.length > 0) {
    const file = acceptedFiles[0];

    const formData = new FormData();
    formData.append("file", file);
    // formData.append("media_metadata", true);

    
    try {
      setUploadProgress(0); // Initialize the progress bar
      const response = await axiosInstance.post(`/v1/nodes/upload`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 95,
          );
          setUploadProgress(progress);
        },
      });
      
      uploadSuccess(response.data);

    } catch (error) {
      console.error("Error uploading file:", error);
      setHasError(true);
    } finally {
      setUploadProgress(0); // Reset the progress bar
    }
  }
};

export function renderMediaElement(mediaUrl, mediaType, containerSize) {
  if (!mediaUrl || !mediaType) {
    return null; // or a default placeholder
  }
  switch (true) {
    case mediaType.includes('video'):
      return <video draggable='false' crossOrigin="anonymous" src={ mediaUrl } width='100%'  controls loop style={ { display:'block' } } />;
    case mediaType.includes('image'):
      return (
        <>
          <img draggable='false' src={ mediaUrl } width='100%' height='100%' alt="Media" style={ { display:'block', objectFit: 'contain' } } />
        </>
      );
    case mediaType.includes('audio'):
      return <audio draggable='false' crossOrigin="anonymous" src={ mediaUrl } width='100%' controls style={ { display:'block' } } />;
    case mediaType.includes('3D'):
      return  <ThreeDeeViewer objUrl={ mediaUrl } containerSize={ containerSize } />;
    // Add more cases for other types
    default:
      return <Typography>Preview</Typography>;
  }
}

export const SmallOutlinedInput = styled(OutlinedInput)`
  & .MuiInputBase-root, & {
    font-size: 0.6rem;
    height: 26px;
  }
  
  & .MuiInputBase-input {
    padding: 4px 6px;
    
    /* Remove arrows from number input */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }

  & .MuiInputAdornment-root {
    font-size: 0.65rem;
  }

  // Change selection outline to white
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${color.Yambo_Idle_Icon} !important;
    border-width: 1px;
  }

  // Also ensure the fieldset border is white when focused
  & fieldset {
    &.Mui-focused {
      border-color: ${color.Yambo_Idle_Icon} !important;
    }
  }
  
  max-width: 70px;
  margin-left: 8px;
`;

export const ExtraSmallOutlinedInput = styled(OutlinedInput)`
  & .MuiInputBase-root, & {
    font-size: 0.6rem;
    height: 26px;
    background-color: ${color.Dark_BG};
  }
  
  & .MuiInputBase-input {
    padding: 4px 6px;
    
    /* Remove arrows from number input */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }

  & .MuiInputAdornment-root {
    font-size: 0.65rem;
  }

  // Change selection outline to white
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${color.Yambo_Idle_Icon} !important;
    border-width: 1px;
  }

  // Also ensure the fieldset border is white when focused
  & fieldset {
    &.Mui-focused {
      border-color: ${color.Yambo_Idle_Icon} !important;
    }
  }
  
  max-width: 70px;
  margin-left: 8px;
`;

export const downloadAllToZip = async (files, modelName) => {
  try {
    const zip = new JSZip();
    
    // Create an array of fetch promises
    const fetchPromises = files.map(async (file, index) => {
      try {
        const response = await fetch(file.url);
        if (!response.ok) throw new Error(`Failed to fetch ${file.url}`);
        
        const blob = await response.blob();
        
        // Use the requested naming convention
        const uniqueFileName = `weavy-${modelName || ''}-${String(index + 1).padStart(4, '0')}.${file.url.split('.').pop()}`;
        
        zip.file(uniqueFileName, blob);
        return { success: true, file };
      } catch (error) {
        return { success: false, file, error: error.message };
      }
    });

    // Wait for all downloads to complete
    const results = await Promise.all(fetchPromises);
    
    // Check for any failures
    const failures = results.filter(r => !r.success);
    if (failures.length > 0) {
      console.error(`Failed to download ${failures.length} files: ${failures.map(f => f.file.url).join(', ')}`);
      return;
    }

    // Generate zip file
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    
    // Create download link
    const downloadUrl = URL.createObjectURL(zipBlob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `weavy-${modelName || ''}-generations.zip`;
    
    // Trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Cleanup
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const downloadFile = async (file, fileName) => {

  try {
    const response = await fetch(file.url);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const generateViewingUrl = (file) => {
  const extension = file.url.split('.').pop();
  const publicId = file.publicId.replace('uploads/', '');
  return `${window.location.origin}/view/${publicId}?type=${file.type}&extension=${extension}`;
};

export const generateCloudinaryUrl = (publicId, extension, type) => {
  return `https://res.cloudinary.com/dpp2flk93/${type}/upload/uploads/${publicId}.${extension}`;
};
