import { Box, Typography, TextField } from '@mui/material';
import {color } from '../../colors';

function ProfileProperty({label, value}) {
    return (
        <Box sx={ {width:'300px', display:'flex', flexDirection:'column', alignItems:'flex-start', mt:2,  justifyContent:'flex-start'} }>
            <Typography sx={ { mb:1 } } variant="body1">{label}</Typography>
            <TextField
             value={value} 
             fullWidth
             disabled
             size="small"
             sx={ { background: `${color.Yambo_BG}` } }
            />
        </Box>
    )
}
export default ProfileProperty;