import React, { useState, useEffect } from 'react';
import { Box, ButtonBase } from '@mui/material';
import { Widget } from '@typeform/embed-react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

function EarlyAccess() {
  const [activeStep] = useState(0);

  const [, setStepTransitions] = useState({ 0: { translateY: '0%', opacity: 1 }, 1: { translateY: '100%', opacity: 0 }, 2: { translateY: '100%', opacity: 0 } });

  const navigate = useNavigate();

  const updateStepTransitions = (newActiveStep) => {
    const newTransitions = {};
    Array.from({ length: 3 }).forEach((_, index) => {
      if (index < newActiveStep) {
        newTransitions[index] = { translateY: '-100%', opacity: 0 }; // Previous
      } else if (index === newActiveStep) {
        newTransitions[index] = { translateY: '0', opacity: 1 }; // Current
      } else {
        newTransitions[index] = { translateY: '100%', opacity: 0 }; // Next
      }
    });
    setStepTransitions(newTransitions);
  };

  useEffect(() => {
    updateStepTransitions(activeStep); // Initialize transitions on mount
  }, []);

  const goToHomepage = () => {
    navigate('/');
  };

  return (
    <Box sx={ { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', position:"relative" } }>
      <Widget id="YNcUBmAy" style={ { width:'100%', height: isMobile ? '90%' : '100%' } } className="my-form" />
      <Box id='hero-header' sx={ { width:'100%', p:2, position:'absolute', top:0 } }>
        <ButtonBase onClick={ goToHomepage }>
          <img src="/logo.svg" alt="" width='30px' />
        </ButtonBase>
      </Box>
    </Box>
  );
}

export default EarlyAccess;
