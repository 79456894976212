import React, { useCallback, useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { Box,Typography, Link, Slider, Input, Checkbox, TextField, Tooltip } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import LoadingButton from '@mui/lab/LoadingButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { usePostHog } from 'posthog-js/react';
import { color } from '../../colors';
import server_url from "../../globals";
import { useUserRole } from "../Recipe/UserRoleContext";
import ModelRunContext from '../Recipe/RunFlow/ModelRunContext';
import NodeImageList from "../Recipe/FlowComponents/NodeImageList";
import { CreditsContext } from '../../services/CreditsContext';
import { runModel } from "./RunModel";
import { SaveCustomNode } from "./SaveCustomNode";
import { extractInputSchemaDetails } from './Utils';

function ImportModelCore ({ id, data ,updateNodeData, setNodesTypes, editable, recipeId, recipeVersion }) {

  const { modelRunTrigger, updateModelRunTriggerStatus } = useContext(ModelRunContext);

  const role = useUserRole();
  const posthog = usePostHog();
  const { setUserCredits } = useContext(CreditsContext);

  const [isProcessing, setIsProcessing] = useState(false);

  const [coverImage, setCoverImage] = useState('');
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [modelName, setModelName] = useState(data.model.name);
  const [modelVersion, setModelVersion] = useState(data.model.version);
  const [cannotFindModel, setCannotFindModel] = useState(false);

  const [progress, setProgress] = useState(0);
  const [predictionStatus, setPredictionStatus] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const predictionCanceled = useRef(null);

  const [dynamicFields, setDynamicFields] = useState({});
  const [modelPropertiesSchema, setModelPropertiesSchema] = useState({});

  const [originalInputHandles] = useState(data.handles.input);

  const [selectedOutput, setSelectedOutput] = useState(data.selectedOutput || 0);


  const { handles, description, model, input, params } = data;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  };

  useEffect(() => {
    posthog.capture('import_model_core_rendered', { id });
  }, []);

  const prevInput = usePrevious(data.input);
  const runInitiatedRef = useRef(new Set());

  const hasNewResults = useRef(null);

  const setOutput = useCallback((selected)=>{
    // console.log("updating the output ");
    const formattedOutput = {};
    handles.output.forEach((elementName, index) => {
      formattedOutput[elementName] = data.result[selected + index];
    });
    updateNodeData(id,{
      selectedOutput: selected,
      output:formattedOutput,
    });
  },[handles.output, data.result]);

  /// change in selected output
  useEffect(()=>{
    if(data.result){
      setOutput(selectedOutput);
    }
  },[selectedOutput]);

  const updateOutputWithNewResults = useCallback(()=>{
    // console.log("updating selected with last index ");
    if(hasNewResults.current){
      setSelectedOutput(data.result? data.result.length -1  : 0);
      setOutput(data.result.length -1);
    }
    hasNewResults.current = null;
        
  },[data.result, hasNewResults]);

  useEffect(() => {
    // console.log("data.result changed");
    if (hasNewResults.current) {
      updateOutputWithNewResults();
    }
  }, [data.result, updateOutputWithNewResults]);

  const parseModelSchema = (rawData) => {
    const schema = extractInputSchemaDetails(rawData);
    setModelPropertiesSchema(schema);
    // console.log("the parsed scheme is: ",schema);
    const isParamsEmpty = !params || Object.keys(params).length === 0;
    // console.log(isParamsEmpty);
    if(isParamsEmpty){
      const initialFields = {};
      Object.keys(schema).forEach((key) => {
        const prop = schema[key];
        if (prop.default !== undefined) {
          initialFields[key] = prop.default;
        } else {
          initialFields[key] = ""; // Default for strings
        }
        if(prop.type === 'enum' && !prop.default) // handles options (enum) when no default is provided
          initialFields[key] = prop.options[0];
      });

      setDynamicFields(initialFields);
    }
    else setDynamicFields(params);
  };
    

  const handleChange = (key, newValue) => {
    // For numerical fields, ensure that the newValue is correctly parsed
    const isNumeric = ['integer', 'number', 'input'].includes(dynamicFields[key]?.type);
    let parsedValue = isNumeric ? parseFloat(newValue) : newValue;
    
    // If the parsedValue is NaN (which can happen if the input is cleared), reset it to a default value or empty string
    if (isNumeric && isNaN(parsedValue)) {
      parsedValue = undefined; // Or a default value like 0, depending on your use case
    }
    
    setDynamicFields((prev) => ({ ...prev, [key]: parsedValue }));
  };

  const resetModelParams = ()=>{
    setModelVersion(null);
    setDynamicFields({});
    setModelPropertiesSchema({});
    updateNodeData(id, {
      handles: {
        ...handles,
        input: originalInputHandles,
      },
    });
  };

  const handleModelNameChange = (event) => {
    setModelName(event.target.value);
    setCannotFindModel(false);
    if(event.target.value === ""){
      resetModelParams();
    }
  };

  const getModelVersionByName = async (modelNameToFetch) => {
    try {
      const response = await axios.get(`${server_url}/v1/models/${modelNameToFetch}`);
      // console.log(response.data);
      setCoverImage(response.data.cover_image_url);

      return response.data.latest_version.id;
    } catch (error) {
      setCannotFindModel(true);
      // console.error("Could not get model version by name ", error);
    }
  };

  const handleModelNamePaste = async (event) =>{
    setModelVersion(null);
    const version = await getModelVersionByName(event.clipboardData.getData('text'));
    if(!version){
      setCannotFindModel(true);
    }
    else setModelVersion(version);
  };

  const getModelVersionParams = async () => {
    try {
      const response = await axios.get(`${server_url}/v1/models/${modelName}/${modelVersion}`);
      parseModelSchema(response.data);
      //  return response.data.latest_version.id;
    } catch (error) {
      // console.error("Could not get version params ", error);
    }
  };

  useEffect(()=>{
    if(modelVersion)
      getModelVersionParams();
  },[modelVersion]);

  const updateModelParams = () => {
    updateNodeData(id, {
      params:
                { ...params,
                  ...dynamicFields,
                },
    },
    );
  };

  useEffect(()=>{
    updateModelParams();
  }, [dynamicFields]);

  useEffect(()=>{
    updateNodeData(id, {
      model:
                { ...model,
                  name:modelName,
                },
    },
    );
  },[modelName]);

  useEffect(()=>{
    updateNodeData(id, {
      model:
                { ...model,
                  version:modelVersion,
                },
    },
    );
  },[modelVersion]);

  const handleInputConnected = useCallback (() => {
    if (input) {
      Object.keys(input).forEach((key) => {
        if (dynamicFields[key] !== undefined) {
          setDynamicFields((prevDynamicFields) => ({
            ...prevDynamicFields,
            [key]: input[key],
          }));
        }
      });
    }
  }, [input]);

  useEffect(()=>{
    /// when a connection is made or present we need to update the params (dynamic fields accordingly)
    handleInputConnected();
  },[input]);

  const handleStopProcessing = (status) => {
    setIsProcessing(false);
    runInitiatedRef.current.delete(id);
    setProgress(0);
    if(status)
      updateModelRunTriggerStatus(id, status);
  };

  const handleStartProcessing = useCallback( () => {
    setErrorMessage(null);
    setPredictionStatus("starting");
    setIsProcessing(true);
    setProgress(0);
    runInitiatedRef.current.add(id);
  },[params, input]);

  /// RUN MODEL AND HADNLE POLLING
  const run =  async () => {
    handleStartProcessing();
    try {

      await runModel(
        handles,
        input,
        { name: modelName, version: modelVersion },
        params,
        id,
        recipeId,
        recipeVersion,
        {
          onProgress: (localProgress) => {
            setProgress(localProgress);
            updateModelRunTriggerStatus(id, "processing", localProgress);
          },
          onSuccess: (results, remainingCredits) => {
            hasNewResults.current = true;

            updateNodeData(id, {
              result: data?.result ? [...data.result, ...results] : results,
            });

            if(remainingCredits != null) {
              setUserCredits(remainingCredits);
            }

            handleStopProcessing("ready");
            posthog.capture('run_model_end', { model: model.name, type: model.name });
          },
          onError: (error) => {
            setErrorMessage(error || "Something went wrong");
            handleStopProcessing("failed");
            posthog.capture('run_model_error', { model: model.name, type: model.name, error });
          },
          onStatusChange: (status) => {
            setPredictionStatus(status);
            if (status === 'initial_processing') {
              updateModelRunTriggerStatus(id, "initial_processing");
            }
          },
        },
      );

    }
    catch (error)
    {
      setErrorMessage(error?.response?.data?.error?.detail || "Something went wrong. Check inputs");
      handleStopProcessing("failed");
      posthog.capture('run_model_error', { model: model.name, type: model.name, error:error?.response?.data?.error?.detail });
    }
  };

  useEffect(()=>{
    const currentModel = modelRunTrigger.find((m) => m.id === id);
        
    if(!currentModel)
      return;

    if(currentModel && !modelVersion){
      updateModelRunTriggerStatus(id, "failed");
      
      return;
    }

    if(currentModel.status === "canceled"){
      predictionCanceled.value = true;
      handleStopProcessing();
      
      return;
    }

    if(runInitiatedRef.current.has(id)) return;

    if(currentModel.status !== "pending") return;

    const inputsChanged = JSON.stringify(input) !== JSON.stringify(prevInput);
        
    setIsProcessing(true);

    /// prevent from running the model twice in parallel (because of the input and modelRunTriggger dependency)
    if(isProcessing)
      return;

    const { predecessors } = currentModel;
    if (!predecessors || predecessors.length === 0) {
      run();
      
      return;
    }

    const predecessorsStatus = predecessors.map((predecessorId) =>
      modelRunTrigger.find((m) => m.id === predecessorId)?.status,
    );

    const allPredecessorsReady = predecessorsStatus.every((status) => status === "ready");
    const anyPredecessorFailedOrCanceled = predecessorsStatus.some((status) => status === "failed" || status === "canceled");
        
    if(anyPredecessorFailedOrCanceled){
      handleStopProcessing("canceled");
      
      return;
    }
    if(allPredecessorsReady && inputsChanged){
      run();
    }
  }, [modelRunTrigger, input]);

  useEffect(()=> {
    if(predictionStatus === "succeeded" && data.result) {
      updateModelRunTriggerStatus(id, "ready", 100);
      runInitiatedRef.current.delete(id);
    }
  },[data.result, predictionStatus]);

  //// RENDER DYNAMIC PARAMS FROM REPLICATE GETVERSION

  const renderDynamicField = (key, property) => {
    const value = dynamicFields[key];
       
    switch (property.type) {
      case "integer":
      case "number":
        return (
          <Box key={ `${key}-slider` }  sx={ { mb:2 } }>
            <Box sx={ { display:'flex', flexDirection:'row', alignItems:'center' } }>
              {/* <Box >
                                <Checkbox size="small" onChange={(event)=>{handleExposeHandle(event, key)}}/>
                            </Box> */}
              <Box sx={ { width:'100%', ml:1 } }>
                <Typography variant='caption' sx={ { mr:.5 } }>{property.title}</Typography>
                <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
                  <HelpOutlineIcon fontSize="10px" />
                </Tooltip>
                {/* </Box> */}
                <Box sx={ { display:'flex', flexDirection:'row' } }>
                  <Slider
                    disabled={ property.expose }
                    valueLabelDisplay="auto"
                    value={ value }
                    onChange={ (e, newValue) => handleChange(key, newValue) }
                    aria-labelledby="input-slider"
                    min={ property.min }
                    max={ property.max }
                    step={ property.type === 'integer' ? 1: 0.1 }
                    size="small"
                    sx={ { mr:2, width:'80%' } }

                  />
                  <Input
                    disabled={ property.expose }
                    value={ value }
                    size="small"
                    sx={ { fontSize:'10px' } }
                    onChange={ (e, newValue) => handleChange(key, newValue) }
                    onBlur={ (e)=>{
                      let newValue = parseFloat(e.target.value);
                      newValue = Math.max(property.min, newValue);
                      newValue = Math.min(property.max, newValue);
                      handleChange(key, newValue);
                    } }
                    inputProps={ {
                      step: property.type === "integer" ? 1 : 0.1,
                      min: property.min,
                      max: property.max,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    } }

                  />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      case "input":
        return (
          <Box key={ `${key}-textfield` } sx={ { mb:2 } }>
            <Typography variant="caption" sx={ { mr:.5 } }>{property.title}</Typography>
            <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
              <HelpOutlineIcon fontSize="10px" />
            </Tooltip>
            <Input
              key={ `${key}-textfield` }
              fullWidth
              // label={key}
              value={ value }
              size="small"
              inputProps={ { type: 'number' } }
              onChange={ (e) => handleChange(key, parseInt(e.target.value)) }
            />
          </Box>
        );
      case "enum":
        return (
          <Box key={ `${key}-enum` } sx={ { mb:2 } }>
            <Typography variant="caption" sx={ { mr:.5 } }>{property.title}</Typography>
            <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
              <HelpOutlineIcon fontSize="10px" />
            </Tooltip>
            <FormControl fullWidth >
              {/* <InputLabel id={`${key}-label`}>{property.title}</InputLabel> */}
              <Select
                labelId={ `${key}-label` }
                id={ key }
                value={ value }
                // label={property.title}
                onChange={ (e) => handleChange(key, e.target.value) }
                size="small"
              >
                {property.options.map((option) => (
                  <MenuItem key={ option } value={ option }>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        );
            
      case "boolean":
        return (
          <Box key={ `${key}-enum` } sx={ { mb:2, display:'flex', flexDirection:'row', alignItems:'center' } } >
            <FormControl >
              <Checkbox inputProps={ { 'aria-label': 'Checkbox' } } checked={ value } onChange={ (e) => handleChange(key, e.target.checked) } />
            </FormControl>
            <Typography variant="caption" sx={ { mr:.5 } }>{property.title}</Typography>
            <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
              <HelpOutlineIcon fontSize="10px" />
            </Tooltip>
                      
          </Box>
        );
      case "string":
        return (
          <Box key={ `${key}-text` } sx={ { mb:2 } } >
            <Typography variant="caption" sx={ { mr:.5 } }>{property.title}</Typography>
            <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
              <HelpOutlineIcon fontSize="10px" />
            </Tooltip>
            <TextField
              fullWidth
              id={ key }
              value={ value }
              onChange={ (e) => handleChange(key, e.target.value) }
              size="small"
            />
                        
          </Box>
        );
      default:
        return null;
    }
  };

  const toggleAdvancedSection = () => {
    setIsAdvancedOpen(!isAdvancedOpen);
  };
  const renderStatus = (status) => {
    switch (status){
      case "starting":
        return (
          <Typography>Starting</Typography>
        );
      case "processing":
        return (
          <Typography>{progress}%</Typography>
        );
      default:
        return (
          <Typography>Waiting</Typography>
        );
    }
  };

  return(
    <>
        
      <Typography variant="caption">{description}</Typography>
      <Box  className="nowheel nodrag nopan" sx={ { mt:2, width:'100%', position:'relative' } }>
        <TextField
          autoComplete="new-password"// prevent autocomplete
          disabled={ !editable }
          sx={ { mb:1 } }
          size="small"
          label="Model Name"
          fullWidth
          value={ modelName }
          onChange={ handleModelNameChange }
          onPaste={ (event) => handleModelNamePaste(event) }
        />
        {cannotFindModel && <Typography color={ color.Red } variant="caption" sx={ { mt:-1, fontSize:'9px', position:'relative', top:'-5px', left:'2px' } }>Could not find model</Typography>}
        {/* <TextField
                size="small"
                label="Model Version"
                fullWidth
                value={modelVersion}
                onChange={handleModelVersionChange}
                /> */}
      </Box>
      {coverImage && !data.result && <Box sx={ { width:'100%', mb:1 } }>
        <img src={ coverImage } width={ '100%' } />
      </Box>}
      {data.result && <NodeImageList nodeName={ data.name } images={ data.result } selected={ selectedOutput } setSelected={ setSelectedOutput } />}


      <LoadingButton
        size="small"
        onClick={ ()=>run() }
        endIcon={ data.result? <ReplayIcon />: <PlayArrowIcon /> }
        loading={ isProcessing }
        loadingPosition="end"
        variant="contained"
        fullWidth
        color="weavy_cta"
        disabled={ !modelVersion || role !== 'editor' }
      >
        {isProcessing ? (
          renderStatus(predictionStatus)
        ):(
          <>{data.result? 'Re-run model':'Run Model'}</>
        )}
      </LoadingButton>
           
      {/* Advances mode */}
      {Object.keys(modelPropertiesSchema).length !== 0 && <Box id='advanced-mode-container' sx={ { width:'100%', mt:1 } }>
        <Box id='advanced-mode-button' sx={ { width:'100%', textAlign:'right', mb:1 } }>
          <Link
            component="button"
            variant="caption"
            onClick={ toggleAdvancedSection }
          >
            {isAdvancedOpen ? "Close" : "Advance"}
          </Link>
        </Box>
        {isAdvancedOpen && (

          <Box id='advnaced-mode-content-container' className="nowheel nodrag nopan">
            {Object.keys(modelPropertiesSchema).map((key) =>
              renderDynamicField(key, modelPropertiesSchema[key]),
            )}
                  

          </Box>
        )}
      </Box>}
            
      {modelName &&  modelVersion && editable && role==='editor' && <SaveCustomNode id={ id } data={ data } setNodesTypes={ setNodesTypes } />}
      {errorMessage && <Typography variant="caption" color='error'>{errorMessage}</Typography>}

    </>

  );
}

export default ImportModelCore;
