import React, { useEffect, useState } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { useUserRole } from "../Recipe/UserRoleContext";
import { hasEditingPermissions } from "./Utils";
function BooleanCore({ id, data, updateNodeData }) {

  const role = useUserRole();

  const [bool, setBool] = useState(data.result ?? true);

  useEffect(()=>{
    if(data.externalData !== undefined){
      setBool(data.externalData);
    }
  }, [data.externalData]);

  useEffect(()=>{
    updateNodeData(id, {
      result:bool,
      output:{
        type: "boolean",
        [data.handles.output[0]]: bool,
      },
      
    });
  }, [bool]);

  return (
    <Box sx={ {
      width: '100%',
      pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '',
    } }
    >
      <FormControlLabel control={ <Switch checked={ bool } onChange={ () => setBool(!bool) } /> } label={ bool ? "True" : "False" } />
    </Box>
  );
}

export default BooleanCore;
