import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { color } from '../../../colors';
import I18N_KEYS from '../../../language/keys';

function ReadOnlyPanel({ recipeId, width, height, duplicateRecipe, viewingVersionMode }) {
  const { t: translate } = useTranslation();

  const handleGoBack = () => {
    window.location.href = window.location.pathname;
  };

  const handleDuplicate = async () => {
    try {
      await duplicateRecipe(recipeId);
    } catch (error) {
      console.log("error duplicating recipe: ", error);
    }
  };

  return (
    <Box
      sx={ {
        width: `${width}px`,
        height: `${height}px`,
        mb: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      } }
    >
      <Box
        sx={ {
          width: '100%',
          height: '100%',
          background:`${color.Dark_Blue}CC`,
          border: `1px solid ${color.Dark_Grey}`,
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 1,
        } }
      >
        <Typography variant="caption" fontWeight="bold">
          {viewingVersionMode ?
            translate(I18N_KEYS.RECIPE_MAIN.FLOW.GENERAL.READ_ONLY_PANEL.TITLE_EDITOR)            :
            translate(I18N_KEYS.RECIPE_MAIN.FLOW.GENERAL.READ_ONLY_PANEL.TITLE_GUEST)
          }
        </Typography>
        {viewingVersionMode ?
          <Button variant="contained" color="weavy_cta" size="small" onClick={ handleGoBack }>
            {translate(I18N_KEYS.RECIPE_MAIN.FLOW.GENERAL.READ_ONLY_PANEL.CTA_EDITOR)}
          </Button>                :
          <Button variant="contained" color="weavy_cta" size="small" onClick={ handleDuplicate }>
            {translate(I18N_KEYS.RECIPE_MAIN.FLOW.GENERAL.READ_ONLY_PANEL.CTA_GUEST)}
          </Button>
        }
      </Box>
    </Box>
  );
}

export default ReadOnlyPanel;
