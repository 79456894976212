/* eslint-disable no-template-curly-in-string */
import KEYS from './keys';

const EN_TEXT = {
  [KEYS.GENERAL.CANCEL]: 'Cancel',
  [KEYS.GENERAL.RENAME]: 'Rename',
  [KEYS.GENERAL.CLOSE]: 'Close',
  [KEYS.GENERAL.SAVE]: 'Save',
  [KEYS.GENERAL.DONE]: 'Done',
  [KEYS.GENERAL.SAVING]: 'Saving...',
  [KEYS.GENERAL.FILE]: 'File',
  [KEYS.GENERAL.URL]: 'URL',
  [KEYS.GENERAL.IMAGE]: 'Image',
  [KEYS.GENERAL.NAME]: 'Name',
  [KEYS.GENERAL.COPIED]: 'Copied!',
  [KEYS.GENERAL.DOWNLOAD]: 'Download',
  [KEYS.GENERAL.COPY_LINK]: 'Copy Link',
  [KEYS.GENERAL.UPLOAD_ERROR]: 'Something went wrong. Please try again.',
  [KEYS.PAYMENTS.SUCCESS_MESSAGE]: '🎉&nbsp;Credits charged!&nbsp;🎉',
  [KEYS.NAVIGATION_DRAWER.BUY_MORE]: 'Buy More',
  [KEYS.DASHBOARD_BANNER.MESSAGE]: '🚀 🎥 Luma Dream Machine is now officially on Weavy 🎥 🚀',
  [KEYS.DASHBOARD_BANNER.CTA]: 'Check it out!',
  [KEYS.SETTINGS.TITLE]: 'Settings',
  [KEYS.SETTINGS.LOGOUT]: 'Logout',
  [KEYS.SETTINGS.SETTINGS]: 'Settings',
  [KEYS.SETTINGS.MENU.BILLING]: 'Billing',
  [KEYS.SETTINGS.MENU.PROFILE]: 'Profile',
  [KEYS.SETTINGS.MENU.TEAM]: 'Team',
  [KEYS.SETTINGS.MENU.TEAM_WORKSPACE]: 'Team & Workspace',
  [KEYS.SETTINGS.SECTIONS_TITLES.PROFILE]: 'My Profile',
  [KEYS.SETTINGS.SECTIONS_TITLES.TEAM]: 'My Team',
  [KEYS.SETTINGS.SECTIONS_TITLES.TEAM_WORKSPACE]: 'Team & Workspace Settings',
  [KEYS.SETTINGS.SECTIONS_TITLES.BILLING]: 'Manage Billing',
  [KEYS.SETTINGS.DELETE_ACCOUNT]: 'Delete Account',
  [KEYS.SETTINGS.TEAM.USER_STATUS.INVITED]: 'Invited',
  [KEYS.SETTINGS.TEAM.USER_STATUS.ACTIVE]: 'Active',
  [KEYS.SETTINGS.TEAM.INVITE_USER_MODAL.TITLE]: 'Invite Team Members',
  [KEYS.SETTINGS.TEAM.INVITE_USER_MODAL.SUCCESS_MESSAGE]: 'Invitation was sent',
  [KEYS.SETTINGS.TEAM.COPY_INVITE_LINK]: 'Copy Invite Link',
  [KEYS.SETTINGS.TEAM.WORKSPACE_NAME]: 'Workspace Name:',
  [KEYS.SETTINGS.TEAM.WORKSPACE_CREDITS]: 'Credits:',
  [KEYS.SETTINGS.TEAM.WORKSPACE_CREDITS_CTA]: 'Get more',
  [KEYS.SETTINGS.TEAM.WORKSPACE_NAME_CHANGE]: 'Change Name',
  [KEYS.SETTINGS.TEAM.WORKSPACE_NAME_PLACEHOLDER]: 'Workspace Name',
  [KEYS.SETTINGS.TEAM.WORKSPACE_MEMBERS_CAN_SEE_CREDITS]: 'Members can see credits',
  [KEYS.SETTINGS.TEAM.TEAM_TABLE.TABLE_TITLE]: 'Team Members',
  [KEYS.SETTINGS.TEAM.TEAM_TABLE.SEARCH_PLACEHOLDER]: 'Search members',
  [KEYS.SETTINGS.TEAM.TEAM_TABLE.ADD_MEMBERS_BUTTON]: 'Add Members',
  [KEYS.SETTINGS.TEAM.EMPTY_STATE.TITLE]: 'No one to weave with yet...',
  [KEYS.SETTINGS.TEAM.EMPTY_STATE.CTA]: 'Add Your First Member',
  [KEYS.SETTINGS.TEAM.REMOVE_MEMBER_DIALOG.CONTENT]: 'Are you sure you want to remove this member?',
  [KEYS.SETTINGS.TEAM.ACTIVATION_PAGE.TITLE]: 'Join {{workspace_placeholder}} on Weavy!',
  [KEYS.SETTINGS.TEAM.ACTIVATION_PAGE.SUBTITLE]: 'Sign in or sign up with {{email_placeholder}} to join',
  [KEYS.SETTINGS.TEAM.JOIN_TEAM_SUCCESS]: 'You\'ve successfully joined the team!',
  [KEYS.SETTINGS.TEAM.ACTIVATION_PAGE.ERROR_TITLE]: 'Looks like the activation URL is not valid.',
  [KEYS.SETTINGS.TEAM.ACTIVATION_PAGE.ERROR_SUBTITLE]: 'Please contact your team admin.',
  [KEYS.MAIN_DASHBOARD.SHARED_WITH_ME]: 'Shared with me',
  [KEYS.MAIN_DASHBOARD.SHARED_APPS]: 'Apps shared with me',
  [KEYS.MAIN_DASHBOARD.SECTION_TITLE.RECENT]: 'Recent',
  [KEYS.MAIN_DASHBOARD.SECTION_TITLE.FAVORITES]: 'Favorites',
  [KEYS.MAIN_DASHBOARD.SECTION_TITLE.SHARED_RECIPES]: 'Shared with me',
  [KEYS.MAIN_DASHBOARD.CREATE_NEW_FILE]: 'Create new File',
  [KEYS.MAIN_DASHBOARD.EMPTY_STATE.FILES]: 'Nothing to Weave Yet...',
  [KEYS.MAIN_DASHBOARD.EMPTY_STATE.APPS]: 'Nothing to Weave Yet...',
  [KEYS.MAIN_DASHBOARD.DELETE_DIALOG.CONFIRMATION_DELETE_FILE]: 'Are you sure you want to delete this file?',
  [KEYS.MAIN_DASHBOARD.DELETE_DIALOG.CONFIRMATION_DELETE_APP]: 'Are you sure you want to delete this app?',
  [KEYS.MAIN_DASHBOARD.DELETE_DIALOG.CONFIRM]: 'Yes, Delete',
  [KEYS.MAIN_DASHBOARD.DELETE_DIALOG.CANCEL]: 'Cancel',
  [KEYS.UPGRADE_MODAL.TITLE]: 'Buy more credits for our premium models',
  [KEYS.UPGRADE_MODAL.CTA]: 'Learn more...',
  [KEYS.MODEL_NODE.ERROR_REQUIRED]: 'Missing inputs: ',
  [KEYS.MODEL_NODE.ERROR_IMAGE_INPUT]: 'Cannot read input image',
  [KEYS.RECIPE_MAIN.ERROR_SAVE]: 'Unable to save your work. Please contact support',
  [KEYS.RECIPE_MAIN.FLOW.FLOW_NAVBAR.DUPLICATE_TO_MY_FILES]: 'Duplicate to My Files',
  [KEYS.RECIPE_MAIN.FLOW.FLOW_NAVBAR.RESTORE_VERSION]: 'Restore This Version',
  [KEYS.RECIPE_MAIN.FLOW.FLOW_NAVBAR.READ_ONLY]: 'Read Only',
  [KEYS.RECIPE_MAIN.FLOW.FLOW_NAVBAR.SAVING]: 'Saving',
  [KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.SHOW_INFO]: 'Show Info',
  [KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.HIDE_INFO]: 'Hide Info',
  [KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.PROMPT]: 'Prompt',
  [KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.NEGATIVE_PROMPT]: 'Negative Prompt',
  [KEYS.RECIPE_MAIN.FLOW.GALLERY.PARAMS.PARAMETERS]: 'Parameters',
  [KEYS.RECIPE_MAIN.FLOW.GENERAL.READ_ONLY_PANEL.TITLE_EDITOR]: 'You\'re viewing a previous version of this workflow',
  [KEYS.RECIPE_MAIN.FLOW.GENERAL.READ_ONLY_PANEL.TITLE_GUEST]: 'You\'re viewing a ready-only version of this workflow',
  [KEYS.RECIPE_MAIN.FLOW.GENERAL.READ_ONLY_PANEL.CTA_EDITOR]: 'Go Back',
  [KEYS.RECIPE_MAIN.FLOW.GENERAL.READ_ONLY_PANEL.CTA_GUEST]: 'Duplicate to My Files',
  [KEYS.UPLOAD_LORA_NODE.UPLOAD_BUTTON_TEXT]: 'Click to Upload',
  [KEYS.UPLOAD_LORA_NODE.UPLOAD_ANOTHER_BUTTON_TEXT_FILE]: 'Click to Upload another file',
  [KEYS.UPLOAD_LORA_NODE.TRIGGER]: 'Trigger',
  [KEYS.UPLOAD_LORA_NODE.DEFAULT_WEIGHT]: 'Default Weight',
  [KEYS.UPLOAD_LORA_NODE.UPLOADING]: 'Uploading...',
  [KEYS.SHARED_DESIGN_APP.MODAL.DEFAULT_ERROR]: 'Failed to generate share link. Please try again.',
  [KEYS.SHARED_DESIGN_APP.MODAL.TITLE]: 'Share Design App',
  [KEYS.SHARED_DESIGN_APP.MODAL.LINK_COPIED]:'Link Copied!',
  [KEYS.SHARED_DESIGN_APP.MODAL.BUTTON_CTA]: 'Generate one-time link',
  [KEYS.FLOW_NAVBAR.SHARED_DESIGN_APP_BUTTON]: 'Share Design App',
  [KEYS.SHARED_DESIGN_APP.ACTIVATION_PAGE.DEFAULT_ERROR]: 'Invalid or expired link. Please contact the sender for a new link.',
  [KEYS.SHARED_DESIGN_APP.ACTIVATION_PAGE.TITLE]: 'Activating recipe, please wait...',
  [KEYS.MAIN_DASHBOARD.DESIGN_APPS.TITLE]: 'Shared with me',
  [KEYS.RECIPE_MAIN.FLOW.MODEL_PROPERTIES.SET_AS_PARAMETER]: 'Set as parameter',
  [KEYS.RECIPE_MAIN.FLOW.MODEL_PROPERTIES.SET_AS_INPUT]: 'Set as input',
  [KEYS.RECIPE_MAIN.NODES.LOCKED.THIS_NODE_IS_LOCKED]: 'This node is locked',
  [KEYS.RECIPE_MAIN.NODES.LOCKED.CLICK_TO_UNLOCK]: 'Click to unlock',
  [KEYS.RECIPE_MAIN.NODES.NODE_MENU.LOCK]: 'Lock',
  [KEYS.RECIPE_MAIN.NODES.NODE_MENU.UNLOCK]: 'Unlock',
  [KEYS.RECIPE_MAIN.NODES.NODE_MENU.RENAME]: 'Rename',
  [KEYS.RECIPE_MAIN.NODES.NODE_MENU.DUPLICATE]: 'Duplicate',
  [KEYS.RECIPE_MAIN.NODES.NODE_MENU.DELETE]: 'Delete',
  [KEYS.RECIPE_MAIN.NODES.MULTI_LORA.ADD_LORA]: '+ Add another LoRA',
  [KEYS.RECIPE_MAIN.NODES.MULTI_LORA.LORAS]: 'LoRAs List',
  [KEYS.RECIPE_MAIN.NODES.MULTI_LORA.SELECT_LORA]: 'Select a LoRA',
  [KEYS.NODE_IMAGE_LIST.CONTEXT_MENU.SET_AS_COVER]: 'Set as cover image',
  [KEYS.NODE_IMAGE_LIST.CONTEXT_MENU.DOWNLOAD]: 'Download current',
  [KEYS.NODE_IMAGE_LIST.CONTEXT_MENU.DOWNLOAD_ALL]: 'Download all',
  [KEYS.NODE_IMAGE_LIST.DELETE_CURRENT]: 'Delete current',
  [KEYS.NODE_IMAGE_LIST.DELETE_ALL]: 'Delete all',
  [KEYS.NODE_IMAGE_LIST.DELETE_ALL_OTHERS]: 'Delete all others',
  [KEYS.SHARED_DESIGN_APP.PUBLISH_DESIGN_APP_BUTTON]: 'Publish',
  [KEYS.SHARED_DESIGN_APP.PREVIEW_DESIGN_APP_BUTTON]: 'Preview',
  [KEYS.SHARED_DESIGN_APP.EDIT_DESIGN_APP_BUTTON]: 'Keep Editing',
  [KEYS.SHARED_DESIGN_APP.PUBLISH_DESIGN_APP_SUCCESS]: 'Design App Published!',
  [KEYS.SHARED_DESIGN_APP.PUBLISH_DESIGN_APP_FAILED]: 'Design App Publish Failed!',
  [KEYS.SHARED_DESIGN_APP.TOOLBAR.LATEST_VERSION]: 'Latest Version',
  [KEYS.SHARED_DESIGN_APP.TOOLBAR.CURRENT_VERSION]: 'Current',
  [KEYS.SHARED_DESIGN_APP.TOOLBAR.RESTORE_VERSION]: 'Restore This Version',
  [KEYS.SHARED_DESIGN_APP.TOOLBAR.VIEW_VERSION]: 'View',
  [KEYS.SHARED_DESIGN_APP.TOOLBAR.NO_VERSIONS]: 'Ready to release?',
  [KEYS.SHARED_DESIGN_APP.TOOLBAR.LAST_PUBLISHED_AT]: 'Last Published At',
  [KEYS.SHARED_DESIGN_APP.NO_INPUTS.TITLE]: 'No inputs found',
  [KEYS.SHARED_DESIGN_APP.NO_INPUTS.TIP]: 'Connect the Output node to your workflow',
  [KEYS.SHARED_DESIGN_APP.UPLOAD_FILE.CTA]: 'Drag & Drop or Click to Upload',
  [KEYS.SHARED_DESIGN_APP.UPLOAD_FILE.PLACEHOLDER]: 'This is a placeholder',
  [KEYS.SHARED_DESIGN_APP.INPUTS.DEFAULTS_COMMENT]: 'Set the default values for all users:',
  [KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR]: 'This field is required',
  [KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR_FILE]: 'File is required',
  [KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR_LORA]: 'Please select a LoRA',
  [KEYS.SHARED_DESIGN_APP.INPUTS.HEADER.ADD_DESCRIPTION]: '+ Add a description',
  [KEYS.SHARED_DESIGN_APP.INPUTS.HEADER.DESCRIPTION_PLACEHOLDER]: 'A meaningful description of this input',
  [KEYS.SHARED_DESIGN_APP.INPUTS.PROMPT.PLACEHOLDER]: 'Give a prompt example',
  [KEYS.SHARED_DESIGN_APP.INPUTS.IMPORT.UPLOAD_ANOTHER]: 'Click to replace',
  [KEYS.SHARE_WORKFLOW_MODAL.TITLE]: 'Invite others',
  [KEYS.SHARE_WORKFLOW_MODAL.EMAIL_LABEL]: 'Email Address',
  [KEYS.SHARE_WORKFLOW_MODAL.EMAIL_PLACEHOLDER]: 'Enter email address',
  [KEYS.SHARE_WORKFLOW_MODAL.SHARE_BUTTON]: 'Send Invite',
  [KEYS.SHARE_WORKFLOW_MODAL.EMAIL_PLACEHOLDER]: 'example@email.com',
  [KEYS.SHARE_WORKFLOW_MODAL.COPY_LINK]: 'Copy Link',
  [KEYS.SHARE_WORKFLOW_MODAL.COPIED]: 'Copied!',
  [KEYS.SHARE_WORKFLOW_MODAL.LINK.RESTRICTED]: 'Only people with access can view this workflow',
  [KEYS.SHARE_WORKFLOW_MODAL.LINK.TEAM]: 'Anyone in your workspace can view this workflow',
  [KEYS.SHARE_WORKFLOW_MODAL.LINK.ANYONE]: 'Anyone with the link can view this workflow',
  [KEYS.SHARE_WORKFLOW_MODAL.SUCCESS_MESSAGE]: 'File shared successfully',
  [KEYS.SHARE_WORKFLOW_MODAL.SHARE_BUTTON_LOADING]: 'Sending...',
  [KEYS.SHARE_WORKFLOW_MODAL.INPUTS.PROMPT_PLACEHOLDER]: 'Describe your image',
  [KEYS.SHARE_WORKFLOW_MODAL.LINK.ORGANIZATION]: 'Anyone at {{orgName}} can view this workflow',
};

export default EN_TEXT;
