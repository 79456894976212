import { Box, TextField, Skeleton } from "@mui/material";
import { color } from "../../../colors";
import { DesignAppMode } from "../../../enums/design-app-modes.enum";

function DesignAppPrompt({ id, value, onChange, isLoading, setIsEditingInputMetadata, updateNodeData, role, mode ,error, helperText }) {

  const onPromptChange = (event) => {
    const updatedPrompt = event.target.value;
    if(role === 'editor' && mode === DesignAppMode.Editing) {
      updateNodeData(id,
        {
          externalData: {
          prompt: updatedPrompt,
        },
      });
    }
    onChange(id, { prompt: updatedPrompt });
  };
    
  return (
    <Box>
      {isLoading ?
        <Box
          sx={ {
            width:'100%',
            height:'110px',
            display:'flex',
            flexDirection:'column',
            border: `1px solid ${color.Yambo_Text_On_Dark_Transparent}`,
            borderRadius: 1,
            p: 2,
          } }
        >
          <Skeleton animation="wave" width="100%" height={ 14 } />
          <Skeleton animation="wave" width="50%" height={ 14 } />
          <Skeleton animation="wave" width="80%" height={ 14 } />
          <Skeleton animation="wave" width="60%" height={ 14 } />
        </Box>        :
        <TextField
          error={error}
          helperText={helperText}
          placeholder={ value }
          fullWidth
          multiline
          onFocus={ () => setIsEditingInputMetadata(true) }
          onBlur={ () => setIsEditingInputMetadata(false) }
          value={ value }
          onChange={ onPromptChange }
          rows={ 4 }
          inputProps={ {
            style: { resize: "vertical" },
          } }
          sx={ {
            background: `${color.Yambo_BG}`,
            '& .MuiFormHelperText-root': {
              background: `none`,
              margin: 0,
              paddingLeft: 0,
              paddingTop: .5,
            }
          } }
        />
      }
    </Box>
  );
}

export default DesignAppPrompt;
