import React, { useState } from "react";
import { Box, Typography, LinearProgress, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { ref, uploadBytesResumable } from 'firebase/storage';
import { colorMap } from '../../colors';
import { AuthContext } from '../../AuthContext';
import { storage } from '../../firebase';
import I18N_KEYS from '../../language/keys';
import { useUserRole } from "../Recipe/UserRoleContext";
import { DynamicNode2 } from "./VisualNodes";
import { hasEditingPermissions } from "./Utils";
function UploadLoraNode({ id, data, updateNodeData }) {
  const { currentUser } = React.useContext(AuthContext);
  const { t: translate } = useTranslation();
  const role = useUserRole();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const uploadSuccess = (lora) => {
    // Schedule the state update to prevent immediate layout changes
    updateNodeData(id, {
      result: {
        lora,
      },
      output: {
        type: "text",
        lora: `weavy-lora::${lora}`,
      },
    });
    setUploadProgress(100);
    setTimeout(() => {
      setIsUploading(false); // Stop the loader after a slight delay
    }, 100); // Adjust delay as needed
  };

  const uploadFileToFirebase = async (file) => {
    const lora = `${file.name}-${new Date().toISOString()}`;
    const storageRef = ref(storage, `LoRas/${currentUser.uid}/${lora}`);
    setIsUploading(true);

    try {
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Upload error:', error);
          setErrorMessage("File upload failed. Please try again.");
          setIsUploading(false);
        },
        () => {
          uploadSuccess(lora);
        },
      );
    } catch (error) {
      console.error('Upload error:', error);
      setErrorMessage("File upload failed. Please try again.");
      setIsUploading(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadFileToFirebase(file);
    }
  };

  const extension = '.safetensors';
  const filename = data?.result?.lora?.indexOf(extension) > 0 ? data.result.lora.substring(0, data.result.lora.indexOf(extension) + extension.length) : data?.result?.lora;

  return (
    <DynamicNode2 id={ id } data={ data } className="import" handleColor={ colorMap.get(data.color) } backgroundColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      <Box sx={ { width: '100%' } }>
        {data && data.result ? (
          <Box
            sx={ {
              display: 'flex',
              flexDirection: 'column',
              alignItems: "flex-end",
              width:'100%',
              mt:1,
            } }
          >
            <TextField
              className="nowheel nodrag nopan"
              value={ filename }
              label="LoRA"
              multiline
              rows={ 3 }
              fullWidth
              size="small"
              disabled
            />
          </Box>
        ) : (
          <Box id="upload-container" sx={ { textAlign: 'center' } }>
            {!isUploading && (
              <label
                htmlFor="file-input"
                style={ {
                  cursor: "pointer",
                  height: '60px',
                  border: '1px dashed',
                  borderColor: colorMap.get(data.color),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1,
                  padding: '10px',
                  borderRadius: '8px',
                } }
              >
                <Typography variant="body">
                  {translate(I18N_KEYS.UPLOAD_LORA_NODE.UPLOAD_BUTTON_TEXT)}
                </Typography>
              </label>
            )}
            <input
              id="file-input"
              type="file"
              onChange={ handleFileUpload }
              accept=".safetensors"
              style={ { display: "none" } }
              disabled={ !hasEditingPermissions(role, data) }
            />
            {isUploading && (
              <Box sx={ { width: '100%', mt: 2 } }>
                <Typography variant="body">
                  {translate(I18N_KEYS.UPLOAD_LORA_NODE.UPLOADING)}
                </Typography>
                <LinearProgress variant="determinate" value={ uploadProgress } sx={ { mt: 1 } } />
              </Box>
            )}
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          </Box>
        )}
      </Box>
    </DynamicNode2>
  );
}

export default UploadLoraNode;
