import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, Typography, ButtonBase, Menu, MenuItem, Divider, Tooltip, Link } from '@mui/material';
import { useUserRole } from '../Recipe/UserRoleContext';
import { useNodeActions } from '../Nodes/NodeActionsContext';
import { color } from '../../colors';
import { getHandleId, hasEditingPermissions, getOS } from './Utils';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import I18N_KEYS from "../../language/keys";

export const OutputOnly = ({ id, data, children, className, handleColor, headerColor }) => {
  const { handles } = data;

  const role = useUserRole();

  return (
    <>
      <Box className={ `custom-node output-only ${className}` } sx={ { cursor: role === "guest" ? 'default': '' } }>
        <Box className="node-header" sx={ { width:'100%', background:headerColor, flexDirection:'row', display:'flex' } }>
          <img src='/icons/dots.svg' width='8px' style={ { opacity:.8 } } />
          <Typography variant='body1' sx={ { ml:1 } }>{data.name}</Typography>
        </Box>
        <Box className="node-content" sx={ { width:'100%' } }>
          {/* <Box>{id}</Box> */}
          {children}
        </Box>
        <Handle type="source"
          style={ {
            top:'18px',
            background:handleColor,
            cursor: role === 'guest' ? 'default' : '', // Disable pointer for guests
            pointerEvents: role === 'guest'  ? 'none' : 'auto',
          } } position={ Position.Right } id={ getHandleId(id, 'output', handles.output[0]) } //{`${id}-output-${handles.output[0]}`}
        >
          <Box sx={ { position:'relative',left:30, top:-20 } }>
            {handles.output[0]}
          </Box>
                
        </Handle>
      </Box>
    </>
  );
};

export const InputOnly = ({ id,  data, children, className,handleColor , headerColor }) => {
    
  return (
    <Box className={ `custom-node input-only ${className}` }>
            
      <Box className="node-header" sx={ { width:'100%', background:headerColor, flexDirection:'row', display:'flex' } }>
        <img src='/icons/dots.svg' width='8px' style={ { opacity:.8 } } />
        <Typography variant='body1' sx={ { ml:1 } }>{data.name}</Typography>
      </Box>
      <Box className="node-content" sx={ { width:'100%' } }>
        {children}
      </Box>
      <Handle
        type="target"
        style={ { top:'18px',background:handleColor } }
        position={ Position.Left }
        id={ getHandleId(id, 'input0',"") } //{`${id}-input0`}
      />
    </Box>
  );
};


export const DynamicNode = ({ id, data, children, className, handleColor, hideTitle }) => {

  const { handles } = data;
  const handlesSpace = 50;
  const handleTop = '40px';

  const role = useUserRole();

  return (
    <Box className={ `custom-node two-input-output ${className}` } sx={ { cursor: role === "guest" ? 'default' : '' } }>
      {!hideTitle &&
      <>
        <Box>{data.name}</Box>
        {data.paid &&
          <Typography>paid</Typography>
        }
      </>
      }
      {/* <Box><Typography>{id}</Typography></Box> */}
      {children}
      {handles.input.map((inputHandle, index)=>(
        <Handle
          key={ index }
          type="target"
          position={ Position.Left }
          style={ {
            top:`calc(${handleTop} + ${index}*${handlesSpace}px)`
            , backgroundColor:handleColor } }
          id={ getHandleId(id, 'input', inputHandle) } //{`${id}-input-${inputHandle}`}
        >
          <Box sx={ { position:'relative',left:-40, top:-20 } }>
            {inputHandle}
          </Box>
        </Handle>
      ))}
      {handles.output.map((outputHandle, index)=>(
        <Handle
          key={ index }
          type="source"
          position={ Position.Right }
          style={ {
            top:`calc(${handleTop} + ${index}*${handlesSpace}px)`
            , backgroundColor:handleColor } }
          id={ getHandleId(id, 'output', outputHandle) } //{`${id}-output-${outputHandle}`}
        >
          <Box sx={ { position:'relative',left:30, top:-20 } }>
            {outputHandle}
          </Box>
        </Handle>
      ))}
            
    </Box>
  );
};

const DynamicHandleLabel = ({ handleType, handlePosition, handleTop, handleSpace, handleColor, handleId, label, index, required, description, format, requiredValidationError }) => {
  const textRef = useRef(null);
  const [labelLeft, setLabelLeft] = useState(-40);

  const role = useUserRole();

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.offsetWidth;
      setLabelLeft(-width);
    }
  }, [label]); // Recalculate if label changes

  const stylesHandleLabel = (labelToHandle) => {
    return labelToHandle.replaceAll('_',' ');
  };

  return (
    <Handle
      type={ handleType }
      position={ handlePosition }
      style={ {
        top: `calc(${handleTop} + ${index * handleSpace}px)`,
        backgroundColor: handleColor,
        cursor: role === 'guest' ? 'default' : '', // Disable pointer for guests
        pointerEvents: role === 'guest'  ? 'none' : 'auto',
      } }
      id={ handleId }
    >
      {handlePosition === 'left' ?
        (
          <Tooltip  title={ format && "["+format+"] "+description } placement='right-start' enterDelay={ 800 }>
            <Box sx={ { position: 'relative', left: labelLeft, top: -20, width:'fit-content' } } ref={ textRef }>
              <span style={ { textTransform:'capitalize', whiteSpace:'nowrap', fontWeight:'bold', color:requiredValidationError? color.Yambo_Orange:'' } }>
                {stylesHandleLabel(label)}
              </span>
              {
                required &&
                <span style={ { fontStyle:'italic'  } }>
                  &nbsp;[required]
                </span>
              }
            </Box>
          </Tooltip>
        ):(
          <Box sx={ { position: 'relative', left: 15, top: -20, width:'fit-content' } } ref={ textRef }>
            <span style={ { textTransform:'capitalize', whiteSpace:'nowrap' } }>{stylesHandleLabel(label)}</span>
          </Box>
        )}
    </Handle>
  );
};

export const DynamicNode2 = ({ 
  id, 
  data, 
  children, 
  className, 
  handleColor, 
  headerColor, 
  hideTitle, 
  hideBody, 
  icon ,
  inputHandleYPos, 
  outputHandleYPos,
  additionalMenu = [],
  validationError
}) => {

  const role = useUserRole();

  const { t: translate } = useTranslation();

  const { deleteNode, duplicateNode, toggleLockNode, openOverlayDialog } = useNodeActions();
  const [os, setOs] = React.useState('other');
  const shortcutKey = os === 'Mac' ? 'cmd' : 'ctrl';
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
    
  const { handles, version } = data;
  const handlesSpace = 50;
  const outputHandleTop = outputHandleYPos || '18px';
  const inputHandleTop = inputHandleYPos || '18px';

  // node locked indication
  const nodeRef = useRef(null);
  const [showIsLockedTooltip, setShowIsLockedTooltip] = useState(false);
  const [interactionWhileLockedCount, setInteractionWhileLockedCount] = useState(0);


  useEffect(() => {
    setOs(getOS());
  }, []);

  const handleDelete = useCallback(() => {
    deleteNode(id);
  }, [deleteNode, id]);

  const handleDuplicate = useCallback(() => {
    duplicateNode(id);
  }, [duplicateNode, id]);

  const handleToggleLock = useCallback(() => {
    toggleLockNode(id);
  }, [toggleLockNode, id]);

  const handleRename = useCallback(() => {
    openOverlayDialog('rename', id, data);
  }, [openOverlayDialog]);

  const menu = [
    {
      name: `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.DUPLICATE)}`,
      action: handleDuplicate,
      shortcut: `${shortcutKey}+d`,
    },
    {
      name: `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.DELETE)}`,
      action: handleDelete,
      shortcut: 'delete / backspace',
    },
    {
      name: data.isLocked ? 
        `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.UNLOCK)}` : 
        `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.LOCK)}`,
      action: handleToggleLock,
    },
    {
      name:'divider',
    },
    {
      name: `${translate(I18N_KEYS.RECIPE_MAIN.NODES.NODE_MENU.RENAME)}`,
      action: handleRename,
    },
    ...additionalMenu
  ];
  
  // sort handles by its order
  const sortedInputHandles = Object.entries(handles?.input || {}).sort(([, a], [, b]) => (a.order || 0) - (b.order || 0));

  const handleLockedInteraction = useCallback((e) => {
    if (data.isLocked && role !== "guest") {
      setInteractionWhileLockedCount(prev => {
        const newCount = prev + 1;
        if (newCount === 4) {
          setShowIsLockedTooltip(true);
          return 0; // Reset counter after showing tooltip
        }
        return newCount;
      });
    }
  }, [data.isLocked]);

  const handleTooltipClose = useCallback(() => {
    setShowIsLockedTooltip(false);
  }, []);
  
  useEffect(() => {
    if (!data.isLocked) {
      setShowIsLockedTooltip(false);
    }
  }, [data.isLocked]);

  // Add handlers for both click and mousedown (drag attempt)
  useEffect(() => {
    const nodeElement = nodeRef.current;
    if (nodeElement) {
      nodeElement.addEventListener('mousedown', handleLockedInteraction);
      
      return () => {
        nodeElement.removeEventListener('mousedown', handleLockedInteraction);
      };
    }
  }, [id, handleLockedInteraction]);

  return (
    <Tooltip 
      title={showIsLockedTooltip ? (
        <Box sx={ { display:'flex', flexDirection:'row', alignItems:'center' } }>
          {`${translate(I18N_KEYS.RECIPE_MAIN.NODES.LOCKED.THIS_NODE_IS_LOCKED)}`}&nbsp;
          <Link onClick={(e) => { e.stopPropagation(); handleToggleLock(); setShowIsLockedTooltip(false); }}>
            {`${translate(I18N_KEYS.RECIPE_MAIN.NODES.LOCKED.CLICK_TO_UNLOCK)}`}
          </Link>
        </Box>
      ) : ""}
      placement="top" 
      open={showIsLockedTooltip}
      onClose={handleTooltipClose}
      leaveDelay={500}
      slots={{
        transition: Fade,
      }}
    >
      <Box ref={nodeRef} className={ `custom-node two-input-output ${className}` } sx={ { cursor: role === "guest" ? 'default' : '' } }>
        {!hideTitle &&
                <Box className="node-header"
                  sx={ {
                    overflow:'hidden',
                    width:'100%',
                    background:headerColor || '',
                    flexDirection:'row',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between',
                  } }
                >
                  <Box sx={ { flexDirection:'row',
                    display:'flex',alignItems:'center' } }
                  >
                    {icon ? (
                      icon
                    ):(
                      <img src='/icons/dots.svg' width='8px' style={ { opacity:.8 } } />
                    )
                    }
                    <Typography variant='body1' sx={ { mx:1, whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis' } }>{data.name}</Typography>
              
                  </Box>
                  <Box>
                    {(role === "guest" || data.isLocked) && <i className="fa-solid fa-lock fa-sm" style={ { opacity:.6 } }></i>}
                    {role === "editor" && menu && menu.length > 0 && <>
                      <ButtonBase size='small' sx={ { px:1, py:1, mr:-1, my:-1 } } onClick={ (e)=>setAnchorEl(e.currentTarget) }>
                        <img src='/icons/ellipsis-vertical.svg' width='12px' style={ { opacity:.6 } } />
                      </ButtonBase>
                      <Menu
                        id="node-menu"
                        anchorEl={ anchorEl }
                        keepMounted
                        open={ open }
                        onClose={ ()=>setAnchorEl(null) }
                        anchorOrigin={ {
                          vertical: 'top',
                          horizontal: 'right',
                        } }
                        transformOrigin={ {
                          vertical: 'top',
                          horizontal: 'right',
                        } }
                      >
                        {menu.map((item, index)=> {
                          if (item.name === 'divider') {
                            return <Divider key={ index } />;
                          } else {
                            return (
                              <MenuItem
                                key={ index }
                                onClick={ ()=>{
                                  item.action();
                                  setAnchorEl(null);
                                } }
                                sx={ {
                                  justifyContent:'space-between',
                                  width:'240px',
                                } }
                                disabled={ item.disabled }
                              >
                                <Typography variant='caption'>{item.name}</Typography>
                                <Typography variant='caption' sx={ { fontStyle:'italic' } }>{item.shortcut}</Typography>
                              </MenuItem>
                            );}})}
                      </Menu>
                    </>
                    }
                  </Box>
                </Box>
      }
      {/* <Box><Typography>{id}</Typography></Box> */}
      {!hideBody && <Box className="node-content" sx={ { width:'100%' } }>
        {children}
      </Box>}
      { version === 2 ?
        sortedInputHandles.map(([key, inputHandle], index) => (
          <DynamicHandleLabel
            key={ index }
            handleType="target"
            handlePosition={ Position.Left }
            handleTop={ inputHandleTop }
            handleSpace={ handlesSpace }
            handleColor={ handleColor }
            handleId={ getHandleId(id, 'input', key) } //{`${id}-input-${key}`}
            label={ key }
            index={ index }
            required={ inputHandle.required }
            description={ inputHandle.description }
            format={ inputHandle.format }
            requiredValidationError={ validationError?.includes(key) }
          />
        ))        :
        handles?.input?.map((inputHandle, index) => (
          <DynamicHandleLabel
            key={ index }
            handleType="target"
            handlePosition={ Position.Left }
            handleTop={ inputHandleTop }
            handleSpace={ handlesSpace }
            handleColor={ handleColor }
            handleId={ getHandleId(id, 'input', inputHandle) } //{`${id}-input-${inputHandle}`}
            label={ inputHandle }
            index={ index }
          />
        ))
      }
      {handles?.output.map((outputHandle, index)=>(
        <DynamicHandleLabel
          key={ index }
          handleType="source"
          handlePosition={ Position.Right }
          handleTop={ outputHandleTop }
          handleSpace={ handlesSpace }
          handleColor={ handleColor }
          handleId={ getHandleId(id, 'output', outputHandle) } //{`${id}-output-${outputHandle}`}
          label={ outputHandle }
          index={ index }
        />
      ))}
            
      </Box>
    </Tooltip>
  );
};
