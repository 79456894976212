import React, { createContext, useContext, useState, useEffect } from 'react';
import { FlowMode } from './enums/flow-modes.enum';


const QueryParamsContext = createContext();

export const useQueryParamsContext = () => useContext(QueryParamsContext);

export const QueryParamsProvider = ({ children }) => {
  const [queryParamViewingMode, setQueryParamViewingMode] = useState();
  const [queryParamVersion, setQueryParamVersion] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setQueryParamViewingMode(params.get('view'));
    setQueryParamVersion(params.get('version'));
    setIsLoaded(true);
  }, []);

  if (!isLoaded) return null;
  return (
    <QueryParamsContext.Provider value={ { queryParamViewingMode, queryParamVersion } }>
      {children}
    </QueryParamsContext.Provider>
  );
};