import { Box, Typography, Skeleton } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import I18N_KEYS from "../language/keys";
import SettingsDrawer from '../components/Settings/SettingsDrawer';
import Profile from '../components/Settings/Profile';
import Team from '../components/Settings/Team';
import Billing from '../components/Settings/Billing';
import { SettingsPageSections } from '../enums/settings-page-sections.enum';
const drawerWidth = 240;

function Settings({ user }) {
  const { t: translate } = useTranslation();
  const [selectedSection, setSelectedSection] = useState(SettingsPageSections.PROFILE);
  const [isLoading, setIsLoading] = useState(false);

  const settingsList = [
    {
      menuLabel:translate(I18N_KEYS.SETTINGS.MENU.PROFILE),
      icon: <i className="fa-light fa-user"></i>,
      section:'profile',
      title:translate(I18N_KEYS.SETTINGS.SECTIONS_TITLES.PROFILE),
    },
    {
      menuLabel:translate(I18N_KEYS.SETTINGS.MENU.TEAM_WORKSPACE),
      icon: <i className="fa-light fa-users"></i>,
      section:'team',
      title:translate(I18N_KEYS.SETTINGS.SECTIONS_TITLES.TEAM_WORKSPACE),
    },
    // {
    //   menuLabel:translate(I18N_KEYS.SETTINGS.MENU.BILLING),
    //   icon: <i className="fa-light fa-credit-card"></i>,
    //   section:'billing',
    //   title:translate(I18N_KEYS.SETTINGS.SECTIONS_TITLES.BILLING),
    // },
  ];

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton variant="rectangular" height={ 200 } />; // TODO: Add a loading component
    }
    switch (selectedSection) {
      case SettingsPageSections.PROFILE:
        return <Profile user={ user } />;
      case SettingsPageSections.TEAM:
        return <Team currentUser={ user } />;
      case SettingsPageSections.BILLING:
        return <Billing user={ user } />;
      default:
        return null;
    }
  };

  return (
    <Box sx={ { display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', width:'100%', height:'100%' } }>
      <Box
        component="nav"
        sx={ { width: { sm: drawerWidth }, flexShrink: { sm: 0 } } }
        aria-label="dashboard navigation"
        id="settings-drawer-wrapper"
      >
        {/* TODO: Add mobile version */}
        <SettingsDrawer width={ drawerWidth } settingsList={ settingsList } selectedSection={ selectedSection } setSelectedSection={ setSelectedSection } />
      </Box>
      <Box id="settings-content-wrapper" sx={ { display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%', px:20, py:8 } }>
        {selectedSection && (
          <Box id="settings-content-top-menu-container" sx={ { display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', mt: 2 } }>
            <Typography variant="h2" sx={ { width:'inherit', textTransform:'capitalize' } }>{settingsList.find((item) => item.section === selectedSection).title}</Typography>
            {/* <Box id='dashboard-list-top-menu' sx={ { display: 'flex', justifyContent: 'flex-end', width: '100%', alignContent: 'center' } }>
              <Input placeholder="Search" sx={ { width:'200px', mr:2 } } value={ searchTerm } onChange={ handleSearchChange } />
              <IconButton size="small" onClick={ () => setIsListView(true) } sx={ { backgroundColor: isListView ? color.Super_Light_Transparent : '', p: 1 } }>
                <i className="fa-solid fa-list"></i>
              </IconButton>
              <IconButton size="small" onClick={ () => setIsListView(false) } sx={ { backgroundColor: !isListView ? color.Super_Light_Transparent : '', p: 1 } }>
                <i className="fa-solid fa-grip"></i>
              </IconButton>
            </Box> */}
          </Box>
        )}
        <Box id="settings-content-container" sx={ { display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%' } }>
          {renderContent()}
        </Box>
      </Box>
    </Box>
  );
}

export default Settings;
