import { Box, TextField, Typography, Divider, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DesignAppMode } from "../../../enums/design-app-modes.enum";
import { I18N_KEYS } from "../../../language/keys";

function DesignAppInputHeader({ inputId, updateNodeData, role, inputColor, nodes, setIsEditingInputMetadata, mode }) {

  const { t: translate } = useTranslation();

  const [editingInputId, setEditingInputId] = useState(null);
  const [editingDescription, setEditingDescription] = useState(false);

  useEffect(() => {
    if (editingInputId || editingDescription) {
      setIsEditingInputMetadata(true);
    }
    else {
      setIsEditingInputMetadata(false);
    }
  }, [editingInputId, editingDescription]);

  const handleInputNameChange = (newName) => {
    if(role === 'editor' && mode === DesignAppMode.Editing) {
      updateNodeData(inputId, { name: newName });
    }
  };
  const handleInputDescriptionChange = (newDescription) => {
    if(role === 'editor' && mode === DesignAppMode.Editing) {
      updateNodeData(inputId, { description: newDescription });
    }
  };

  const getInputDescription = () => {
    const nodeDescription = nodes.find((node) => node.id === inputId).data.description;
    if(role === 'editor' && mode === DesignAppMode.Editing && !nodeDescription){
      return translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.HEADER.ADD_DESCRIPTION);
    }
    
    return nodeDescription;
  };
  
  return (
    <Box 
      sx={ { 
        width: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        mb:1
      } }
    >
      <Divider sx={ { ml:-2 } } textAlign="left">
        <Box sx={ { display: 'flex', alignItems: 'center', gap: 1 } }>

          {role === 'editor' && mode === DesignAppMode.Editing &&
        <Box sx={ { width: '12px', display: 'flex', alignItems: 'center' } }>
          <img src='/icons/dots.svg' width='12px' style={ { opacity:.8, cursor:'grab' } } draggable={ false } />
        </Box>
          }
          {editingInputId === inputId ? (
            <TextField
              autoFocus
              fullWidth
              size="small"
              defaultValue={ nodes.find((node) => node.id === inputId).data.name }
              onFocus={ (e) => {
                e.target.select();
              } }
              onBlur={ (e) => {
                handleInputNameChange(e.target.value);
                setEditingInputId(null);
              } }
              onKeyDown={ (e) => {
                if (e.key === 'Enter') {
                  handleInputNameChange(e.target.value);
                  setEditingInputId(null);
                }
                if (e.key === 'Escape') {
                  setEditingInputId(null);
                }
              } }
              sx={ {
                '& .MuiInput-root': {
                  fontSize: '0.8125rem',
                  '&:before, &:after': {
                    borderBottom: `1px solid ${inputColor}`,
                  },
                },
              } }
              variant="standard"
            />
          ) : (
            <Chip
              size="small"
              label={ nodes.find((node) => node.id === inputId).data.name }
              sx={ {
                background: inputColor,
                cursor: role === 'editor' && mode === DesignAppMode.Editing ? 'text' : 'default',
                '&:hover': {
                  background: role === 'editor' && mode === DesignAppMode.Editing ? inputColor : 'transparent',
                },
              } }
              onClick={ () => role === 'editor' && mode === DesignAppMode.Editing && setEditingInputId(inputId) }
            />
          )}
        </Box>
      </Divider>
      <Box id="design-appinput-description" sx={ { mt:1 } }>
        {editingDescription ? (
          <TextField
            autoFocus
            multiline
            fullWidth
            size="small"
            placeholder={ translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.HEADER.DESCRIPTION_PLACEHOLDER) }
            defaultValue={ nodes.find((node) => node.id === inputId).data.description }
            onBlur={ (e) => {
              handleInputDescriptionChange(e.target.value);
              setEditingDescription(false);
            } }
            onKeyDown={ (e) => {
              if (e.key === 'Enter') {
                handleInputDescriptionChange(e.target.value);
                setEditingDescription(false);
              }
              if (e.key === 'Escape') {
                setEditingDescription(false);
              }
            } }
            variant="standard"
            sx={ { '& .MuiInput-root': { fontSize: '0.75rem' } } }
          />
        ) : (
          <Typography
            variant="caption"
            sx={ {
              cursor: role === 'editor' && mode === DesignAppMode.Editing ? 'text' : 'default',
              maxWidth: '100%',
            } }
            onClick={ () => role === 'editor' && mode === DesignAppMode.Editing && setEditingDescription(true) }
          >
            {getInputDescription()}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default DesignAppInputHeader;
