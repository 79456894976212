import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useUserRole } from "../../Recipe/UserRoleContext";
import { hasEditingPermissions } from "../Utils";
function StringCore ({ id, data, updateNodeData }) {

  const role = useUserRole();

  const [string, setString] = useState(data?.result?.string || {string: ""});

  const onStringChange = (e) => {
    setString(e.target.value);
  };

  useEffect(()=>{
    if(data.externalData){
      setString(data.externalData);
    }
  }, [data.externalData]);

  useEffect(()=>{
    updateNodeData(id, {
      result:{
        string,
      },
      output:{
        type:"text",
        string,
      },
    });
  },[string]);

  return (
    <TextField
      sx={ { mt:1 } }
      disabled={ !hasEditingPermissions(role, data) }
      label="String"
      value={ string }
      onChange={ onStringChange }
      multiline
      rows={ 3 }
      fullWidth
      size="small"
      onMouseDown={ (event) => event.stopPropagation() }
      inputProps={{
        style: { resize: "vertical" },
      }}
    />
  );
}

export default StringCore;