import { Box, Typography, Input, Checkbox, Tooltip, TextField, Link, styled } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from "react-i18next";
import { color } from '../../colors';
import I18N_KEYS from "../../language/keys";
import { NumberInputField } from "./input-fields/number-input-field/number-input-field.component";


const ExtraSmallFontTextField = styled(TextField)`
  & .MuiInputBase-root, & {
    font-size: 0.6rem;
  }
  
  & .MuiSelect-select {
    padding: 4px 6px;
  }
  
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }
`;

export const TextFieldWithCursor = ({ id, value, property, handleChange }) => {
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
    
  useEffect(() => {
    // If we have a stored cursor position and the input is focused
    if (cursorPosition !== null && inputRef.current === document.activeElement) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [value, cursorPosition]);
  
  const handleInputChange = (e) => {
    const newCursorPosition = e.target.selectionStart;
    setCursorPosition(newCursorPosition);
    handleChange(id, e.target.value);
  };
  
  return (
    <TextField
      sx={ { mt: 1 } }
      disabled={ property.exposed }
      fullWidth
      id={ id }
      multiline
      value={ value }
      rows={ 8 }
      onChange={ handleInputChange }
      size="small"
      inputRef={ inputRef }
      inputProps={ {
        style: { resize: "vertical" },
      } }
    />
  );
};
  
export default TextFieldWithCursor;
    
export const renderDynamicField = (params, key, property, handleChange, isHovered, handleExposeProperty, handleCollapseProperty, version ) => {
  let value = params[key]; // NaN value can happen if the schema[key] was exposed as a handle and no connection was made
  if(value === undefined || value === null)
  {
    value = property.default;
  }
  else {
    if(!Array.isArray(params[key]) && typeof params[key] === "object" && property.type !== "seed")
      value = params[key].value;
    else
      value = params[key];
  }
  switch (property.type) {
    case "integer":
    case "number":
      return (
        <NumberInputField
          key= { `${key}-numberInputField` }
          inputKey={ key }
          title={ property.title }
          description={ property.description }
          value={ value }
          disabled={ property.exposed }
          min={ property.min }
          max={ property.max }
          type={ property.type }
          onChange={ handleChange }
        />
      );
    case "input": // backward compatability 24.10 (adding separation between input of integer and floats)
      return (
        <Input
          key={ `${key}-textfield` }
          fullWidth
          // label={key}
          value={ value }
          size="small"
          inputProps={ { type: 'number' } }
          onChange={ (e) => handleChange(key, parseInt(e.target.value)) }
        />
      );
    case "input-integer":
      return (
        <Input
          key={ `${key}-input-integer` }
          fullWidth
          disabled={ property.exposed }
          value={ value }
          size="small"
          inputProps={ { type: 'number' } }
          onChange={ (e) => handleChange(key, parseInt(e.target.value)) }
        />
      );
    case "input-number":
      return (
        <Input
          key={ `${key}-input-float` }
          fullWidth
          disabled={ property.exposed }
          value={ value }
          size="small"
          inputProps={ { type: 'number', step: '0.1' } }
          onChange={ (e) => handleChange(key, parseFloat(e.target.value)) }
        />
      );
    case "enum":
      return (
        <FormControl fullWidth sx={ { mt:1 } }>
          {/* <InputLabel id={`${key}-label`}>{property.title}</InputLabel> */}
          <Select
            labelId={ `${key}-label` }
            id={ key }
            value={ value }
            disabled={ property.exposed }
            onChange={ (e) => handleChange(key, e.target.value) }
            size="small"
          >
            {property.options.map((option) => (
              <MenuItem key={ option } value={ option }>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
          
    case "boolean":
      return (
        <Box key={ `${key}-boolean` } sx={ { mb:2, display:'flex', flexDirection:'row', alignItems:'center', position:'relative' } }>
          <Box sx={ { display:'flex', flexDirection:'row', alignItems:'center', opacity: property.exposed ? 0.5 : 1 } } >
            <FormControl >
              <Checkbox disabled={ property.exposed } inputProps={ { 'aria-label': 'Checkbox' } } checked={ value } onChange={ (e) => handleChange(key, e.target.checked) } />
            </FormControl>
            <Typography variant="caption" className="property-title" sx={ { mr:.5 } }>{property.title}</Typography>
            <Tooltip title={ property.description } sx={ { fontSize:'10px' } }>
              <HelpOutlineIcon fontSize="10px" />
            </Tooltip>
          </Box>
          { version === 2 &&
          // <Box sx={ { position:'absolute', right:1,  } }>
              <ParamExposureComponent
                paramKey={ key }
                property={ property }
                isHovered={ isHovered }
                handleCollapseProperty={ handleCollapseProperty }
                handleExposeProperty={ handleExposeProperty }
              />
            // </Box>
          }
        </Box>
      );
    case "seed":
      return (
        <Box sx={ { display:'flex', flexDirection:'row', alignItems:'center' } }>
          <FormControl >
            <Checkbox
              inputProps={ { 'aria-label': 'Checkbox' } }
              disabled={ property.exposed }
              checked={ value?.isRandom }
              onChange={ (e) =>
                handleChange(
                  key,
                  {
                    isRandom: e.target.checked,
                    seed: value.seed,
                  },
                ) }
            />
          </FormControl>
          <Typography variant="caption" className="property-title" sx={ { mr:.5, opacity: property.exposed ? 0.5 : 1 } }>Random</Typography>
                                
          <Input
            value={ value?.seed }
            inputProps={ { type: 'number' } }
            size="small"
            onChange={ (e) => handleChange(key, { isRandom: value?.isRandom, seed: e.target.value }) }
            disabled={ value?.isRandom || property.exposed }
            sx={ { ml:1 } }
          />
        </Box>
      );
    case "array":
      return (
        <>
          <ExtraSmallFontTextField
            disabled={ property.exposed }
            sx={ { mt:1 } }
            fullWidth
            id={ key }
            multiline
            value={ Array.isArray(value) ? value.join(', ') : value || '' }
            rows={ 3 }
            onChange={ (e) => {
              // Just pass the raw text value
              handleChange(key, e.target.value);
            } }
            onBlur={ (e) => {
              // Only split into array when the field loses focus
              const newValue = e.target.value.split(',').map((item) => item.trim()).filter(Boolean);
              handleChange(key, newValue);
            } }
            size="small"
          />
          <Typography variant="caption" sx={ { opacity: property.exposed ? 0.5 : 1 } }>Use comma for multiple entries </Typography>
        </>
      );
    case "text":
      return (
        <TextFieldWithCursor
          id={ key }
          value={ value }
          property={ property }
          handleChange={ handleChange }
        />
      );
    default:
      return null;
  }
};

export const ParamExposureComponent = ({ paramKey, property, isHovered, handleCollapseProperty, handleExposeProperty }) => {
    
  const { t: translate } = useTranslation();

  const [hover, setHover] = useState(false);

  return (
    <Link
      onClick={ property.exposed ? () => handleCollapseProperty(paramKey) : () => handleExposeProperty(paramKey) }
      sx={ {
        position:'absolute',
        right:0,
        display:'flex',
        alignItems:'center',
        opacity: isHovered.key === paramKey && isHovered.value ? 1 : 0,
        textDecoration: 'none',
        backgroundColor:color.Dark_Blue,
        // pl:1,
        transition: 'opacity 0.15s ease-in-out, transform 0.3s ease-in-out',
        transform: hover ? 'translateX(0px)' : property.exposed ? 'translateX(85px)' : 'translateX(60px)',
      } }
      onMouseEnter={ () => setHover(true) }
      onMouseLeave={ () => setHover(false) }
    >
      <i className={ property.exposed ? "fa-light fa-arrow-right-from-arc fa-xs" : "fa-light fa-arrow-left-to-arc fa-xs" }></i>
      <Typography
        variant="caption"
        sx={ {
          fontSize:'12px',
          ml:.5,
          opacity: hover ? 1 : 0,
          transition: 'opacity 0.15s ease-in-out',
          whiteSpace: 'nowrap',
        } }
      >
        {property.exposed ? translate(I18N_KEYS.RECIPE_MAIN.FLOW.MODEL_PROPERTIES.SET_AS_PARAMETER) : translate(I18N_KEYS.RECIPE_MAIN.FLOW.MODEL_PROPERTIES.SET_AS_INPUT)}
      </Typography>
    </Link>
  );
};

