import { LoadingButton } from '@mui/lab';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from "@mui/material";
import { hasEditingPermissions } from "../Nodes/Utils";

const CancellableLoadingButton = ({
  run,
  onCancel,
  isProcessing,
  title,
  data,
  canCancel,
  disabled,
  shouldShowCreditsToMembers
}) => {
  const renderButton = () => {
    let endIcon;
    let subtitle = '';
    let localDisabled = disabled;
    if (isProcessing) {
      endIcon = <CircularProgress size="16px" sx={ { color: 'rgba(255, 255, 255, 0.3)' } } />;
      localDisabled = !canCancel;
    } else {
      endIcon = data.result && data.result.length > 0 ? <ReplayIcon /> : <PlayArrowIcon />;
      subtitle = data.paid && shouldShowCreditsToMembers && ` (${data.paid} Credits)`;
      // localDisabled = !hasEditingPermissions(role, data) || (data.paid && credits < data.paid);
    }

    //todo: replace loading button with regular one
    return (
      <LoadingButton
        size="small"
        onClick={ () => isProcessing ? onCancel() : run() }
        endIcon={ endIcon }
        startIcon={ isProcessing && canCancel ? <CancelIcon sx={ { fontSize: '14px !important' } } /> : null }
        variant="contained"
        color="weavy_cta"
        fullWidth
        disabled={ localDisabled }
        sx={ {
          height: '36px',
        } }
      >
        {title}{subtitle}
      </LoadingButton>
    );
  };

  return renderButton();
};

export default CancellableLoadingButton;
