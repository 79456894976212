import { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import  TempSignin  from '../components/Homepage/TempSignin';
import { AuthContext } from '../AuthContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_KEYS } from '../language/keys';
import axiosInstance from '../services/axiosConfig';
import { usePostHog } from 'posthog-js/react';

function TeamInviteActivationPage() {
    const posthog = usePostHog();
    const { t: translate } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const { signIn, currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isTokenValid, setIsTokenValid] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const token = params.get('token');
    const workspaceName = params.get('workspaceName');

    const validateToken = async () => {
        try {
            await axiosInstance.post(`/v1/workspaces/members/invitation/${token}/validate`);
            setIsTokenValid(true);
        } catch (error) {
            console.error(error);
            setIsTokenValid(false);
            posthog.capture('team_invite_activation_page_invalid_token');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            validateToken();
        }
    }, [token]);

    // activation URL is not valid -> redirect to home
    useEffect(() => {
        if(!email || !token || !workspaceName){
            posthog.capture('team_invite_activation_page_invalid_url');
           navigate('/');
        }
    },[email, token, workspaceName]);

    useEffect(() => {
        const acceptInvitation = async () => {
            try {
                setIsLoading(true);
                await axiosInstance.post(`/v1/workspaces/members/invitation/${token}/accept`);
                posthog.capture('team_invite_activation_page_accepted');
                navigate('/?acceptInvitation=true');
            } catch (error) {
                console.error(error);
                posthog.capture('team_invite_activation_page_error');
            } finally {
                setIsLoading(false);
            }
        }

        if(currentUser && !isLoading && isTokenValid){
            acceptInvitation();
        }
    },[currentUser, isLoading, isTokenValid]);

  return (
    <>
        <Box sx={ { display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', height:'100vh' } }>
            { isLoading &&
                <CircularProgress />
            }
            { isTokenValid  && !currentUser && !isLoading &&
                <>
                    <Typography variant="h5">{ translate(I18N_KEYS.SETTINGS.TEAM.ACTIVATION_PAGE.TITLE, { workspace_placeholder: workspaceName }) }</Typography>
                    <Typography variant="body1" sx={ { mt:2, mb:3 }}>{ translate(I18N_KEYS.SETTINGS.TEAM.ACTIVATION_PAGE.SUBTITLE, { email_placeholder: email }) }</Typography>
                    <TempSignin signIn={ signIn } fullScreen={ false } teamInviteToken={ token } hintEmail={ email }/> 
                </>
            }
            { !isTokenValid && !isLoading &&
                <>
                    <Typography variant="h6">{ translate(I18N_KEYS.SETTINGS.TEAM.ACTIVATION_PAGE.ERROR_TITLE) }</Typography>
                    <Typography variant="body2">{ translate(I18N_KEYS.SETTINGS.TEAM.ACTIVATION_PAGE.ERROR_SUBTITLE) }</Typography> 
                </>
            }
        </Box>
        
    </>
  );
}

export default TeamInviteActivationPage;
