import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Box,
  Button,
  CircularProgress,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Snackbar,
  Portal,
  Alert,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDomainWithoutSuffix } from 'tldts';
import axiosInstance from "../../../services/axiosConfig";
import { color } from '../../../colors';
import I18N_KEYS from '../../../language/keys';
import { AuthContext } from '../../../AuthContext';
import { RecipeVisibilityType } from '../../../enums/recipe-visibility-type';
import { AcceptedOrgNames } from '../../../consts/accpeted-org-names.consts';
import ShareModalUser from "./Share/ShareModalUser";
import EmailChipsInput from "./Share/EmailChipsInput";

const COUNTDOWN_VALUE_SECONDS = 2;

const MOCK_USERS = [
  {
    email: 'test@test.com',
    displayName: 'Test User',
    uid: '123',
  },
  {
    email: 'test2@test.com',
    displayName: 'Test User 2',
    uid: '124',
  },
  {
    email: 'test3@test.com',
    displayName: 'Test User 3',
    uid: '125',
  },
  {
    email: 'test@test.com',
    displayName: 'Test User',
    uid: '123',
  },
  {
    email: 'test2@test.com',
    displayName: 'Test User 2',
    uid: '124',
  },
  {
    email: 'test3@test.com',
    displayName: 'Test User 3',
    uid: '125',
  },
  {
    email: 'test@test.com',
    displayName: 'Test User',
    uid: '123',
  },
  {
    email: 'test2@test.com',
    displayName: 'Test User 2',
    uid: '124',
  },
  {
    email: 'test3@test.com',
    displayName: 'Test User 3',
    uid: '125',
  },
  {
    email: 'test3@test.com',
    displayName: 'Test User 3',
    uid: '125',
  },
  {
    email: 'test2@test.com',
    displayName: 'Test User 2',
    uid: '124',
  },
  {
    email: 'test3@test.com',
    displayName: 'Test User 3',
    uid: '125',
  },
  {
    email: 'test3@test.com',
    displayName: 'Test User 3',
    uid: '125',
  },
  {
    email: 'test2@test.com',
    displayName: 'Test User 2',
    uid: '124',
  },
  {
    email: 'test3@test.com',
    displayName: 'Test User 3',
    uid: '125',
  },
  {
    email: 'test3@test.com',
    displayName: 'Test User 3',
    uid: '125',
  },
];

const ShareWorkflowModal = ({ open, onClose, recipeId, posthog, visibility }) => {

  const { currentUser } = useContext(AuthContext);

  const [acceptedOrgName] = useState(AcceptedOrgNames[getDomainWithoutSuffix(currentUser.email)] || undefined);

  const [emails, setEmails] = useState([]);
  const [currentInput, setCurrentInput] = useState({ value: '', isValid: false });
  const [users, setUsers] = useState([]);
  const [isShareLoading, setIsShareLoading] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [error, setError] = useState('');
  const [countdown, setCountdown] = useState(COUNTDOWN_VALUE_SECONDS);

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    const getRecipeUsers = async () => {
      try{
        setIsLoadingUsers(true);
        const response = await axiosInstance.get(`/v1/recipes/${recipeId}/users`);
        setUsers(response.data.users);
      } catch (e) {
        console.error("Error getting recipe users:", e);
      } finally {
        setIsLoadingUsers(false);
      }
    };
    getRecipeUsers();
  }, []);

  // premissions menu
  const permissions = [
    {
      type: RecipeVisibilityType.Private,
      text: translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.LINK.RESTRICTED),
      icon: <i className="fa-solid fa-user-group"></i>,
    },
    {
        type: RecipeVisibilityType.Team,
        text: translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.LINK.TEAM),
        icon: <i className="fa-light fa-buildings"></i>
    },
    // {
    //     type: RecipeVisibilityType.Organization,
    //     text: translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.LINK.ORGANIZATION, { orgName: acceptedOrgName }),
    //     icon: <i className="fa-light fa-buildings"></i>
    // },
    {
      type: RecipeVisibilityType.Public,
      text: translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.LINK.ANYONE),
      icon: <i className="fa-light fa-globe"></i>,
    },
   
  ];
  const [permissionsAnchorEl, setPermissionsAnchorEl] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState(permissions.find((p)=> p.type === visibility) || permissions[0]);

  const handlePermissionsClick = (event) => {
    setPermissionsAnchorEl(event.currentTarget);
  };

  const handlePermissionsClose = async (index) => {
    if (typeof index === 'number') {
      setSelectedPermission(permissions[index]);
      const visibilityType = permissions[index].type;
      let updatedRecipe = {};
      if(visibilityType === RecipeVisibilityType.Public) {
        updatedRecipe = { public: true, visibility: visibilityType };
      } else if(visibilityType === RecipeVisibilityType.Team) {
        updatedRecipe = { visibility: visibilityType };
      } else if(visibilityType === RecipeVisibilityType.Organization) {
        updatedRecipe = { visibility: visibilityType };
      } else if(visibilityType === RecipeVisibilityType.Private) {
        updatedRecipe = { public: false, visibility: visibilityType };
      }
      try {
        await axiosInstance.put(`/v1/recipes/${recipeId}`, updatedRecipe);
      } catch (error) {
        console.error("error creating recipe: ", error);
      }
    }
    setPermissionsAnchorEl(null);
  };

  const handleClose = () => {
    setEmails([]);
    setCurrentInput({ value: '', isValid: false });
    setIsShareLoading(false);
    setIsLinkCopied(false);
    setError('');
    setCountdown(COUNTDOWN_VALUE_SECONDS);
    onClose();
  };

  useEffect(() => {
    let countdownInterval;
    if (isLinkCopied) {
      let countdownValue = COUNTDOWN_VALUE_SECONDS;
      countdownInterval = setInterval(() => {
        countdownValue -= 0.1;
        setCountdown(countdownValue.toFixed(1));
        if (countdownValue <= 0) {
          clearInterval(countdownInterval);
          handleClose();
        }
      }, 100);
    } else {
      setCountdown(COUNTDOWN_VALUE_SECONDS);
    }
    
    return () => clearInterval(countdownInterval);
  }, [isLinkCopied]);

  const handleShareSuccess = (users) => {
    setEmails([]);
    setUsers(users);
    setShowSuccessSnackbar(true);
    // getRecipeUsers
  };

  const handleShareSubmit = async () => {
    const emailsToShare = [...emails];
    if (currentInput.value.trim() && currentInput.isValid) {
      emailsToShare.push(currentInput.value.trim());
    }

    if (emailsToShare.length === 0) return;

    setIsShareLoading(true);
    posthog.capture('shared_workflow_by_email', { number_of_users: emailsToShare.length });

    try {
      const response = await axiosInstance.post(`/v1/recipes/${recipeId}/share`, { emails: emailsToShare });
      handleShareSuccess(response.data.users);
    } catch (e) {
      console.error("Error sharing file:", e);
      const errorMessage = error.response?.data?.error || translate(I18N_KEYS.SHARED_DESIGN_APP.MODAL.DEFAULT_ERROR);
      setError(errorMessage);
    } finally {
      setIsShareLoading(false);
    }
  };

  const handleInputChange = (value, isValid) => {
    setCurrentInput({ value, isValid });
  };

  const handleDeleteSharedUser = async (userId) => {
    setUsers(users.filter((user) => user.uid !== userId));
    await axiosInstance.delete(`/v1/recipes/${recipeId}/users/${userId}`);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsLinkCopied(true);
    setTimeout(() => setIsLinkCopied(false), 2000);
  };

  return (
    <>
      <Modal id="share-workflow-modal" open={ open } onClose={ handleClose } aria-labelledby="share-design-app-modal">
        <Box sx={ {
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          width: 480,
          minHeight: 430,
          maxHeight: 680,
          background:color.Dark_Blue,
          border:'1px solid',
          borderColor:color.Dark_Grey,
          boxShadow: 24, p:2, borderRadius: 2,
          display: 'flex', flexDirection: 'column',
        } }
        >
          <Typography variant="caption" sx={ { mb: 1, fontWeight: 'bold' } }>
            { translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.TITLE) }
          </Typography>
          <Box sx={ { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' , gap:1 } }>
            <EmailChipsInput emails={ emails } setEmails={ setEmails } onInputChange={ handleInputChange } />
            <Button
              variant="contained"
              size="small"
              color={ isLinkCopied ? "success" : "weavy_cta" }
              onClick={ handleShareSubmit }
              disabled={ emails.length === 0 && !currentInput.isValid }
              fullWidth
              endIcon={ isShareLoading ? <CircularProgress color="inherit" size={ 16 } /> : null }
              sx={ { position: 'relative', overflow: 'hidden', width:'140px', height:'40px' } }
            >
              <Typography variant="caption">
                { isShareLoading ?
                  translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.SHARE_BUTTON_LOADING) :
                  translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.SHARE_BUTTON)
                }
              </Typography>
            </Button>
          </Box>
          <Divider sx={ { my: 2 } } />
          <Box sx={ { display: 'flex', flexDirection: 'column', gap: 1, maxHeight: 400, overflowY:'auto', mx: -2 } }>
            <ShareModalUser user={ currentUser } currentUserId={ currentUser.uid } />
            { isLoadingUsers ? (
              <>
                {[...Array(3)].map((_, index) => (
                  <Box
                    key={ index }
                    sx={ {
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 1,
                      py: 1,
                      px: 2,
                      opacity: isLoadingUsers ? 1 : 0,
                      transition: 'opacity 0.3s ease-out',
                    } }
                  >
                    <Skeleton animation="wave" variant="circular" width={ 26 } height={ 26 } />
                    <Box sx={ { display: 'flex', flexDirection: 'column', gap: 0.5, flex: 1 } }>
                      <Skeleton animation="wave" variant="text" width="40%" height={ 15 } />
                      <Skeleton animation="wave" variant="text" width="60%" height={ 12 } />
                    </Box>
                  </Box>
                ))}
              </>
            ) : (
              users?.length > 0 ? users.map((user, index) => (
                <ShareModalUser key={ index } user={ user } currentUserId={ currentUser.uid } onDelete={ handleDeleteSharedUser } />
              )) : null
            ) }
          </Box>
          <Box sx={ { display: 'flex', mt:'auto', flexDirection: 'column', gap: 1 } }>
            <Divider sx={ { my: 2 } } />
            <Box sx={ { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' } }>
              <Button variant="contained" color="weavy_cta_secondary" size="small" onClick={ handleCopyLink } sx={ { width:'96px' } }>
                { isLinkCopied ?
                  (<>
                    <i className="fa-light fa-check"></i>&nbsp;{ translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.COPIED) }
                  </>) :
                  (<>
                    <i className="fa-sharp fa-regular fa-link-simple fa-rotate-by" style={ { transform: 'rotate(-45deg)' } }></i>&nbsp;{ translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.COPY_LINK) }
                  </>)
                }
              </Button>
              <Box>
                <Button
                  endIcon={ <ExpandMoreIcon /> }
                  onClick={ handlePermissionsClick }
                  sx={ {
                    color: color.Yambo_Text_On_Dark,
                    textTransform: 'none',
                    fontSize: '0.75rem',
                  } }
                >
                  {selectedPermission.text}
                </Button>
                <Menu
                  anchorEl={ permissionsAnchorEl }
                  open={ Boolean(permissionsAnchorEl) }
                  onClose={ handlePermissionsClose }
                >
                  {permissions
                    .filter((permission) => permission.type !== RecipeVisibilityType.Organization || acceptedOrgName)
                    .map((permission, index) => (
                      <MenuItem
                        key={ index }
                        onClick={ () => handlePermissionsClose(index) }
                        sx={ { fontSize: '0.8rem', p:2 } }
                      >
                        {permission.icon}&nbsp;&nbsp;{permission.text}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Portal>
        <Snackbar
          open={ showSuccessSnackbar }
          onClose={ () => setShowSuccessSnackbar(false) }
          autoHideDuration={ 3000 }
          anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
        >
          <Alert
            onClose={ () => setShowSuccessSnackbar(false) }
            severity="success"
            variant="filled"
            icon={ <i className="fa-light fa-paper-plane"></i> }
            sx={ { width: '100%', color:'white' } }
          >
            {translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.SUCCESS_MESSAGE)}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default ShareWorkflowModal;
