import React, { useState, useEffect } from "react";
import { Box, Link, Typography, Autocomplete, TextField, Slider, Input } from '@mui/material';
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import I18N_KEYS from '../../language/keys';
import { useUserRole } from "../Recipe/UserRoleContext";
import { color } from "../../colors";
import LoRA from "./MultiLoRA/LoRA";

class LoRAObj {
  id;
  url;
  name;
  coverImage;
  trigger;
}

const minWeight = 0.0;
const maxWeight = 1.0;
const stepWeight = 0.01;

function MultiLoRACore({ id, data, updateNodeData, container }) {

  const { t: translate } = useTranslation();
  const role = useUserRole();

  const [loras, setLoras] = useState(data.loras || [{
    id: uuidv4(),
    file: "",
    name: "Untitled",
    coverImage: "",
    trigger: "",
    defaultWeight: 0.5,
  }]);
  const [selectedLora, setSelectedLora] = useState(data.selectedLora || loras[0]);
  const [weight, setWeight] = useState(data.weight || 0.5);

  useEffect(() => {
    if (data.loras) {
      setLoras(data.loras);
    }
  }, [data.loras]);

  useEffect(() => {
    if(data.externalData) {
      setSelectedLora(data.externalData.selectedLora);
      setWeight(data.externalData.weight);
    }
  }, [data.externalData]);

  useEffect(() => {
    if (selectedLora) {
      const updatedSelectedLora = loras.find((l) => l.id === selectedLora.id);
      if (updatedSelectedLora && JSON.stringify(updatedSelectedLora) !== JSON.stringify(selectedLora)) {
        setSelectedLora(updatedSelectedLora);
      }
    }
  }, [loras]);

  const updateLora = (lora) => {
    const updatedLoras = loras.map((l) => l.id === lora.id ? lora : l);
    setLoras(updatedLoras);
    // Add this to ensure the node data is updated immediately
    updateNodeData(id, {
      loras: updatedLoras,
    });
  };

  useEffect(() => {
    updateNodeData(id, {
      weight: weight,
      selectedLora: selectedLora,
      minWeight: minWeight,
      maxWeight: maxWeight,
      stepWeight: stepWeight,
      output: {
        type: "lora",
        [data.handles.output[0]]: selectedLora ? `weavy-lora::${selectedLora.file}` : "",
        [data.handles.output[1]]: weight,
      },
    });
  }, [selectedLora, weight]);

  const handleItemAdd = () => {
    setLoras([...loras, {
      id: uuidv4(),
      file: "",
      name: "Untitled",
      coverImage: "",
      trigger: "",
      defaultWeight: 0.5,
    }]);
  };
   
  // lora weights:
  const handleWeightChange = (newValue) => {
    let parsedValue = parseFloat(newValue);
        
    // If the parsedValue is NaN (which can happen if the input is cleared), reset it to a default value or empty string
    if (isNaN(parsedValue)) {
      parsedValue = undefined;
    }
    parsedValue = Math.min(Math.max(parsedValue, minWeight), maxWeight);
    setWeight(parsedValue);
  };
  const deleteLora = (id) => {
    setLoras(loras.filter((l) => l.id !== id));
  };

  return (
    <>
      {container === "node" &&
        <Box sx={ { width: '100%' } }>
          <Box>
            <Autocomplete
              id="lora-select"
              sx={ { width: '100%', mt:1 } }
              options={ loras.filter((l) => l.file) }
              autoHighlight
              value={ selectedLora }
              onChange={ (event, newValue) => {
                setSelectedLora(newValue);
              } }
              getOptionLabel={ (option) => option.name }
              getOptionKey={ (option) => option.id }
              isOptionEqualToValue={ (option, value) => option.id === value.id }
              renderOption={ (props, option) => {
                const { id: propsId, ...optionProps } = props;
                
                return (
                  <Box
                    key={ option.id }
                    component="li"
                    { ...optionProps }
                    sx={ {
                      width: '100%',
                      backgroundColor: color.Yambo_Blue,
                      '&:hover': {
                        backgroundColor: color.Yambo_Blue_Stroke,
                      },
                    } }
                  >
                    <LoRA lora={ option } container="node" />
                  </Box>
                );
              } }
              renderInput={ (params) => (
                <TextField
                  { ...params }
                  label={ translate(I18N_KEYS.RECIPE_MAIN.NODES.MULTI_LORA.SELECT_LORA) }
                  inputProps={ {
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  } }
                />
              ) }
            />
            <Box sx={ { display:'flex', flexDirection:'row', alignItems:'center' ,mt:1 } }>
              <Typography variant="caption" fontWeight="bold">
                  Weight
              </Typography>
              <Slider
                size="small"
                value={ weight }
                onChange={ (event, newValue) => handleWeightChange(newValue) }
                aria-labelledby="weight-slider"
                max={ maxWeight }
                min={ minWeight }
                sx={ { ml:2 } }
                step={ stepWeight }
                valueLabelDisplay="auto"
              />
              <Input
                value={ weight }
                size="small"

                onChange={ (e) => handleWeightChange(e.target.value) }
                inputProps={ {
                  step: stepWeight,
                  min: minWeight,
                  max: maxWeight,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                  style: {
                    width: '50px',
                    fontSize: '10px',
                  },
                } }
                sx={ { ml:2 } }
              />
            </Box>
          </Box>
        </Box>}
      {container === "drawer" && <Box sx={ { width: '100%' } }>
        <Typography variant="caption" className="property-title" sx={ { my:1, display:'block' } }>{translate(I18N_KEYS.RECIPE_MAIN.NODES.MULTI_LORA.LORAS)}</Typography>
        {loras.length > 0 && loras.map((lora) => (
          <LoRA key={ lora.id } lora={ lora } updateLora={ updateLora } deleteLora={ deleteLora } setSelectedLora={ setSelectedLora } container="drawer" />
        ))}

        {role !== "guest" &&
        <Box sx={ { mt: 1 } }>
          <Link
            onClick={ handleItemAdd }
            sx={ {
              pointerEvents: role === "guest" || data.isLocked ? 'none' : '',
              fontSize: '12px',
            } }
          >{translate(I18N_KEYS.RECIPE_MAIN.NODES.MULTI_LORA.ADD_LORA)}</Link>
        </Box>}
        
      </Box>}
    </>
  );
}
    
export default MultiLoRACore;
