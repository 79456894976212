import React, { useState, useEffect, useCallback } from 'react';
import { Chip, Paper, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { SmallFontTextField } from '../../../Nodes/Utils';
import I18N_KEYS from '../../../../language/keys';

// Custom styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  padding: '8px',
  alignItems: 'center',
  '& .MuiTextField-root': {
    flex: 1,
    minWidth: 200,
  },
  '& .MuiInputBase-root': {
    '& textarea': {
      height: '20px !important',
      overflow: 'hidden',
      marginTop: '6px',
    },
  },
}));

function EmailChipsInput({ emails, setEmails, onInputChange }) {
  const { t: translate } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [selectedEmail, setSelectedEmail] = useState(null);

  // Email validation regex
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setError('');
    setSelectedEmail(null);
    
    // Notify parent of input changes and validity
    if (onInputChange) {
      onInputChange(value, value.trim() ? emailRegex.test(value.trim()) : false);
    }
  };

  const validateAndAddEmail = (email) => {
    const trimmedEmail = email.trim();
    
    if (!trimmedEmail) return;

    if (!emailRegex.test(trimmedEmail)) {
      setError(translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.INVALID_EMAIL));
      return;
    }

    if (emails.includes(trimmedEmail)) {
      setError(`${trimmedEmail} has already been added`);
      return;
    }

    setEmails([...emails, trimmedEmail]);
    setInputValue('');
    setError('');
    setSelectedEmail(null);
    
    // Notify parent of empty input after adding email
    if (onInputChange) {
      onInputChange('', false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      validateAndAddEmail(inputValue);
    }

    // Handle backspace when input is empty
    if (e.key === 'Backspace' && !inputValue && emails.length > 0) {
      e.preventDefault();
      const lastEmail = emails[emails.length - 1];
      
      if (selectedEmail === lastEmail) {
        // Second backspace - delete the email
        setEmails((prevEmails) => prevEmails.filter((email) => email !== lastEmail));
        setSelectedEmail(null);
      } else {
        // First backspace - select the email
        setSelectedEmail(lastEmail);
      }
    }
  };

  const handleDoubleClick = (email) => {
    if (inputValue.trim()) {
      return;
    }
    setInputValue(email);
    setEmails((prevEmails) => prevEmails.filter((e) => e !== email));
    setSelectedEmail(null);
    
    // Notify parent of input change
    if (onInputChange) {
      onInputChange(email, emailRegex.test(email));
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const pastedEmails = pastedText.split(/[,\s]+/);
    
    pastedEmails.forEach((email) => {
      validateAndAddEmail(email);
    });
    setSelectedEmail(null);
  };

  const handleDelete = useCallback((emailToDelete) => {
    setEmails((prevEmails) => prevEmails.filter((email) => email !== emailToDelete));
    setSelectedEmail(null);
  }, []);

  // Clear selection when clicking outside
  const handleContainerClick = () => {
    setSelectedEmail(null);
  };

  return (
    <Box sx={ { width: '100%' } } onClick={ handleContainerClick }>
      <StyledPaper elevation={ 0 } variant="outlined">
        {emails.map((email) => (
          <Chip
            key={ email }
            label={ email }
            onDelete={ () => handleDelete(email) }
            onDoubleClick={ () => handleDoubleClick(email) }
            color={ selectedEmail === email ? "error" : "weavy_cta_secondary" }
            variant="outlined"
            size="small"
            sx={ {
              transition: 'all 0.3s ease',
              ...(selectedEmail === email && {
                backgroundColor: 'error.light',
                color: 'error.contrastText',
                '& .MuiChip-deleteIcon': {
                  color: 'error.contrastText',
                },
              }),
            } }
          />
        ))}
        <SmallFontTextField
          fullWidth
          value={ inputValue }
          onChange={ handleInputChange }
          onKeyDown={ handleKeyDown }
          onPaste={ handlePaste }
          placeholder={ emails.length === 0 ? translate(I18N_KEYS.SHARE_WORKFLOW_MODAL.EMAIL_PLACEHOLDER) : "" }
          variant="standard"
          size="small"
          error={ !!error }
          autoComplete="off"
          inputProps={ {
            type: 'email',
          } }
          InputProps={ {
            disableUnderline: true,
          } }
        />
      </StyledPaper>
      {error && (
        <Typography color="error" variant="caption" sx={ { mt: 1 } }>
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default EmailChipsInput;
