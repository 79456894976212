import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, FormControlLabel, Checkbox, Link, Typography } from '@mui/material';
import { useUpdateNodeInternals } from 'reactflow';
import { colorMap } from '../../colors';
import { useUserRole } from '../Recipe/UserRoleContext';
import { DynamicNode2 } from './VisualNodes';
import CropOverlay from './Crop/CropOverlay';
import { Crop } from "./Cloudinary";
import { getFileDimensions, hasEditingPermissions } from "./Utils";

function validateProperties(obj) {
  // List of properties to check
  const properties = ['height', 'width', 'x', 'y'];

  // Check each property in the list
  for (let prop of properties) {
    const value = obj[prop];
    
    // Check if the value is NaN, null, or undefined
    if (value === null || value === undefined || Number.isNaN(value)) {
      return false; // Return false if any property does not pass the check
    }
  }

  return true; // Return true if all properties pass the check
}


function CropCloudinaryNode ({ id, data, updateNodeData }) {

  const role = useUserRole();

  const updateNodeInternals = useUpdateNodeInternals();

  const [isNewNode, setIsNewNode] = useState(data.crop_data? false: true);

  const videoElRef = useRef(null);
  const [videoReady, setVideoReady] = useState(false);

  /// input and output files 
  const [fileSrc, setFileSrc] = useState();
  const [fileSize, setFileSize] = useState();
  const [fileType, setFileType] = useState();
  const [fileCrp, setFileCrp] = useState(null);


  // crop tooltip 
  const [, setCropTooltipPosition] = useState({ top: 0, left: 0 });
  const [, setShowCropSize] = useState(false);

  /// actual crop dimensions
  const [cropBox, setCropBox] = useState();
  const [cropDims, setCropDims] = useState();
  const [cropContainerDims, setCropContainerDims] = useState({ width: 0, height: 0 });
  const [scaleFactor, setScaleFactor] = useState(data.crop_data?.scaleFactor);
  const cropContainerRef = useRef(null);
  const [lockAspectRatio, setLockAspectRatio] = useState(data.lockAspectRatio ?? true);

  //// helpers
  const [shouldReset, setShouldReset] = useState(false);
  

  useEffect(()=>{
    if(data.crop_data)
      setCropBox(data.crop_data);
  },[]);

  const exportCorppedFile = useCallback(()=>{

    if(!fileCrp) return;

    const outputObj = {};
    let fileCrpObject = {};

    // console.log(cropBox.width, scaleFactor, cropBox.height );
    outputObj[data.handles.output[0]] =
        {
          url: fileCrp,
          type: fileType,
          width: Math.ceil(cropBox.width *scaleFactor),
          height:Math.ceil(cropBox.height *scaleFactor),
          publicId: fileSrc.publicId,
          transformations: fileSrc.transformations? fileSrc.transformations :[],
        };


    if(cropBox){
      fileCrpObject = cropBox;
    }

    if(outputObj[data.handles.output[0]].transformations) {
      const previousTransformations = outputObj[data.handles.output[0]].transformations;
      const newTransformation =  {
        nodeIdentifier:id,
        resize:{
          type:'crop',
          x_pos:Math.ceil(fileCrpObject.x * scaleFactor),
          y_pos:Math.ceil(fileCrpObject.y * scaleFactor),
          width:Math.ceil(fileCrpObject.width *scaleFactor),
          height:Math.ceil(fileCrpObject.height *scaleFactor),
        },
      };
      const index = previousTransformations.findIndex((t) => t.nodeIdentifier === id);
      if (index !== -1) {
        // If found, update the existing transformation
        previousTransformations[index] = newTransformation;
      } else {
        // Otherwise, add the new transformation
        previousTransformations.push(newTransformation);
      }
      outputObj[data.handles.output[0]].transformations = previousTransformations;
    }



    updateNodeData(id, {
      crop_data: fileCrpObject,
      lockAspectRatio: lockAspectRatio,
      result:fileCrp || '',
      output:outputObj,
    });


  }, [fileCrp, fileSrc, fileType, cropBox, lockAspectRatio]);

  useEffect(()=>{
    exportCorppedFile();
  },[fileCrp]);

  /// LOAD IMAGE on connect or disconnect
  useEffect(()=>{
    if(data.input && data.input[data.handles.input[0]]){
      // edge connected
      const src = data.input[data.handles.input[0]];
      if (src?.url?.includes("data:image/png;base64")) { // prevent crash
        return;
      }
      setFileSrc(src);
      setFileType(src.type);
    }
    else {
      setFileSrc();
      setFileCrp(null);
      setCropBox(null);
      setIsNewNode(true);
      updateNodeData(id, {
        crop_data: {},
        lockAspectRatio: null,
        result:{},
        output:{
          [data.handles.output[0]]:null,
        },
      });
    }
  },[data.input]);


  /// setting cropoverlay size
  useEffect(() => {
    const container = cropContainerRef?.current;
    if (!container) return;
    // Initialize dimensions once
    const updateDimensions = () => {
      const dim = { width: container.offsetWidth, height: container.offsetHeight };
      setCropContainerDims(dim);
      if(isNewNode)
        setCropBox({
          width:container.offsetWidth,
          height:container.offsetWidth,
          x: 0,
          y:0,
          scaleFactor: 1,
        });
      updateNodeInternals(id);
    };
    updateDimensions();
    const resizeObserver = new ResizeObserver((entries) => {
      for(let i = 0; i< entries.length; i++){ // todo: @jonathan g.z. needed?
        updateDimensions();
      }
    });

    // Start observing the node
    resizeObserver.observe(container);
    
    return () => resizeObserver.unobserve(container);
    
  }, [id, fileSrc]);

  /// get file dimensions on fileSrc change
  useEffect(() => {
    const getFileDimensionFunc = async (url, type) => {
      const res = await getFileDimensions(url, type);
      setFileSize(res);
    };
    if(fileSrc?.url)
      getFileDimensionFunc(fileSrc.url, fileSrc.type);

  },[fileSrc]);

  ////  calulate the scale factor between the actual image size  and the html 
  useEffect(()=>{
    if(fileSrc && fileSize && cropContainerDims.width )
      setScaleFactor(fileSize.width / cropContainerDims.width);
  },[fileSrc, fileSize, cropContainerDims]);

  useEffect(() => {
    if (cropBox && !cropBox.scaleFactor && scaleFactor){
      const innerCropBox = {
        ...cropBox,
        scaleFactor,
      };
      updateNodeData(id, { crop_data: innerCropBox });
      setCropBox(innerCropBox);
    }
  }, [scaleFactor]);

  //// acutal cropping function
  useEffect(() => {
    if (cropBox && validateProperties(cropBox) && fileSrc && scaleFactor) {
      if(fileSrc.type === 'video' && !videoReady)
        return;
      let crp;
      crp = Crop(fileSrc, scaleFactor, cropBox, id);
      console.log(crp);
      if(crp){
        setFileCrp(crp);
      }
    }
  },[cropBox, fileSrc, scaleFactor, fileSrc?.publicId, videoReady]);

  // useEffect(()=>{
  //   console.log("CropBox: ", cropDims);
  // },[cropBox]);

  /// runs when the user releases the crop box which triggers the acutal cropping function
  const handleCropEnd = (cropDimensions) => {
    setShowCropSize(false);
    setCropTooltipPosition({ top:0, left:0 });
    setCropBox({
      width:cropDimensions.width,
      height:cropDimensions.height,
      x: cropDimensions.x,
      y:cropDimensions.y,
      scaleFactor,
    });
  };


  /// handles the small crop tooltip - Currently marked out.
  const handleShowSize = () => {
    
    // const cropCurrentData = cropperRef.current?.cropper.getData([true]);
    // setCropSize({ width: cropCurrentData.width, height: cropCurrentData.height});
    
    // const handleUsed = e.detail.action;
    // if(handleUsed !== 'all')
    // setShowCropSize(true);
    
    // const cropBoxDetails = e.currentTarget.cropper.cropBoxData;
    // switch(handleUsed) {
    //     case 'se':
    //         setCropTooltipPosition({ top: cropBoxDetails.top + cropBoxDetails.height + 20, left: cropBoxDetails.left+cropBoxDetails.width + 20});
    //         break;
    //     case 's':
    //         setCropTooltipPosition({ top: cropBoxDetails.top + cropBoxDetails.height + 20, left: (cropBoxDetails.left+cropBoxDetails.width)/2 });
    //         break;
    //     case 'sw':
    //         setCropTooltipPosition({ top: cropBoxDetails.top + cropBoxDetails.height + 20, left: cropBoxDetails.left-20 });
    //         break;
    //     case 'w':
    //         setCropTooltipPosition({ top: (cropBoxDetails.top + cropBoxDetails.height)/2, left: cropBoxDetails.left-40 });
    //         break;
    //     case 'nw':
    //         setCropTooltipPosition({ top: cropBoxDetails.top -20 , left: cropBoxDetails.left-40 });
    //         break;
    //     case 'n':
    //         setCropTooltipPosition({ top: cropBoxDetails.top -20 , left: (cropBoxDetails.left+cropBoxDetails.width)/2 });
    //         break;
    //     case 'ne':
    //         setCropTooltipPosition({ top: cropBoxDetails.top -20 , left: (cropBoxDetails.left+cropBoxDetails.width)+20 });
    //         break;
    //     case 'e':
    //         setCropTooltipPosition({ top: (cropBoxDetails.top + cropBoxDetails.height)/2 , left: (cropBoxDetails.left+cropBoxDetails.width)+20 });
    //         break;
    // }
  };

  const handleResetClick = useCallback(() => {
    setShouldReset(true);
  },[]);


  
  return (
    <DynamicNode2 id={ id } data={ data } className="crop" handleColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      {fileSrc && <>
        <Box id="crop-node-header"
          sx={ {
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            mb:1,
            pointerEvents: role === "guest" || data.isLocked ? "none":"",
          } }
        >
          <Box sx={ { display:'flex', flexDirection:'column' } }>
            <Typography variant='caption'>
            x: <b>{parseInt(Math.ceil((cropDims?.x ?? 0) * (scaleFactor ?? 1))).toString()}</b>
            &nbsp;&nbsp;y: <b>{parseInt(Math.ceil((cropDims?.y ?? 0) * (scaleFactor ?? 1))).toString()}</b>
            &nbsp;&nbsp;w: <b>{parseInt(Math.ceil((cropDims?.width ?? 0) * (scaleFactor ?? 1))).toString()}</b>
            &nbsp;&nbsp;h: <b>{parseInt(Math.ceil((cropDims?.height ?? 0) * (scaleFactor ?? 1))).toString()}</b>
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ lockAspectRatio }
                  onChange={ (e) => setLockAspectRatio(e.target.checked) }
                />
              }
              label="Lock Aspect Ratio"
              sx={ {
                '& .MuiFormControlLabel-label': {
                  fontSize: '12px',
                },
              } }
            />
            <Link  onClick={ handleResetClick }>reset</Link>
          </Box>
        </Box>
        <Box  className="nowheel nodrag nopan"  sx={ { pointerEvents: !hasEditingPermissions(role, data) ? "none":"" } }>
            
          <>
            <Box ref={ cropContainerRef } id={ `crop-media-wrapper-${id}` } sx={ { position:'relative', width:'100%', height:'100%' } } >
              {fileType === 'image' ?
                (
                  <img src={ fileSrc.url } style={ { width:"100%", display:'block' } } />
                ):(
                  <video crossOrigin="anonymous" src={ fileSrc.url } style={ { width:'100%' } }
                    ref={ videoElRef }
                    onCanPlay={ ()=>{setVideoReady(true);} }
                    controls
                  />
                )}
               
              <CropOverlay
                lockAspectRatio={ lockAspectRatio }
                isNew={ isNewNode }
                cropBox={ cropBox }
                cropDimensions={ cropDims }
                setCropDimensions={ setCropDims }
                container={ cropContainerDims }
                onCropEnd={ handleCropEnd }
                onCropMove={ handleShowSize }
                shouldReset={ shouldReset }
                setShouldReset={ setShouldReset }
              />
               
            </Box>
            {/* small widget to show crop size */}
            {/* {showCropSize && <Box sx={{ 
                    position: 'absolute',
                    top: `${cropTooltipPosition.top}px`,
                    left: `${cropTooltipPosition.left}px`,
                    zIndex: 'tooltip',
                    display:'flex',
                    flexDirection:'column',
                    background:'#000000aa',
                    py:.5,
                    px:1,
                    borderRadius:'4px'
                    }}>
                 <Typography sx={{fontSize:'8px'}} >w: {cropSize.width}px</Typography>
                 <Typography sx={{fontSize:'8px'}}>h: {cropSize.height}px</Typography>
                </Box>} */}
                
          </>
                
        </Box>
      </>}
        
    </DynamicNode2>
  );
}


export default CropCloudinaryNode;
