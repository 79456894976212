import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useDropzone } from "react-dropzone";
import { useTranslation } from 'react-i18next';
import { color } from '../../../colors';
import axiosInstance from "../../../services/axiosConfig";
import I18N_KEYS from '../../../language/keys';
import { DesignAppMode } from "../../../enums/design-app-modes.enum";

function DesignAppUploadFile({ id, value, onUpload, isLoading, updateNodeData, setIsUploading, role, mode, error, helperText }) {

  const { t: translate } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewImage, setPreviewImage] = useState(value?.file?.thumbnailUrl || null);
  const [uploadedFileType, setUploadedFileType] = useState(value?.file?.type || null);
  const [hasError, setHasError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const uploadSuccess = (uploadData) => {
    const newUploadedFile = {
      url: uploadData.url,
      thumbnailUrl: uploadData.thumbnailUrl,
      viewUrl: uploadData.viewUrl,
      type: uploadData.type,
      width: uploadData.width,
      height: uploadData.height,
      publicId: uploadData.publicId,
      visualId: uploadData.visualId,
    };
    if(role === 'editor' && mode === DesignAppMode.Editing) {
      updateNodeData(id, {
        externalData: newUploadedFile,
      });
    }
    setUploadProgress(100);
    onUpload(id, newUploadedFile);
  };

  useEffect(()=>{
    switch (value?.type) {
      case 'video':
        setPreviewImage(value?.thumbnailUrl);
        setUploadedFileType('image');
        break;
      case 'image':
        setPreviewImage(value?.thumbnailUrl);
        setUploadedFileType('image');
        break;
      case 'audio':
        setPreviewImage("/audio.png");
        setUploadedFileType('audio');
        break;
      case '3D':
        setPreviewImage(value?.thumbnailUrl);
        setUploadedFileType('3D');
        break;
      default:
        break;
    }
    
  },[value?.thumbnailUrl]);



  const onDrop = async (acceptedFiles,rejectedFiles,event) => {
    if(event){
      event.stopPropagation();
    }
    setHasError(false);
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileName = file.name.toLowerCase();
      
      if (file.type.startsWith('video')) {
        setUploadedFileType('video');
      } else if (file.type.startsWith('image')) {
        setUploadedFileType('image');
      } else if (file.type.startsWith('audio')) {
        setUploadedFileType('audio');
      } else if (fileName.endsWith('.glb')) {
        setUploadedFileType('3D');
      }
      if(file.type.startsWith('audio')) {
        setPreviewImage("/audio.png");
      } else {
        setPreviewImage(URL.createObjectURL(file)); // Create a temporary URL for the file
      }
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("media_metadata", true);

      
      try {
        setIsUploading(true);
        setUploadProgress(0); // Initialize the progress bar
        const response = await axiosInstance.post(`/v1/nodes/upload`, formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 95,
            );
            setUploadProgress(progress);
          },
        });
        
        uploadSuccess(response.data);
        // console.log("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
        setHasError(true);
      } finally {
        setUploadProgress(0); // Reset the progress bar
        setIsUploading(false);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png','.webp','.webm', '.mp4', '.mov'],
      'audio/*': ['.mp3', '.wav', '.ogg'],
      'model/gltf-binary': ['.glb'],
    },
    multiple: false, // Allow only one file to be dropped/uploaded
    noDragEventsBubbling: true,
  });

  const openFileUploader = () => {
    // Trigger the input file element's click event
    const inputElement = document.getElementById(`design-app-file-upload-input-${id}`);
    inputElement.click();
  };

  
  function renderMediaElement(url, type) {
    switch (type) {
      case 'video':
        return <video draggable='false' crossOrigin="anonymous" src={ url } width='100%' style={ { display:'block' } } />;
      case 'audio':
      case '3D':
      case 'image':
        return <img
          draggable='false'
          src={ url }
          alt="Media"
          style={ {
            display: 'block',
            width: '100%',
            height: 'auto',
            maxHeight: '300px',
            minHeight: '180px',
            objectFit: 'contain',
          } }
        />;
      // Add more cases for other types
      default:
        return <Typography>No preview</Typography>;
    }
  }

  return (
    <Box 
    { ...getRootProps() }
      id={ `design-app-upload-file-container-${id}` }

      sx={ { 
        width:'100%', 
        border:`1px solid ${ error ? color.Yambo_Orange : color.Yambo_Text_On_Dark_Transparent}`,
        borderRadius:1,
        position:'relative',
        transition: 'all 0.1s ease',
        // aspectRatio: '3/2',
      } }>
      <Box sx={ { width:'100%', height:'100%' } } onMouseEnter={ () => setIsHovered(true) } onMouseLeave={ () => setIsHovered(false) }>

        {(value?.file?.url || previewImage) && !hasError && !isLoading ? (
          <>
            <Box sx={ { position:'relative' } }>
              <Box
                className='media-container'
                sx={ { width: "100%", height: "100%" } }
                onClick={ openFileUploader }
              
              >
                {renderMediaElement(previewImage, uploadedFileType)}
                
                {value && value.width && value.height &&
                    <Typography
                      variant="caption"
                      sx={ { position:'absolute', top:5, left:5, textShadow: '0 0 5px rgba(0, 0, 0, 1)' } }
                    >
                      {value.width} x {value.height}
                    </Typography>
                }
              </Box>
              {uploadProgress > 0 && uploadProgress < 100  &&
                <Box sx={ { width:'100%', position:'absolute', top:0 } } id='design-app-upload-progress-container'>
                  <LinearProgress variant="determinate" value={ uploadProgress } />
                </Box>
              }
              {isHovered &&
                  <Box
                    sx={ { position:'absolute', top:0, left:0, width:'100%', height:'100%', backgroundColor:'rgba(0, 0, 0, 0.3)', zIndex:1000 } }
                    onClick={ (e) => {
                      e.stopPropagation();
                      openFileUploader();
                    } }
                  >
                    <Box sx={ { display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', height:'100%', cursor:'pointer' } }>
                      <i className="fa-light fa-photo-film-music" style={ { fontSize:'24px' } }></i>
                      <Typography variant="caption" fontWeight="bold" color={ color.Yambo_Text_On_Dark } >
                        {translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.IMPORT.UPLOAD_ANOTHER)}
                      </Typography>
                    </Box>
                  </Box>
              }
            </Box>
          </>
        ) : (
          <Box
            id="design-app-upload-container"
            sx={ {
              textAlign:'center',
              height:'180px',
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'center',
              p: !isLoading ? 2 : 0,
            } }
          >
            {isLoading ?
              <Box sx={ { position:'relative', width:'100%', height:'100%' } }>
                <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />
                <i className="fa-light fa-photo-film-music" style={ { fontSize:'24px', color:color.Yambo_Text_On_Dark_Transparent, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)' } }></i>
              </Box>
              : 
              <Box
                
                sx={ {
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  border:  !isLoading ? '1px dashed' : 'none',
                  transition: 'all 0.1s ease',
                  borderColor: error ? color.Yambo_Orange : color.Yambo_Text_On_Dark_Transparent,
                  borderRadius: 1,
                  background: !isLoading ?
                    `repeating-linear-gradient(
                      45deg,
                      transparent,
                      transparent 10px,
                      ${error ? color.Yambo_Orange : color.Yambo_Text_On_Dark}10 10px,
                      ${error ? color.Yambo_Orange : color.Yambo_Text_On_Dark}10 11px
                  )` : 'transparent',
                } }
              >
                <>
                  <input { ...getInputProps() } />
                  <Box sx={ { display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', height:'100%' } }>
                    <i 
                    className="fa-light fa-photo-film-music" 
                    style={ { 
                      fontSize:'24px', 
                      transition: 'all 0.1s ease',
                      color:error ? color.Yambo_Orange : color.Yambo_Text_On_Dark_Transparent
                    } }
                    ></i>
                    <Typography 
                      variant="caption" 
                      fontWeight="bold"
                      sx={ { color:error ? `${color.Yambo_Orange} !important` : color.Yambo_Text_On_Dark_Transparent } }
                      >
                      {error ? 
                      helperText
                       : 
                      translate(I18N_KEYS.SHARED_DESIGN_APP.UPLOAD_FILE.CTA)}
                      </Typography>
                  </Box>
                </>
              </Box>
            }
          </Box>
        )}
      </Box>
      <input
        id={ `design-app-file-upload-input-${id}` }
        type="file"
        style={ { display: "none" } }
        { ...getInputProps() }
      />
      {hasError &&
      <Box sx={ { mb:1, width:'100%', display:'flex', justifyContent:'center', px:1 } }>
        <Typography variant="caption">
          <i className="fa-light fa-poo"></i>&nbsp;
          {translate(I18N_KEYS.GENERAL.UPLOAD_ERROR)}
        </Typography>
      </Box>
      }
    </Box>


  );
}

export default DesignAppUploadFile;
