import { Box, Avatar, Button, Typography } from '@mui/material';
import { useContext } from 'react';
import ProfileProperty from './ProfileProperty';
import { useTranslation } from 'react-i18next';
import { I18N_KEYS } from '../../language/keys';
import { AuthContext } from '../../AuthContext';

function Profile({user}) {
    const { t: translate } = useTranslation();
    const { signOut } = useContext(AuthContext);

    return (
        <Box sx={ { justifyContent:'center', alignItems:'center', width:'100%', height:'100%', py:2 } }>
            <Box id="profile-avatar-container">
                <Avatar alt="user.displayName" src={ user.photoURL } sx={ { width:'60px', height:'60px', mr:1 } } />
            </Box>
            <Box id="profile-name-container">
                <ProfileProperty label="Name" value={user.displayName} />
            </Box>
            <Box id="profile-email-container">
                <ProfileProperty label="Email" value={user.email} />
            </Box>
            <Box id="profile-actions-container" sx={{ mt:8, display:'flex', flexDirection:'column', width:'fit-content', gap:2 }}>
                <Button variant="outlined" color="weavy_cta_secondary" onClick={signOut}>
                    <i className="fa-solid fa-arrow-right-from-bracket menu-icon"></i>&nbsp;
                    {translate(I18N_KEYS.SETTINGS.LOGOUT)}
                </Button>
                {/* <Button variant="text" color="error" sx={{ }}>
                    {translate(I18N_KEYS.SETTINGS.DELETE_ACCOUNT)}
                </Button> */}
            </Box>
        </Box>
    )
}

export default Profile;