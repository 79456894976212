import React, { useEffect, useState, useRef } from "react";
import { usePostHog } from "posthog-js/react";
import {
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  Button,
  Snackbar,
  Link,
  Skeleton,
  Slide,
  Alert,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { color } from "../../../colors";
import { useUserRole } from '../UserRoleContext';
import axiosInstance from "../../../services/axiosConfig";
import { CreditsContext } from "../../../services/CreditsContext";
import I18N_KEYS from '../../../language/keys';
import { FlowMode } from '../../../enums/flow-modes.enum';
import ShareDesignAppModal from './ShareDesignAppModal';
import ShareWorkflowModal from './ShareWorkflowModal';

function SlideTransition(props) {
  return <Slide { ...props } direction="down" />;
}

function FlowNavbar(props) {
  const { credits, openUpgradeModal, shouldShowCreditsToMembers } = React.useContext(CreditsContext);
  const posthog = usePostHog();
  const {
    isSaving,
    recipeData,
    setRecipeData,
    recipeId,
    setSelectOnDrag,
    gotoDashboard,
    savingBeforeExit,
    duplicateRecipe,
    flowViewingMode,
    setFlowViewingMode,
    shouldShowDesignAppToggle,
  } = props;


  const [isEditingName, setIsEditingName] = useState(false);
  const [newRecipeName, setNewRecipeName] = useState();
  const oldRecipeName = useRef(null);
  const [activeTool, setActiveTool] = useState("select");
  const nameRef = useRef(null);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  // New state to control the modal visibility
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isShareWorkflowModalOpen, setIsShareWorkflowModalOpen] = useState(false);

  const { t: translate } = useTranslation();

  const role = useUserRole();

  useEffect(() => {
    if (recipeData && recipeData.name) setNewRecipeName(recipeData.name);
  }, [recipeData?.name]);

  const goToDashboard = () => {
    gotoDashboard();
  };

  const renameRecipe = async (newName) => {
    if (newName === recipeData.name) return;
    let updatedRecipe = { ...recipeData, name: newName };
    try {
      await axiosInstance.put(`/v1/recipes/${recipeId}`, updatedRecipe);
      setRecipeData(updatedRecipe);
    } catch (error) {
      console.log("error creating recipe: ", error);
    }
  };

  useEffect(() => {
    if (isEditingName && nameRef.current) {
      oldRecipeName.current = newRecipeName;
      nameRef.current.focus();
      const input = nameRef.current.querySelector('input');
      if (input) input.select();
    }
  }, [isEditingName]);

  const handleRecipeNameChange = (e) => setNewRecipeName(e.target.value);

  const handleRecipeNameBlur = () => {
    if (!newRecipeName.trim()) {
      setNewRecipeName(oldRecipeName.current);
    } else {
      renameRecipe(newRecipeName);
    }
    setIsEditingName(false);
  };

  const handleRecipeNameEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!newRecipeName.trim()) {
        setNewRecipeName(oldRecipeName.current);
      } else {
        renameRecipe(newRecipeName);
      }
      setIsEditingName(false);
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      setNewRecipeName(oldRecipeName.current);
      setIsEditingName(false);
    }
  };

  ///// MENU
  const handleClickPointer = () => {
    setActiveTool("select");
    setSelectOnDrag(true);
  };

  const handleClickHand = () => {
    setActiveTool("pan");
    setSelectOnDrag(false);
  };

  const handleShareLink = async () => {
    posthog.capture('clicked_share');
    const updatedRecipe = { public: true };
    try {
      await axiosInstance.put(`/v1/recipes/${recipeId}`, updatedRecipe);
      await navigator.clipboard.writeText(window.location.href);
      setIsLinkCopied(true);
      setTimeout(() => setIsLinkCopied(false), 2000);
    } catch (error) {
      console.log("error creating recipe: ", error);
    }
  };

  const handleShareWorkflowClick = () => {
    setIsShareWorkflowModalOpen(true);
  };

  const saveToMyFiles = async () => {
    try {
      await duplicateRecipe(recipeId);
    } catch (error) {
      console.log("error duplicating recipe: ", error);
    }
  };

  const handleFlowViewingModeChange = (e, newViewingMode) => {
    if(newViewingMode !== null) {
      setFlowViewingMode(newViewingMode);
      const url = new URL(window.location.href);
      url.searchParams.set('view', newViewingMode);
      window.history.replaceState({}, '', url);
    }
  };

  const handleRestoreVersion = () => {
    console.log('restore version');
  };

  return (
    <Box id="flow-navbar" style={ { display: 'flex', position: 'relative', height: '48px' } }>
      <Box id="navigation-tools" sx={ { display: 'flex', flexDirection: 'row', alignItems: 'center', flex: '0 0 auto' } }>
        {!savingBeforeExit ?
          (<IconButton className="flow-navbar-left-side-button" onClick={ goToDashboard }>
            <i className="fa-light fa-house fa-2xs"></i>
          </IconButton>
          ) : (
            <Box sx={ { width: '40px', display: 'flex', alignItems: "center", justifyContent: 'center' } }>
              <CircularProgress size={ 16 } sx={ { color: color.Yambo_Text_On_Dark } } />
            </Box>
          )}
        <Box sx={ { opacity: flowViewingMode === FlowMode.Workflow ? 1 : 0, transition: 'opacity 0.1s ease-in-out', pointerEvents: flowViewingMode === FlowMode.Workflow  ? 'auto' : 'none' } }>
          <IconButton onClick={ handleClickPointer } className={ activeTool === "select" ? 'flow-navbar-left-side-button active' : 'flow-navbar-left-side-button' }>
            <img src='/icons/pointer.svg' width='13px' />
          </IconButton>
          <IconButton onClick={ handleClickHand } className={ activeTool === "pan" ? 'flow-navbar-left-side-button active' : 'flow-navbar-left-side-button' }>
            <i className="fa-regular fa-xs fa-hand" style={ { fontSize: '14px' } }></i>
          </IconButton>
        </Box>
        {/* {role !== 'editor' && 
          <Box sx={ { ml: 1 } }>
            {viewingVersionMode ? (
              // <Button onClick={ handleRestoreVersion } variant="contained" size="small" sx={ { height: 'auto' } } className="save-this-button" color="weavy_cta_secondary">
              //   {translate(I18N_KEYS.RECIPE_MAIN.FLOW.FLOW_NAVBAR.RESTORE_VERSION)}
              // </Button>
              null
            ) : (
              <Button onClick={ saveToMyFiles } variant="contained" size="small" sx={ { height: 'auto' } } className="save-this-button" color="weavy_cta_secondary">
                {translate(I18N_KEYS.RECIPE_MAIN.FLOW.FLOW_NAVBAR.DUPLICATE_TO_MY_FILES)}
             </Button>
            )}
          </Box>
        } */}
      </Box>
      {recipeData &&
      <>
        <Box sx={ {
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: 'auto',
          zIndex: 1,
        } }
        >
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          {!isEditingName ? (
            <Typography onClick={ () => {
              if (role === 'editor') setIsEditingName(true);
            } }
            >
              {newRecipeName}
            </Typography>
          ) : (
            <TextField
              variant="standard"
              ref={ nameRef }
              value={ newRecipeName }
              onBlur={ handleRecipeNameBlur }
              onKeyDown={ handleRecipeNameEnter }
              fullWidth
              onChange={ handleRecipeNameChange }
              className="flow-navbar-name-input"
            />
          )}
          {shouldShowDesignAppToggle &&
             <Box sx={ { ml: 3 } }>
               <ToggleButtonGroup
                 value={ flowViewingMode }
                 exclusive
                 onChange={ handleFlowViewingModeChange }
                 aria-label="FLOW VIEWING MODE"
                 size="small"
                 sx={ {
                   border: `1px solid ${color.Dark_Grey}`,
                   height: '32px',
                   '& .MuiToggleButton-root': {
                     borderTop: 'none',
                     borderBottom: 'none',
                     borderColor: color.Yambo_Black_Dark,
                     '&:first-of-type': {
                       borderLeft: 'none',
                     },
                     '&:last-of-type': {
                       borderRight: 'none',
                     },
                     backgroundColor: color.Yambo_BG,
                     position: 'relative',
                     '&:hover': {
                       backgroundColor: color.Yambo_BG,
                     },
                     '&.Mui-selected': {
                       backgroundColor: color.Yambo_BG,
                       fontWeight: 'bold',
                       color: color.Yambo_Text_On_Dark,
                       '&::after': {
                         content: '""',
                         position: 'absolute',
                         bottom: 0,
                         left: '50%',
                         transform: 'translateX(-50%)',
                         width: '60px',
                         height: '2px',
                         backgroundColor: color.Yambo_Purple,
                         transition: 'all 0.3s ease',
                       },
                       '&:hover': {
                         backgroundColor: color.Yambo_BG,
                       },
                     },
                   },
                 } }
               >
                 <ToggleButton
                   color="weavy_cta"
                   value={ FlowMode.Workflow }
                   aria-label="Workflow"
                   sx={ {
                     width: '80px',
                     '&.Mui-selected::after': {
                       transform: 'translateX(-50%) !important',
                     },
                   } }
                 >
                   <Typography variant="caption"
                     sx={ {
                       fontWeight: 'inherit',
                       color: flowViewingMode === FlowMode.Workflow ?
                         `${color.Yambo_Text_On_Dark} !important` :
                         `${color.Yambo_Text_On_Dark_Transparent} !important`,
                     } }
                   >Workflow</Typography>
                 </ToggleButton>
                 <ToggleButton
                   color="weavy_cta"
                   value={ FlowMode.App }
                   aria-label="App"
                   sx={ {
                     width: '80px',
                     '&.Mui-selected::after': {
                       transform: 'translateX(-50%) !important',
                     },
                   } }
                 >
                   <Typography
                     variant="caption"
                     sx={ {
                       fontWeight: 'inherit',
                       color: flowViewingMode === FlowMode.App ?
                         `${color.Yambo_Text_On_Dark} !important` :
                         `${color.Yambo_Text_On_Dark_Transparent} !important`,
                     } }
                   >App</Typography>
                 </ToggleButton>
               </ToggleButtonGroup>
             </Box>
          }
          {/* {role === 'editor' && <Box sx={ { display: 'flex', alignItems: 'center', ml: 1, opacity: isSaving ? .5 : 0 } }>
            <i className="fa-solid fa-clock-rotate-left fa-spin fa-spin-reverse fa-2xs"></i>
            <Typography sx={ { ml: 0.5, fontSize: '12px' } }>
              {translate(I18N_KEYS.RECIPE_MAIN.FLOW.FLOW_NAVBAR.SAVING)}
            </Typography>
          </Box>} */}
          
        </Box>
        {role === 'editor' &&
            <Box sx={ {
              display: 'flex',
                alignItems: 'center',
                ml: 2,
                opacity: isSaving ? .5 : 0,
                transition: 'opacity 0.2s ease-in-out',
            } }
            >
              <i className="fa-solid fa-clock-rotate-left fa-spin fa-spin-reverse fa-2xs"></i>
              <Typography sx={ { ml: 0.5, fontSize: '12px' } }>
                {translate(I18N_KEYS.RECIPE_MAIN.FLOW.FLOW_NAVBAR.SAVING)}
              </Typography>
            </Box>
        }
        </Box>
      </>
      }
        
      <Box sx={ {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        marginLeft: 'auto',
      } }
      >
      
      {role === 'editor' &&
          <Box sx={ { mr: 1 } }>
            <Button variant="contained" size="small" sx={ { height: 'auto' } } className="share-button" color="weavy_cta_secondary" onClick={ handleShareWorkflowClick }>
              <i className="fa-sharp fa-regular fa-link-simple fa-rotate-by" style={ { transform: 'rotate(-45deg)' } }></i>&nbsp;Share
            </Button>
          </Box>
      }
        {role === 'editor' && shouldShowCreditsToMembers &&
        <Box id="flow-navbar-plans-and-credits-container" sx={ { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' } }>
          <Box id="flow-navbar-credits-container"
            sx={ {
              background: color.Yambo_BG,
              border: `1px solid ${color.Yambo_Text_On_Dark_Transparent}`,
              borderRadius: 4,
              px: 1,
              py: .2,
              mx: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: "center",
              justifyContent: 'center',
            } }
          >
            {credits !== null && credits !== undefined ?
              <>
                <Typography variant="caption" sx={ { fontWeight: 'bold' } }>{credits} {credits !== 1 ? `Credits` : `Credit`}</Typography>
              </> :
              <Skeleton variant="text" width={ 50 } />
            }
          </Box>
          <Link variant="caption" onClick={ () => openUpgradeModal() } sx={ { mr: 2 } }>Get more</Link>
        </Box>
        }
      </Box>

      <Snackbar
        anchorOrigin={ { vertical: 'top', horizontal: 'center' } }
        open={ isLinkCopied }
        onClose={ () => setIsLinkCopied(false) }
        autoHideDuration={ 500 }
        TransitionComponent={ SlideTransition }
      >
        <Alert
          severity="success"
          variant="filled"
          sx={ { width: '100%', color: color.Yambo_Text_On_Dark, backgroundColor: color.Yambo_Light_Green } }
        >
          Link Copied to Clipboard!
        </Alert>
      </Snackbar>

      <ShareDesignAppModal
        open={ isShareModalOpen }
        onClose={ () => setIsShareModalOpen(false) }
        recipeId={ recipeId }
        posthog={ posthog }
      />
      {isShareWorkflowModalOpen && <ShareWorkflowModal
        open={ isShareWorkflowModalOpen }
        onClose={ () => setIsShareWorkflowModalOpen(false) }
        recipeId={ recipeId }
        posthog={ posthog }
        visibility={ recipeData.visibility }
      />}
    </Box>
  );
}

export default FlowNavbar;
