import { createContext, useContext } from 'react';

const NodeActionsContext = createContext({
  deleteNode: () => {},
  duplicateNode: () => {},
  toggleLockNode: () => {},
  openOverlayDialog: () => {},
  closeOverlayDialog: () => {},
  setCoverImage: () => {},
});

export const useNodeActions = () => useContext(NodeActionsContext);

export const NodeActionsProvider = ({ children, deleteNode, duplicateNode, toggleLockNode, openOverlayDialog, closeOverlayDialog, setCoverImage }) => (
  <NodeActionsContext.Provider value={ { deleteNode, duplicateNode, toggleLockNode, openOverlayDialog, closeOverlayDialog, setCoverImage } }>
    {children}
  </NodeActionsContext.Provider>
);
