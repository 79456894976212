import React, { useEffect, useState } from 'react';
import { Box, Typography } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import LinearProgress from '@mui/material/LinearProgress';
import { useDropzone } from "react-dropzone";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '@mui/icons-material/Upload';
import { color, colorMap } from '../../colors';
import axiosInstance from "../../services/axiosConfig";
import { useUserRole } from "../Recipe/UserRoleContext";
import { hasEditingPermissions, renderMediaElement } from "./Utils";
import { DynamicNode2 } from "./VisualNodes";
import { useTranslation } from "react-i18next";
import I18N_KEYS from '../../language/keys';

function ImportCloudinaryNode({ id, data, updateNodeData }) {
  const role = useUserRole();
  const { t: translate } = useTranslation();
  const [fileLink, setFileLink] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const [previewImage, setPreviewImage] = useState(data.result?.url);
  const [initialData] = useState(data?.initialData); // support drag from library
  const [uploadedFileType, setUploadedFileType] = useState(data.result?.type);
  const [isFocused, setIsFocused] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if(!previewImage){
      setPreviewImage(data.result?.url);
      setUploadedFileType(data.result?.type);
    }

  }, [data?.result]);
  /// this useEffect is to handle a case where the node was created from an existing file dragged from the media library
  useEffect(() => {
    if (initialData) {
      updateNodeData(id, {
        result: data.initialData,
        output: {
          file: data.initialData,
        },
        initialData: null,
      });
    }
  }, [initialData]);

  useEffect(()=>{
    if(data.externalData){
      updateNodeResultAndOutput(data.externalData);
      setPreviewImage(data.externalData.thumbnailUrl);
      setUploadedFileType(data.externalData.type);
    }
  },[data.externalData]);

  const updateNodeResultAndOutput = (fileData) =>{
    updateNodeData(id,
      {
        result: fileData,
        output: {file: fileData},
      });
  }
  const uploadSuccess = (uploadData) => {
    const fileDataToUpdate = {
      url: uploadData.url,
      thumbnailUrl: uploadData.thumbnailUrl,
      viewUrl: uploadData.viewUrl,
      type: uploadData.type,
      width: uploadData.width,
      height: uploadData.height,
      publicId: uploadData.publicId,
      visualId: uploadData.visualId,
      fps: uploadData.fps,
      duration: uploadData.duration,
    }
    updateNodeResultAndOutput(fileDataToUpdate);
    setUploadProgress(100);
    setPreviewImage(uploadData.url);
    setUploadedFileType(uploadData.type);
  };

  const onDrop = async (acceptedFiles) => {
    setHasError(false);
    if (acceptedFiles.length > 0) {
      setUploading(true);
      const file = acceptedFiles[0];
      const fileName = file.name.toLowerCase();

      if (file.type.startsWith('video')) {
        setUploadedFileType('video');
        setPreviewImage(URL.createObjectURL(file));
      } else if (file.type.startsWith('image')) {
        setUploadedFileType('image');
        setPreviewImage(URL.createObjectURL(file));
      } else if (file.type.startsWith('audio')) {
        setUploadedFileType('audio');
        setPreviewImage("/audio.png");
      } else if (fileName.endsWith('.glb')) {
        setUploadedFileType('3D');
      }

      const formData = new FormData();
      formData.append("file", file);

      try {
        setUploadProgress(0);
        const response = await axiosInstance.post(`/v1/nodes/upload`, formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 95,
            );
            setUploadProgress(progress);
          },
        });

        uploadSuccess(response.data);
      } catch (error) {
        console.error("Error uploading file:", error);
        setHasError(true);
      } finally {
        setUploading(false);
        setUploadProgress(0);
      }
    }
  };

  useEffect(() => {
    if(data.pastedData && data.pastedData?.imageFile) {
      onDrop([data.pastedData.imageFile]);
      data.pastedData.imageFile = null;
    }
  }, [data.pastedData]);

  const handleLinkChange = (event) => {
    setHasError(false);
    setFileLink(event.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png','.webp','.webm', '.mp4', '.mov'],
      'audio/*': ['.mp3', '.wav', '.ogg'],
      'model/gltf-binary': ['.glb'],
    },
    multiple: false, // Allow only one file to be dropped/uploaded
  });

  const openFileUploader = () => {
    // Trigger the input file element's click event
    const inputElement = document.getElementById(`file-upload-input-${id}`);
    inputElement.click();
  };

  const handleLinkInput = async (link) => {
    setUploadProgress(0);
    setHasError(false);
    setPreviewImage(link);
    setUploadedFileType('image');
    const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
    if (!urlPattern.test(link)) {
      console.error("Invalid URL");
      setHasError(true);
      
      return;
    }

    try {
      const parsedUrl = new URL(link);
      const filePath = parsedUrl.pathname;
      // const fileUrlPattern = /[/|.|\w|\s|-]*\.(?:jpg|jpeg|png|obj|fbx|glb)/gi;

      if (filePath) {
        setUploading(true);
        const response = await axiosInstance.post(`/v1/nodes/upload`, null, {
          params: {
            file: fileLink,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 95,
            );
            setUploadProgress(progress);
          },
        });

        uploadSuccess(response.data);
      }
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
    finally {
      setUploading(false);
      setUploadProgress(0); // Reset the progress bar after completion or error
    }
  };
  
  return (
    <DynamicNode2 id={ id } data={ data } className="import" inputsLabels={ ['prompt'] } handleColor={ colorMap.get(data.color) } headerColor={ colorMap.get(data.dark_color) }>
      <Box sx={ { width:'100%', pointerEvents: !hasEditingPermissions(role, data) ? 'none':'' } }>
        {(previewImage || data.result) && !hasError ? (
          <Box sx={ { position: 'relative' } }>
            <Box
              className='media-container'
              sx={ { width: "100%", height: "100%" } }
              onClick={ openFileUploader }
            >
              {renderMediaElement(previewImage, uploadedFileType)}
              {data.result && data.result.width && data.result.height && (
                <Typography
                  variant="caption"
                  sx={ { position: 'absolute', top: 5, left: 5, fontWeight:'bold' } }
                >
                  {data.result.width} x {data.result.height} {data.result.duration ? `| ${data.result.duration.toFixed(2)}s` : ''} {data.result.fps ? `| ${data.result.fps} fps` : ''}
                </Typography>
              )}
            </Box>
            {isUploading && (
              <Box sx={ { width: '100%', position: 'absolute', top: 0 } }>
                <LinearProgress variant="determinate" value={ uploadProgress } />
              </Box>
            )}
          </Box>
        ) : (
          <Box id="upload-container" sx={ { textAlign:'center' } }>
            <Box
              { ...getRootProps() }
              sx={ { cursor: "pointer", height:'60px', border:'1px dashed', borderColor:color.Yambo_Blue_Stroke, display:'flex', alignItems:'center', justifyContent:'center', mb:1 } }
            >
              <input { ...getInputProps() } />
              <Typography variant="body">Drag & Drop or Click to Upload</Typography>
            </Box>
            <Box sx={ { mb:1 } }>
              <Typography variant="caption">Or</Typography>
            </Box>
            <OutlinedInput
              className={ isFocused ? "nowheel nodrag nopan": "" }
              onFocus={ () => {setIsFocused(true);} }
              onBlur={ () => {setIsFocused(false);} }
              type="text"
              placeholder="Paste a file link"
              value={ fileLink }
              onChange={ handleLinkChange }
              fullWidth
              size='small'
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="upload-from-link"
                    onClick={ () => handleLinkInput(fileLink) }
                    edge="end"
                  >
                    <UploadIcon />
                  </IconButton>
                </InputAdornment> }
            />
          </Box>
        )}
      </Box>
      <input
        id={ `file-upload-input-${id}` }
        type="file"
        style={ { display: "none" } }
        { ...getInputProps() }
      />
      {hasError && <Box sx={ { mt:1, width:'100%', display:'flex', justifyContent:'center', px:1 } }>
        <Typography variant="caption">
          <i className="fa-solid fa-poo"></i>&nbsp;
          {translate(I18N_KEYS.GENERAL.UPLOAD_ERROR)}
        </Typography>
      </Box>}
    </DynamicNode2>


  );
}

export default ImportCloudinaryNode;
