import React, { createContext, useContext, useState } from 'react';

// Create a context for media
const MediaGalleryContext = createContext();

// Create a hook to use the media context
export const useMediaGalleryContext = () => useContext(MediaGalleryContext);

export const MediaGalleryProvider = ({ children }) => {
  const [mediaArray, setMediaArray] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <MediaGalleryContext.Provider value={ { mediaArray, setMediaArray, showGallery, setShowGallery, selectedFile, setSelectedFile } }>
      {children}
    </MediaGalleryContext.Provider>
  );
};
