import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GoogleLogo } from '../../assets/google-logo.svg';
import { ReactComponent as MicrosoftLogo } from '../../assets/microsoft-logo.svg';
import { AuthContext } from '../../AuthContext';


// pass token and email and remove form url when enter this one
function TempSignin({ fullScreen = true, teamInviteToken, hintEmail }) {
  const navigate = useNavigate();
  const { currentUser, signIn, isSigningIn } = useContext(AuthContext);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    if (currentUser && !isSigningIn) {
      navigate('/');
    } else if (!currentUser) {
      setAuthChecked(true);
    }
  }, [currentUser, isSigningIn, navigate]);

  if (!authChecked) {
    return null;
  }

  //todo: style file
  const buttonStyles = {
    width: "240px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 20px",
    borderRadius: "8px",
    gap: "12px",
    textTransform: "none",
  };

  return (
    <Box
      sx={ {
        width: fullScreen ? "100%" : "auto",
        height: fullScreen ? "100%" : "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      } }
    >
      <Button
        variant="outlined"
        color="weavy_cta_secondary"
        sx={ { ...buttonStyles } }
        onClick={ () => signIn("/", "google", teamInviteToken || null, hintEmail) }
      >
        <GoogleLogo width={ 24 } height={ 24 } alt="Google logo" />
        <Typography>Sign in with Google</Typography>
      </Button>

      <Button
        variant="outlined"
        color="weavy_cta_secondary"
        sx={ { ...buttonStyles } }
        onClick={ () => signIn("/", "microsoft", teamInviteToken || null, hintEmail) }
      >
        <MicrosoftLogo width={ 24 } height={ 24 } alt="Microsoft logo" />
        <Typography>Sign in with Microsoft</Typography>
      </Button>
    </Box>
  );
}

export default TempSignin;
