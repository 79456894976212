import React, { useEffect, useState } from "react";
import { Box, FormControlLabel, Switch, OutlinedInput, Typography, FormControl, RadioGroup, Radio } from "@mui/material";
import { useUserRole } from "../Recipe/UserRoleContext";
import { hasEditingPermissions } from "./Utils";
function SeedCore({ id, data, updateNodeData }) {

  const role = useUserRole();

  const [isRandom, setIsRandom] = useState(data.isRandom ?? false);
  const [seed, setSeed] = useState(data.seed ?? Math.floor(Math.random() * 1000000) + 1);
  const [tempSeed, setTempSeed] = useState(seed);

  useEffect(()=>{
    if(data.externalData !== undefined){
      setSeed(data.externalData.seed);
      setIsRandom(data.externalData.isRandom);
      setTempSeed(data.externalData.seed);
    }
  }, [data.externalData]);

  useEffect(()=>{
    updateNodeData(id, {
      result: {
        isRandom: isRandom,
        seed: seed,
      },
      isRandom: isRandom,
      seed: seed,
      output:{
        type: "seed",
        [data.handles.output[0]]: {
          isRandom: isRandom,
          seed: seed,
        },
      },
      
    });
  }, [seed, isRandom]);

  const handleSeedChange = (event) => {
    setTempSeed(event.target.value);
  };

  const commitSeed = () => {
    setSeed(tempSeed);
  };

  return (
    <Box sx={ {
      width: '100%',
      pointerEvents: !hasEditingPermissions(role, data) ? 'none' : '',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'left',
    } }
    >
      <FormControl sx={ { width: '100%' } }>
        <RadioGroup
          row
        
          value={ isRandom ? 'random' : 'fixed' }
          onChange={ (e) => setIsRandom(e.target.value === 'random') }
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="random" control={<Radio size="small" />} label="Random" />
          <FormControlLabel value="fixed" control={<Radio size="small" />} label="Fixed" />
        </RadioGroup>
      </FormControl>
      { !isRandom && <OutlinedInput
        value={ tempSeed }
        size="small"
        fullWidth
        disabled={ isRandom }
        color="weavy_green_outline"
        inputProps={ { type: 'number', step: 1 } }
        onChange={ handleSeedChange }
        onBlur={ commitSeed }
        onKeyDown={ (e) => {
          if (e.key === 'Enter') {
            commitSeed();
            e.target.blur();
          }
        } }
        onFocus={ (e) => e.target.select() }
        sx={ {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          }
        } }
      /> }
    </Box>
  );
}

export default SeedCore;
