import { Box, Typography, Button, Popover, Tooltip,  Slider, Input, TextField, Skeleton, Link, Divider, Chip } from "@mui/material";
import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { color } from "../../../colors";
import CancellableLoadingButton from "../../CancellableLoadingButton/CancellableLoadingButton";
import { CreditsContext } from "../../../services/CreditsContext";
import { useUserRole } from '../UserRoleContext';
import { renderDesignAppParams, getWorkflowInputNodes, getConnectedOutputNodesCount } from '../DesignApp/DesignAppUtils';
import DesignAppUploadFile from "../DesignApp/DesignAppUploadFile";
import DesignAppToolbar from "../DesignApp/DesignAppToolbar";
import DesignAppGallery from "../DesignApp/DesignAppGallery";
import DesignAppPrompt from "../DesignApp/DesignAppPrompt";
import DesignAppInputHeader from "../DesignApp/DesignAppInputHeader";
import axiosInstance from "../../../services/axiosConfig";
import I18N_KEYS from '../../../language/keys';
import { NodeType } from '../../../enums/node-type.enum';
import { DesignAppMode } from '../../../enums/design-app-modes.enum';
import ReadOnlyPanel from "./ReadOnlyPanel";

const PARAM_TYPES = ['seed', 'array', 'number', 'integer', 'boolean', 'mux'];
const DESIGN_APP_HEADER_HEIGHT = 48;
const DESIGN_APP_HEADER_WIDTH = 400;
const CONTROLS_HEIGHT = 116;

export const InputPanelSkeleton = () => {
  return (
    <Box id="design-app-inputs-container-skeleton" 
    sx={ { 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      mb:1,
      p:2
    } }>

        <Divider sx={ { ml:-2 } } textAlign="left">
        <Box sx={ { display: 'flex', alignItems: 'center', gap: 1 } }>
        <Chip
            size="small"
            label={ <Skeleton variant="rounded" width={100} height={16} /> }
          />
        </Box>
        
      </Divider>
      <Box sx={ { display: 'flex', flexDirection: 'column', my:2 } }>
        <Skeleton animation="wave" variant="rounded" width="90%" height={8} />
      </Box>
      <Box sx={ { display: 'flex', flexDirection: 'column', border: `1px solid ${color.Dark_Grey}`, borderRadius: 1, p:1, gap: 1 } }>
        <Skeleton animation="wave" variant="rounded" width="100%" height={8} />
        <Skeleton animation="wave" variant="rounded" width="100%" height={8} />
        <Skeleton animation="wave" variant="rounded" width="80%" height={8} />
        <Skeleton animation="wave" variant="rounded" width="60%" height={8} />
      </Box>
    </Box>
  );
};

function DesignApp({ recipeId, recipeData, setRecipeData, nodes, edges, readOnly = false , updateNodeData, saveDesignAppMetadata }) {

  // todo: disable button while input file is being uploaded

  const [designAppData, setDesignAppData] = useState(null);
  const { t: translate } = useTranslation();
  const { setUserCredits } = useContext(CreditsContext);
  const role = useUserRole();
  // designApp object
  const [inputs, setInputs] = useState([]);
  const [results, setResults] = useState([]);
  const [numberOfRuns, setNumberOfRuns] = useState(1);
  const [isLoadingDesignApp, setIsLoadingDesignApp] = useState(true);
  const [isParsingInputs, setIsParsingInputs] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [mode, setMode] = useState(role === 'editor' && !readOnly ? DesignAppMode.Editing : DesignAppMode.Running);
  const canCancel = useRef(false);
  const isLoading = isLoadingDesignApp || isParsingInputs;
  /// ui elements
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const [leftPanelWidth, setLeftPanelWidth] = useState(`${localStorage.getItem('designAppLeftPanelWidth') || '30'}%`);
  const [isDraggingPanel, setIsDraggingPanel] = useState(false);

  // handle validation
  const [validationErrors, setValidationErrors] = useState({});
  const inputRefs = useRef({});

  const [numberOfLoadingResults, setNumberOfLoadingResults] = useState(0);

  // drag and drop
  const [draggedInput, setDraggedInput] = useState(null);
  const [targetInput, setTargetInput] = useState(null);
  const [isEditingInputMetadata, setIsEditingInputMetadata] = useState(false); // to prevent dragging input metadata while editing text

  /// run workflow
  const [isProcessing, setIsProcessing] = useState(false);
  // const [progress, setProgress] = useState(0);

  const [errorMessage, setErrorMessage] = useState(null);

  /// gallery
  const [selected, setSelected] = useState(0);
  

  const isMounted = useRef(true);

  const [designAppMetadata, setDesignAppMetadata] = useState(recipeData?.designAppMetadata || {});


  /// validate inputs
  const validateInputs = () => {
    const errors = {};
    inputs.filter(input => input.exposed).forEach(input => {
      if (!input.value || 
          (input.type === NodeType.Prompt && !input.value.prompt) ||
          (input.type === NodeType.String && !input.value.string?.trim())) {
        errors[input.id] = translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR);
      }
      if(input.type === NodeType.Import && !input.value.file?.url && !input.value.url) {
        errors[input.id] = translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR_FILE);
      }
      if(input.type === NodeType.MultiLora && !input.value.selectedLora?.file) {
        errors[input.id] = translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.REQUIRED_ERROR_LORA);
      }
    });

    setValidationErrors(errors);
    // If there are errors, scroll to the first one
    if (Object.keys(errors).length > 0) {
      const firstErrorId = Object.keys(errors)[0];
      const inputsContainer = document.querySelector('#design-app-inputs-container');
      const errorElement = inputRefs.current[firstErrorId];
      
      if (inputsContainer && errorElement) {
        inputsContainer.scrollTo({
          top: errorElement.offsetTop - inputsContainer.offsetTop - 16,
          behavior: 'smooth'
        });
      }
    }
    return Object.keys(errors).length === 0;
  };

  /// end validate inputs

  /// parsing workflow inputs

  const getParsedInputValue = (input, existingValue) => {
    // todo: simplify conditions

    if(input.type === NodeType.MultiLora){
      return  mode !== DesignAppMode.Editing && existingValue ? existingValue : {
        weight: input.data.weight,
        selectedLora: input.data.selectedLora,
      };
    }

    if(input.type === NodeType.Prompt){
      return  mode !== DesignAppMode.Editing && existingValue ? existingValue : {
        prompt: input.data.result.prompt,
      };
    }
    if(input.type === NodeType.Import){
      return  mode !== DesignAppMode.Editing && existingValue ? existingValue : {
        file: input.data.result,
      };
    }

    if(input.type === NodeType.String){
      return  mode !== DesignAppMode.Editing && existingValue ? existingValue : {
        string: input.data.result.string,
      };
    }

    return  (mode !== DesignAppMode.Editing && existingValue ? existingValue : input.data.result);
  };

  const numberOfOutputNodes = useMemo(() => {
    return getConnectedOutputNodesCount(nodes, edges);
  }, [nodes]);

  const parseInputs = (inputNodes, designAppData) => {
    setIsParsingInputs(true);
    const clonedInput = _.cloneDeep(inputNodes);
    const filteredPrompts = clonedInput.filter((n) => {
      return n.type === NodeType.Prompt && !n.data.isLocked;
    });
    const filteredImports = clonedInput.filter((n) => {
      return n.type === NodeType.Import && !n.data.isLocked;
    });
    const filteredStrings = clonedInput.filter((n) => {
      return n.type === NodeType.String && !n.data.isLocked;
    });
    const filteredLoRas = clonedInput.filter((n) => {
      return n.type === NodeType.MultiLora && !n.data.isLocked;
    });
    const filteredParamNodes = clonedInput.filter((n) => {
      return PARAM_TYPES.includes(n.type) && !n.data.isLocked;
    });

    const allInputs = [...filteredPrompts, ...filteredStrings, ...filteredImports, ...filteredLoRas];

    const parsedInputs = [];

    for (const [index, input] of allInputs.entries()) {
      
      const existingValue = designAppData?.inputs?.find((i) => i.id === input.id)?.value;
      const existingOrder = designAppData?.inputs?.find((i) => i.id === input.id)?.order;
      const existingExposed = designAppData?.inputs?.find((i) => i.id === input.id)?.exposed;

      let inputMetadata;
      if(designAppMetadata[input.id]){
        inputMetadata = designAppMetadata[input.id];
      } else {
        inputMetadata = {
          order: index,
          required: false,
          exposed: true,
        };
        setDesignAppMetadata((prevMetadata) => ({
          ...prevMetadata,
          [input.id]: inputMetadata,
        }));
      }

      const parsedInput = {
        id: input.id,
        type: input.type,
        description: input.data.description,
        value: getParsedInputValue(input, existingValue),
        exposed: role === 'editor' && mode === DesignAppMode.Editing ? 
          (existingExposed ?? inputMetadata.exposed) : 
          inputMetadata.exposed,
        order: role === 'editor' && mode === DesignAppMode.Editing ? 
          (existingOrder ?? inputMetadata.order) : 
          inputMetadata.order,
        required: inputMetadata.required,
      };
      parsedInputs.push(parsedInput);
    }

    for (const [index, paramNode] of filteredParamNodes.entries()) {
      const existingValue = designAppData?.inputs?.find((i) => i.id === paramNode.id)?.value;
      const existingOrder = designAppData?.inputs?.find((i) => i.id === paramNode.id)?.order;
      const existingExposed = designAppData?.inputs?.find((i) => i.id === paramNode.id)?.exposed;
      

      let inputMetadata;
      if(designAppMetadata[paramNode.id]){
        inputMetadata = designAppMetadata[paramNode.id];
      } else {
        inputMetadata = {
          order: existingOrder || index,
          required: false,
          exposed: false,
        };
        setDesignAppMetadata((prevMetadata) => ({
          ...prevMetadata,
          [paramNode.id]: inputMetadata,
        }));
      }

      const parsedParam = {
        id: paramNode.id,
        type: paramNode.type,
        description: paramNode.data.description,
        value: getParsedInputValue(paramNode, existingValue),
        exposed: role === 'editor' && mode === DesignAppMode.Editing ? 
          (existingExposed ?? inputMetadata.exposed) : 
          inputMetadata.exposed,
        order: role === 'editor' && mode === DesignAppMode.Editing ? 
          (existingOrder ?? inputMetadata.order) : 
          inputMetadata.order,
        mode: paramNode.data.mode,
        required: inputMetadata.required,
      };
      parsedInputs.push(parsedParam);
    }

    // Sort inputs by order
    const sortedInputs = parsedInputs.sort((a, b) => {
      return a.order - b.order;
    });
    setInputs(sortedInputs);
    setIsParsingInputs(false);
  };

  /// end parsing workflow inputs

  // init
  useEffect(() => {
    setIsLoadingDesignApp(true);
    fetchDesignAppData(recipeData.recipeVersion);
    return () => {
      isMounted.current = false;
    };
  }, []);

  /// handle input change
  const handleChange = (paramId, newValue) => {
    setErrorMessage(null);
    setValidationErrors(prev => {
      const updated = { ...prev };
      delete updated[paramId];
      return updated;
    });
    if(role === 'editor'){
      updateNodeData(paramId, { externalData: newValue });
    }
    setInputs((prevInputs) => {
      return prevInputs.map((input) => {
        if (input.id === paramId) {
          const updatedInput = _.cloneDeep(input);
          updatedInput.value = newValue;
          
          return updatedInput;
        }
        
        return input;
      });
    });
  };

  const handleExposeParam = (paramId, exposed) => {
    setDesignAppMetadata((prevMetadata) => {
      const updatedMetadata = { ...prevMetadata };
      // Update order for all inputs in metadata
      updatedMetadata[paramId] = {
        ...updatedMetadata[paramId],
        exposed: exposed,
      };

      return updatedMetadata;
    });
    setInputs((prevInputs) => {
      return prevInputs.map((input) => {
        if (input.id === paramId) {
          const updatedInput = _.cloneDeep(input);
          updatedInput.exposed = exposed;
          
          return updatedInput;
        }
        
        return input;
      });
    });
  };

  /// end handle input change

  /// debounce save design app
  const debounceDesignAppTimeoutRef = useRef();
  const debounceDesignAppMetadataTimeoutRef = useRef();

  const saveDesignApp = async (updatedInputs) => {
    try {
      await axiosInstance.post(`/v1/recipes/${recipeId}/design-app-params/save`, {
        designApp: updatedInputs,
      });

      // Optionally update local state with the response
      setDesignAppData((prevData) => ({
        ...prevData,
        inputs: updatedInputs,
      }));
    } catch (error) {
      console.error('Failed to save design app', error);
    }
  };

  const debouncedSaveApp = useCallback((inputs) => {
    clearTimeout(debounceDesignAppTimeoutRef.current);
    debounceDesignAppTimeoutRef.current = setTimeout(() => {
      saveDesignApp(inputs);
    }, 500);
  }, []);


  useEffect(() => {
    setDesignAppData({ inputs, results });
    debouncedSaveApp(inputs);

    return () => {
      clearTimeout(debounceDesignAppTimeoutRef.current);
    };
  }, [inputs, debouncedSaveApp, results]);



  // debounce save design app metadata
  const debouncedSaveDesignAppMetadata = useCallback((designAppMetadata) => {
    clearTimeout(debounceDesignAppMetadataTimeoutRef.current);
    debounceDesignAppMetadataTimeoutRef.current = setTimeout(() => {
      saveDesignAppMetadata(false, designAppMetadata);
    }, 500);
  }, []);

  useEffect(() => {
    debouncedSaveDesignAppMetadata(designAppMetadata);

    return () => {
      clearTimeout(debounceDesignAppMetadataTimeoutRef.current);
    };
  }, [designAppMetadata, debouncedSaveDesignAppMetadata]);

  /// end debounce save design app metadata

  const DefaultsComment = () => {
    if(mode === DesignAppMode.Running) return null;

    return(
      <Box sx={ { width:'100%', display:'flex', justifyContent:'center', alignItems:'center', mt:1 } }>
        <Typography variant="caption" sx={ { color:'transparent' } }>
          {translate(I18N_KEYS.SHARED_DESIGN_APP.INPUTS.DEFAULTS_COMMENT)}
        </Typography>
      </Box>
    );
  };

  const renderInputContent = (input) => {

    const hasError = !!validationErrors[input.id];
    
    const validationProps = {
      error: hasError,
      helperText: hasError ? validationErrors[input.id] : null
    };
    switch (input.type) {
      case NodeType.Prompt:
        return (
          <Box sx={ { width:'100%', display:'flex', flexDirection:'column' } }>
            <DesignAppInputHeader
              input={ designAppMetadata[input.id] }
              inputId={ input.id }
              updateNodeData={ updateNodeData }
              role={ role }
              inputColor={ color.Yambo_Green }
              nodes={ nodes }
              setIsEditingInputMetadata={ setIsEditingInputMetadata }
              mode={ mode }
              error={hasError}
            />
            <DesignAppPrompt
              id={ input.id }
              value={ input.value?.prompt }
              onChange={ handleChange }
              updateNodeData={ updateNodeData }
              setIsEditingInputMetadata={ setIsEditingInputMetadata }
              mode={ mode }
              role={ role }
              isLoading={ isLoading }
              {...validationProps}
            />
          </Box>
        );
      case NodeType.Import:
        return (
          <Box sx={ { width:'100%', display:'flex', flexDirection:'column' } }>
            <DesignAppInputHeader
              input={ input }
              inputId={ input.id }
              updateNodeData={ updateNodeData }
              role={ role }
              inputColor={ color.Yambo_Blue }
              nodes={ nodes }
              setIsEditingInputMetadata={ setIsEditingInputMetadata }
              mode={ mode }
              error={hasError}
            />
            <DesignAppUploadFile
              id={ input.id }
              value={ input.value }
              onUpload={ handleChange }
              mode={ mode }
              role={ role }
              isLoading={ isLoading }
              updateNodeData={ updateNodeData }
              setIsUploading={ setIsUploading }
              {...validationProps}
            />
          </Box>
        );
      case NodeType.String:
        return (
          <Box sx={ { width:'100%', display:'flex', flexDirection:'column' } }>
            <DesignAppInputHeader
                input={ input }
                inputId={ input.id }
                updateNodeData={ updateNodeData }
                role={ role }
                inputColor={ color.Yambo_Blue }
                nodes={ nodes }
                setIsEditingInputMetadata={ setIsEditingInputMetadata }
                mode={ mode }
              />
              <TextField
                fullWidth
                multiline
                onFocus={ () => setIsEditingInputMetadata(true) }
                onBlur={ () => setIsEditingInputMetadata(false) }
                size="small"
                value={ input.value?.string }
                onChange={ (e) => handleChange(input.id, e.target.value) }
                sx={ {
                  background: `${color.Yambo_BG}`,
                } }
                {...validationProps}
                // todo: add validation
              />
            </Box>
          );
      case NodeType.MultiLora:
        return (
          <Box sx={ { width:'100%', display:'flex', flexDirection:'column' } }>
            <DesignAppInputHeader
              input={ designAppMetadata[input.id] }
              inputId={ input.id }
              updateNodeData={ updateNodeData }
              role={ role }
              inputColor={ color.Yambo_Blue }
              nodes={ nodes }
              setIsEditingInputMetadata={ setIsEditingInputMetadata }
              mode={ mode }
              error={hasError}
            />
            {renderDesignAppParams(nodes.find((node) => node.id === input.id), input, handleChange, isLoading, validationProps)}
          </Box>
        );
      default:
        return (
          <Box sx={ { width:'100%', display:'flex', flexDirection:'column' } }>
            <DesignAppInputHeader
              input={ designAppMetadata[input.id] }
              inputId={ input.id }
              updateNodeData={ updateNodeData }
              role={ role }
              inputColor={ color.Yambo_Green }
              nodes={ nodes }
              setIsEditingInputMetadata={ setIsEditingInputMetadata }
              mode={ mode }
              error={hasError}
            />
            {renderDesignAppParams(nodes.find((node) => node.id === input.id), input, handleChange, isLoading, validationProps, true)}
          </Box>
        );
    }
  };

  // Settings
  const openSettings = (e) => {
    setSettingsAnchorEl(e.currentTarget);
    setSettingsOpen(true);
  };

  const handleStartProcessing = () => {
    setErrorMessage(null);
    setIsProcessing(true);
    // setProgress(0);
  };

  const handleStopProcessing = () => {
    setIsProcessing(false);
    // setProgress(0);
  };
  
  const pollRunsStatus = async (runIds) => {
    let runIdsToPoll = [...runIds];
    const poll = async () => {
      if(!runIdsToPoll.length){
        handleStopProcessing();

        return;
      }

      try {
        const response = (await axiosInstance.get(`/v1/recipes/${recipeId}/runs/status?runIds=${runIdsToPoll.join(',')}`)).data;

        runIdsToPoll = [];

        for (const runId in response?.runs) {
          const currentRunStatus = response?.runs[runId];
          const status = currentRunStatus.status;
          switch (status) {
            case "COMPLETED":
              setResults((prevResults) => [...currentRunStatus.results, ...prevResults]);
              setUserCredits(response?.remainingCredits);
              break;

            case "FAILED":
              // todo: better solution when one out of many fails
              setErrorMessage(currentRunStatus.error);
              break;

            case "CANCELED":
              break;

            case "RUNNING":
              runIdsToPoll.push(runId);
              break;
          }
        }

        setNumberOfLoadingResults(runIdsToPoll.length * numberOfOutputNodes);

        if (isMounted.current) {
          // Schedule next poll
          setTimeout(poll, 1000);
        }
      } catch (error) {
        setErrorMessage(error?.message || "Something went wrong");
        handleStopProcessing();
      }
    };

    // Start polling
    await poll();
  };

  const handleExistingRuns = (runs) => {
    const runningRunIds = [];
    runs?.forEach((run) => {
      if(run.status === 'COMPLETED' || run.status === 'CANCELED'){
        return;
      }

      if (run.status === 'FAILED') {
        setErrorMessage(run.error || 'Something went wrong');

        return;
      }

      runningRunIds.push(run.runId);
    });

    if(runningRunIds.length){
      canCancel.current = true;
      handleStartProcessing();
      pollRunsStatus(runningRunIds);
    }
  };

  const fetchDesignAppData = async (version) => {
    try {
      const response = await axiosInstance.get(`/v1/recipes/${recipeId}/user-design-app?version=${version}`);
      const fetchedData = response.data;

      setDesignAppData(fetchedData);
      setResults(fetchedData?.results || []);

      // Initialize inputs based on fetched data
      const workflowInputNodes = getWorkflowInputNodes(nodes, edges);
      parseInputs(workflowInputNodes, fetchedData);

      // Check for running tasks
      if (fetchedData?.latestRuns?.length) {
        handleExistingRuns(fetchedData.latestRuns);
      }
    } catch (error) {
      setErrorMessage(error?.message || 'Failed to fetch design app data');
      console.error('Error fetching design app data:', error);
    } finally {
      setTimeout(() => {
        setIsLoadingDesignApp(false);
      }, 1000);
    }
  };

  // Run
  const run = async () => {

    if (!validateInputs()) {
      setErrorMessage('Please fill in all required fields');
      return;
    }
    canCancel.current = false;
    handleStartProcessing();
    setNumberOfLoadingResults(numberOfOutputNodes * numberOfRuns);

    const body = {
      inputs: inputs?.map((input) => ({
        nodeId: input.id,
        input: input.value,
      })) || [],
      numberOfRuns,
      recipeVersion: recipeData.recipeVersion,
    };

    try{
      const response = await axiosInstance.post(`/v1/recipes/${recipeId}/run`, body,{ "axios-retry": { retries: 0 } });
      const runIds = response.data.runIds;
      canCancel.current = true;

      // should solve the issue of going to the workflow tab and back here. the data needs to be updated
      // todo: replace with actual to get the design app api when entering this page
      setDesignAppData({ ...designAppData, latestRuns: runIds.map((runId) => ({ runId, status: 'RUNNING' })) });

      // Start polling in the background
      pollRunsStatus(runIds);
    } catch (e) {
      console.error('Failed to run recipe', e);
      setErrorMessage(e?.message || "Something went wrong");
      handleStopProcessing();
    }
  };

  const cancelRun = async () => {
    handleStopProcessing();
    try{
      await axiosInstance.post(`/v1/recipes/${recipeId}/runs/cancel`);
    } catch (e) {
      console.error('Failed to cancel prediction', e);
    }
  };

  /// handle drag and drop for reordering inputs
  const handleDragStart = (e, inputId) => {
    setDraggedInput(inputId);
    e.dataTransfer.setData('text/plain', inputId);
  };

  const handleDragEnd = () => {
    setDraggedInput(null);
    setTargetInput(null);
  };
  const handleDragOver = (e, inputId) => {
    e.preventDefault();
    setTargetInput(inputId);
  };

  const handleDrop = (e, targetInputId) => {
    e.preventDefault();
    if (!draggedInput || draggedInput === targetInputId) return;
  
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      const draggedIndex = newInputs.findIndex((input) => input.id === draggedInput);
      const targetIndex = newInputs.findIndex((input) => input.id === targetInputId);
      
      // Remove dragged item and insert at new position
      const [draggedItem] = newInputs.splice(draggedIndex, 1);
      newInputs.splice(targetIndex, 0, draggedItem);
  
      // Update order property for all inputs
      const reorderedInputs = newInputs.map((input, index) => ({
        ...input,
        order: index,
      }));

      // Update metadata if user is editor
      if (role === 'editor') {
        setDesignAppMetadata((prevMetadata) => {
          const updatedMetadata = { ...prevMetadata };
          // Update order for all inputs in metadata
          reorderedInputs.forEach((input) => {
            updatedMetadata[input.id] = {
              ...updatedMetadata[input.id],
              order: input.order,
            };
          });

          return updatedMetadata;
        });
      }
      return reorderedInputs;
    });
  };
  
  const shouldShowTopIndicator = (targetInputId, draggedInputId) => {
    if (!targetInputId || !draggedInputId) return false;
    
    // Get the indices from the inputs array
    const targetIndex = inputs.findIndex((input) => input.id === targetInputId);
    const draggedIndex = inputs.findIndex((input) => input.id === draggedInputId);
    
    // Show top indicator if dragged item is coming from below
    return draggedIndex > targetIndex;
  };
  
  const getInputContainerStyles = (inputId) => ({
    width: '100%',
    p: 2,
    borderTop: targetInput === inputId && shouldShowTopIndicator(inputId, draggedInput) && draggedInput ?
      `2px solid ${color.Yambo_Purple}` :
      'none',
    borderBottom: targetInput === inputId && !shouldShowTopIndicator(inputId, draggedInput) && draggedInput ?
      `2px solid ${color.Yambo_Purple}` :
      'none',
    opacity: draggedInput === inputId ? 0.5 : 1,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: '1001',
      left: '0px',
      top: '-4px',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      backgroundColor: color.Yambo_Purple,
      display: targetInput === inputId && shouldShowTopIndicator(inputId, draggedInput) && draggedInput ?
        'block' :
        'none',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: '1001',
      left: '0px',
      bottom: '-4px',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      backgroundColor: color.Yambo_Purple,
      display: targetInput === inputId && !shouldShowTopIndicator(inputId, draggedInput) && draggedInput ?
        'block' :
        'none',
    },
  });

  function getButtonTitle() {
    if(isProcessing){
      return 'Running';
    }

    return results?.length ? 'Re-run': 'Run';
  }

  const formatError = (error) => {
    if (!error) return "Something went wrong";
    
    return typeof error === 'string' ? error : JSON.stringify(error);
  };

  const formattedMessage = formatError(errorMessage);

  const disableRunButton = () => {
    return isLoadingDesignApp || isUploading;
  };

  const handleMouseDown = (e) => {
    setIsDraggingPanel(true);
    e.preventDefault(); // Prevent text selection while dragging
  };

  useEffect(() => {

    let tempWidth;
    const handleMouseMove = (e) => {
      if (!isDraggingPanel) return;
      
      const container = document.getElementById('design-app-container');
      if (!container) return;
  
      const containerRect = container.getBoundingClientRect();
      const newWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;
      
      // Limit the width between 20% and 80%
      const limitedWidth = Math.min(Math.max(newWidth, 20), 80);
      setLeftPanelWidth(`${limitedWidth}%`);
      localStorage.setItem('designAppLeftPanelWidth', `${limitedWidth}%`);
      tempWidth = limitedWidth;
    };
  
    const handleMouseUp = () => {
      setIsDraggingPanel(false);
      localStorage.setItem('designAppLeftPanelWidth', tempWidth);
    };
  
    if (isDraggingPanel) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDraggingPanel]);

  // gallery 
  const deleteCurrentResult = () => {
    console.log('deleteCurrentResult');
    const newResult = results.filter((_, index) => index !== selected);
    setSelected(0);
    setResults(newResult);
  }

  const deleteAllResults = () => {
    console.log('deleteAllResults');
    setResults([]);
    setSelected(0);
  }

  const deleteAllOthersResults = () => {
    console.log('deleteAllOthersResults');
    const newResult = results.filter((_, index) => index === selected);
    setResults(newResult);
    setSelected(0);
  }


  return (
    <Box
      id="design-app-container"
      sx={ {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        background: `${color.Yambo_BG}66`,
        zIndex: 9999,
        p: 1,
      } }
    >
      {role === 'editor' &&
        <DesignAppToolbar
          recipeId={ recipeId }
          recipeData={ recipeData }
          setRecipeData={ setRecipeData }
          height={ DESIGN_APP_HEADER_HEIGHT }
          mode={ mode }
          setMode={ setMode }
        />}
      {readOnly && <ReadOnlyPanel recipeData={ recipeData } viewingVersionMode={ readOnly } width={ DESIGN_APP_HEADER_WIDTH } height={ DESIGN_APP_HEADER_HEIGHT } />}
      <Box
        sx={ {
          width: `100%`,
          height: role === 'editor'? `calc(100% - ${DESIGN_APP_HEADER_HEIGHT}px)` : '100%',
          flexDirection: 'row',
          display: 'flex',
          overflow: 'hidden',
          gap: 0,

        } }
      >
        <Box
          id="design-app-inputs-controls-container"
          sx={ {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: leftPanelWidth,
            overflow: 'auto',
            minWidth: '20%',
            maxWidth: '80%',
            position: 'relative',
            
          } }
        >

          {/* Inputs */}
          <Box id="design-app-inputs-container"
            sx={ {
              width: '100%',
              background: `${color.Dark_Blue}CC`,
              height: `calc(100% - ${CONTROLS_HEIGHT + 16}px)`,
              overflow: 'auto' ,
              border: `1px solid ${color.Dark_Grey}`,
              position: 'relative',
              // py:2,
              borderRadius: 3,
            } }
          >
            {isLoading && <InputPanelSkeleton />}
            {!isLoading && inputs.filter((input) => input.exposed).length > 0 && <DefaultsComment />}
            {!isLoading && inputs.filter((input) => input.exposed).length > 0 ? ( // empty state
              inputs.filter((input) => input.exposed).map((input) => (
                <Box
                  key={ `input-${input.id}` }
                  ref={ (el) => inputRefs.current[input.id] = el }
                  sx={ getInputContainerStyles(input.id) }
                  draggable = { role === 'editor' && !isEditingInputMetadata && mode === DesignAppMode.Editing }
                  onDragStart={ (e) => handleDragStart(e, input.id) }
                  onDragEnd={ handleDragEnd }
                  onDragOver={ (e) => handleDragOver(e, input.id) }
                  onDrop={ (e) => handleDrop(e, input.id) }
                >
                  {renderInputContent(input)}
                </Box>
              ))) : (
              !isLoading && <Box sx={ { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' } }>
                <img src="/illustrations/no-inputs.png" alt="No inputs" style={ { width: '60%', filter:'grayscale(100%)', opacity:0.7 } } />
                <Typography variant="body2">{translate(I18N_KEYS.SHARED_DESIGN_APP.NO_INPUTS.TITLE)}</Typography>

                <Typography variant="caption">
                  {translate(I18N_KEYS.SHARED_DESIGN_APP.NO_INPUTS.TIP)}
                </Typography>
              </Box>
            )}
          </Box>
          {/* Run button and settings */}
          <Box
            id="design-app-controls-container"
            sx={ {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              background: `${color.Dark_Blue}CC`,
              width: '100%',
              height: CONTROLS_HEIGHT,
              zIndex: 9998,
              border: `1px solid ${color.Dark_Grey}`,
              p:2,
              borderRadius: 3,
            } }
          >
            <Box
              sx={ {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                mb:1,
                gap: 1,
              } }
            >
              <Typography variant="caption" fontWeight="bold" sx={ {
                whiteSpace: 'nowrap',
                minWidth: 'fit-content',
              } }
              >
                Number of Results
              </Typography>
              <Slider
                size="small"
                value={ numberOfRuns }
                onChange={ (event, newValue) => setNumberOfRuns(newValue) }
                aria-labelledby="number-of-runs-slider"
                max={ 10 }
                min={ 1 }
                sx={ { ml:2 } }
                step={ 1 }
                valueLabelDisplay="auto"
              />
              <Input
                value={ numberOfRuns }
                size="small"
                onChange={ (e) => setNumberOfRuns(Number(e.target.value)) }
                inputProps={ {
                  step: 1,
                  min: 1,
                  max: 10,
                  type: 'number',
                  'aria-labelledby': 'input-slider',
                  style: {
                    width: '50px',
                    fontSize: '10px',
                  },
                } }
                sx={ { ml:2 } }
              />
            </Box>

            <Box id="design-app-run-and-params-buttons-container" sx={ { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' } }>
              <CancellableLoadingButton
                run={ run }
                onCancel={ cancelRun }
                isProcessing={ isProcessing }
                title={ getButtonTitle() }
                data={ {} }
                canCancel={ canCancel.current }
                disabled={ disableRunButton() }
              />
              {/* if there are params -> render params button and popover */}
              {inputs.filter((input) => !input.exposed).length > 0 && (
                <>
                  <Button
                    onClick={ openSettings }
                    variant="contained"
                    color="weavy_cta_secondary"
                    sx={ { ml: 1 } }
                  >
                    <i className="fa-light fa-sliders"></i>
                  </Button>
                  <Popover
                    id="settings-popover"
                    open={ settingsOpen }
                    anchorEl={ settingsAnchorEl }
                    onClose={ () => setSettingsOpen(false) }
                    anchorOrigin={ {
                      vertical: 'top',
                      horizontal: 'right',
                    } }
                    transformOrigin={ {
                      vertical: 'bottom',
                      horizontal: 'right',
                    } }
                    sx={ {
                      '& .MuiPopover-paper': {
                        width: `calc(${leftPanelWidth} - 48px)`,
                        maxWidth: '480px',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        background: color.Yambo_BG,
                        border: `1px solid ${color.Yambo_Text_On_Dark_Transparent}`,
                      },
                    } }
                  >
                    <Box sx={ { p: 1 } }>
                      {inputs.filter((input) => !input.exposed).map((param) => (
                        <Box 
                          key={ `param-${param.id}` } 
                          sx={ { width: '100%', mb:2, p:1 } } 
                        >
                          <Box sx={ { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' } }>
                            <Box>
                          {/* {role === 'editor' && mode === DesignAppMode.Editing && <Box sx={ { width: '12px', display: 'flex', alignItems: 'center', mr:1 } }>
                            <img src='/icons/dots.svg' width='12px' style={ { opacity:.8, cursor:'grab' } } draggable={ false } />
                          </Box>} */}
                          <Typography variant="caption" sx={ { mr:.5 } }>{nodes.find((node) => node.id === param.id).data.name}</Typography>
                          { nodes.find((node) => node.id === param.id).data.description && 
                          <Tooltip title={ nodes.find((node) => node.id === param.id).data.description } sx={ { fontSize:'10px' } }>
                            <HelpOutlineIcon fontSize="10px" />
                          </Tooltip> }
                            </Box>
                            {role === 'editor' && mode === DesignAppMode.Editing && <Link
                              onClick={ () => handleExposeParam(param.id, !param.exposed) }
                              sx={ {
                                display:'flex',
                                alignItems:'center',
                                textDecoration:'none',
                                color: color.Yambo_Text_On_Dark_Transparent,
                              } }
                            >
                                  <i className="fa-light fa-arrow-left-to-arc fa-xs"></i>
                                  <Typography
                                    variant="caption"
                                  sx={ {
                                    fontSize:'12px',
                                    ml:.5,
                                    transition: 'opacity 0.15s ease-in-out',
                                    whiteSpace: 'nowrap',
                                  } }
                                >
                                  {translate(I18N_KEYS.RECIPE_MAIN.FLOW.MODEL_PROPERTIES.SET_AS_INPUT)}
                                </Typography>
                            </Link> }
                          </Box>
                          {renderDesignAppParams(nodes.find((node) => node.id === param.id), param, handleChange)}
                        </Box>
                      ))}
                    </Box>
                  </Popover>
                </>
              )}
            </Box>
            {(errorMessage) &&
                <Box
                  id="model-error-container"
                  sx={ {
                    display: 'flex',
                    alignItems: 'center',
                    mt:.5,
                  } }
                >
                  <Tooltip title={ formattedMessage } placement="top">
                    <Typography
                      variant="caption"
                      color="error"
                      sx={ {
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'block',
                      } }
                    >
                      {formattedMessage}
                    </Typography>
                  </Tooltip>
                </Box>}
          </Box>
          
        </Box>
        <Box
          id="design-app-panel-resizer"
          onMouseDown={handleMouseDown}
          sx={{
            width: '12px',
            height: '100%',
            background: 'transparent',
            position: 'relative',
            cursor: 'col-resize',
          }}
          >
            <Box sx={ {
              width: '2px',
              left: 'calc(50% - 1px)',
              height: '20px',
              background: color.Yambo_Purple,
              position: 'relative',
              top: 'calc(45% - 10px)',
              cursor: 'col-resize',
              borderRadius: '2px',
              outline: `1px solid ${color.Yambo_Purple}`,
            } } />
          </Box>
        {/* Preview window */}
        <Box
          id="design-app-preview-container"
          sx={ {
            flex:1,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            background: `${color.Dark_Blue}CC`,
            border: `1px solid ${color.Dark_Grey}`,
            p:2,
            borderRadius: 3,
          } }
        >
          <Box
            sx={ {
              width:'100%',
              height:'100%',
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              // borderLeft: `1px solid ${color.Yambo_Text_On_Dark_Transparent}`,
              // borderRadius: 2,
            } }
          >
            {isLoading ? 
            <Box sx={ {
              width: '100%',
              height: '80%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap:4,
            } }>
              <Box sx={ {
                flex:1,
                width: '80%',
                position: 'relative',
              } }>
                <Skeleton variant="rounded" animation="wave" width="100%" height="100%" />
                <i className="fa-light fa-photo-film-music fa-xl" 
                style={ { 
                  fontSize: '50px', 
                  position: 'absolute', 
                  top: '50%', 
                  left: '50%', 
                  transform: 'translate(-50%, -50%)',
                  color: color.Yambo_Text_On_Dark_Transparent,
                } }></i>
              </Box>
              <Box sx={ {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                height: '20%',
                gap:2,
                position: 'relative',
              } }>
                {Array.from({ length: 5 }).map((_, index) => (
                    <Skeleton key={ index } variant="rounded" animation="wave" width="100%" height="100%"/>
              ))}
              </Box>
            </Box>
            :
            <>
            {(results.length > 0 || isProcessing)?
              (
                <DesignAppGallery 
                  recipeName={ recipeData.name } 
                  mediaArray={ isProcessing ? [...Array(numberOfLoadingResults).fill({ type: 'rendering' }), ...results] : results } 
                  selected={ selected } 
                  setSelected={ setSelected } 
                  deleteCurrentResult={ deleteCurrentResult } 
                  deleteAllResults={ deleteAllResults } 
                  deleteAllOthersResults={ deleteAllOthersResults } 
                />

              ) : (
                <Box sx={ {
                  border: `1px solid ${color.Yambo_Text_On_Dark_Transparent}`,
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                } }
                >
                  <i className="fa-thin fa-image fa-xl" style={ { fontSize: '50px' } }></i>
                </Box>

              )}
            </>
            }
          </Box>
        </Box>
      </Box>


    </Box>
  );
}

export default DesignApp;
